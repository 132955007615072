const NOTIFICATION_EMAIL = {
	created: {
		heading: '[HEADS UP] New user signed up on the User Portal',
		body: 'A user was signed up successfully on the user portal.',
	},
	confirmed: {
		heading: '[HEADS UP] New user confirmed',
		body: 'A user was confirmed successfully on the user portal.',
	},
	organization_confirmed: {
		heading: '[HEADS UP] user organization confirmed',
		body: "User's organization was confirmed successfully on the user portal.",
	},
	error: {
		heading: '[HEADS UP] Error!',
		body: 'Error while signing up a user on the user portal.',
	},
	signIn: {
		heading: '[HEADS UP] User signed in on the User Portal',
		body: 'A user was signed in successfully on the user portal.',
	},
	email_activation_error: {
		heading: '[HEADS UP] Email Activation error',
		body: 'An error occured when a user was activating their email address.',
	},
	account_activated: {
		heading: '[HEADS UP] Activation successful',
		body: 'An account was successfully activated on the User Portal',
	},
	user_account_activated: {
		heading: 'Welcome to Leda',
		body: '<p>Hello,</p><p>Thank you for joining the Leda Health Portal. We\'d like to confirm that your account was created successfully. To access the resources available to you click the link below. If you experience any issues logging into your account, reach out to us at <a href="mailto:support@leda.co">support@leda.co.</a></p><a href="https://www.ledaportal.com/">Leda Health User Portal</a><p>Best regards, </p><p>The Leda Health Team</p>',
	},
};

export default NOTIFICATION_EMAIL;
