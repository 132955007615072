import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

export const cardSkeleton = (
	<Grid container spacing={0} style={{ margin: '20px 0px 40px 0px' }}>
		<Grid sx={4} md={2} item>
			<Skeleton variant="rect" width={60} height={60} />
		</Grid>
		<Grid sx={8} md={10} item>
			<Skeleton variant="text" width="70%" />
			<Skeleton variant="text" width="70%" />
			<Skeleton variant="text" width="40%" />
		</Grid>
	</Grid>
);

export const imageskeleton = (width, height) => (
	<Skeleton variant="rect" width={width} height={height} />
);

export const lineskeleton = (width, height) => (
	<Skeleton variant="text" width={width} height={height} style={{ margin: '20px 0px' }} />
);
