import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.primary.main,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1.75),
		width: '100%',
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(0, 1),
		},
	},
	listItem: {
		fontSize: 18,
		fontWeight: 500,
		fontStyle: 'normal',
		padding: 0,
		alignItems: 'flex-start',
	},
	listText: {
		fontSize: 14,
		color: theme.palette.primary.main,
		fontWeight: 500,
		margin: 0,
	},
	imageLogo: {
		padding: theme.spacing(1.5, 0),
	},
}));

export default useStyles;
