// @flow

import PropTypes from 'prop-types';
import React, { useMemo, useContext, useReducer, createContext } from 'react';

import reducers from '../reducers';

import initialState from './initialState';

export default function makeStore() {
	// Make a context for the store
	const context = createContext();
	context.displayName = 'Leda';

	// Make a provider that takes an initialValue
	function Provider({ _initialState = initialState, children }) {
		// Make a new state instance (could even use immer here!)
		const [store, dispatch] = useReducer(reducers, _initialState);

		// Memoize the context value to update when the state does
		const contextValue = useMemo(() => [store, dispatch], [store]);

		// Provide the store to children
		return <context.Provider value={contextValue}>{children}</context.Provider>;
	}

	Provider.propTypes = {
		_initialState: PropTypes.shape(PropTypes.Object),
		children: PropTypes.node,
	};

	Provider.defaultProps = {
		_initialState: initialState,
		children: null,
	};

	// A hook to help consume the store
	const useStore = () => useContext(context);

	return [Provider, useStore];
}
