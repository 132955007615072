import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	icon: {
		fontSize: 100,
		color: theme.palette.primary.main,
		marginTop: theme.spacing(3),
	},
	errorIcon: {
		fontSize: 100,
		color: theme.palette.error.main,
		marginTop: theme.spacing(3),
	},
	dialog: {
		// padding: theme.spacing(5),
		minHeight: '30%',
		minWidth: '30%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			minWidth: '80%',
		},
	},
	dialoTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	button: {
		margin: theme.spacing(3),
		height: 'fit-content',
		background: '#BEE2E2 !important',
		borderRadius: '9px !important',
		color: `${theme.palette.primary.main} !important`,
		padding: '10px 34px !important',
		textTransform: 'none !important',
		width: 300,
		'&:hover': {
			background: '#BEE2E2',
			color: theme.palette.primary.main,
		},
	},
	errorButton: {
		margin: theme.spacing(3),
		backgroundColor: theme.palette.error.main,
		color: 'white',
		width: '100%',
		height: 'fit-content',
		padding: theme.spacing(2),
	},
	dialogActions: {
		minWidth: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	dialogContent: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'start',
	},
	photoStyle: {
		maxHeight: '100%',
		maxWidth: '100%',
		overflow: 'scroll',
	},
}));

export default styles;
