import {
	DialogTitle,
	Dialog,
	Button,
	DialogContent,
	DialogActions,
	Typography,
	Box,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Widget } from '@typeform/embed-react';
import PropTypes from 'prop-types';
import React from 'react';
import ReactPlayer from 'react-player';

import ModalCloseIcon from '../assets/ModalCloseIcon.svg';
import log from '../utils/log';

import styles from './Styles/SimpleDialog.Style';

function SimpleDialog(props) {
	const {
		open,
		dialogTitle,
		setOpen,
		dialogContentText,
		images,
		button,
		alternateText,
		surveyForm,
		navButton,
		promoCode,
		introVideo,
		introBanner,
	} = props;

	const classes = styles();
	log('[SimpleDialog][handleFormSubmit] props', props);

	const handleClose = () => {
		setOpen(false);
	};

	const handleFormSubmit = (e) => {
		log('[SimpleDialog][handleFormSubmit] event', e);
	};

	return (
		<Dialog
			classes={{
				paper: surveyForm || introVideo ? classes.formDialog : classes.dialog,
			}}
			onClose={handleClose}
			open={open}>
			{dialogTitle && dialogTitle !== null && dialogTitle !== '' ? (
				<DialogTitle>
					<Typography color="primary" variant="h5">
						<b>{dialogTitle}</b>
					</Typography>
				</DialogTitle>
			) : null}
			<DialogContent
				classes={{
					root: surveyForm || introVideo ? classes.formDialogContent : classes.dialogContent,
				}}>
				<img
					aria-hidden
					alt="An icon to close Modal"
					className={classes.closeIcon}
					onClick={() => setOpen(false)}
					src={ModalCloseIcon}
				/>
				{dialogContentText &&
					dialogContentText !== '' &&
					dialogContentText !== null &&
					(promoCode ? (
						<Typography color="primary">
							{dialogContentText}
							<b>{promoCode}</b>
						</Typography>
					) : (
						<Typography color="primary">{dialogContentText}</Typography>
					))}
				{images && images !== '' && images !== null && images.length > 0
					? images.map((image, i) => (
							<img
								alt={alternateText[i]}
								className={classes.photoStyle}
								key={alternateText[i]}
								src={image}
							/>
					  ))
					: null}
				{introVideo ? <ReactPlayer width="100%" height="100%" controls url={introVideo} /> : null}
				{introBanner ? (
					<Box className={classes.bannerBox}>
						<img
							alt="IntroBanner"
							className={classes.bannerStyle}
							key="introBanner"
							src={introBanner}
						/>
					</Box>
				) : null}
				{surveyForm ? (
					<Widget className={classes.eligibilityForm} onSubmit={(e) => handleFormSubmit(e)} />
				) : null}
			</DialogContent>
			{button ? (
				<DialogActions>
					<Button classes={{ root: classes.button }} fullWidth onClick={handleClose}>
						Got it
					</Button>
				</DialogActions>
			) : null}
			{navButton ? (
				<DialogActions>
					<Button classes={{ root: classes.navButton }}>
						<a
							className={classes.dialogButton}
							href="https://www.leda.co/healingcircles"
							rel="noreferrer"
							target="_blank">
							Explore Healing Circles
							<ArrowForwardIcon className={classes.forwardIcon} />
						</a>
					</Button>
				</DialogActions>
			) : null}
		</Dialog>
	);
}

SimpleDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	dialogTitle: PropTypes.string,
	setOpen: PropTypes.func.isRequired,
	dialogContentText: PropTypes.string.isRequired,
	images: PropTypes.shape(PropTypes.shape),
	button: PropTypes.bool,
	alternateText: PropTypes.string,
	surveyForm: PropTypes.bool,
	navButton: PropTypes.string,
	promoCode: PropTypes.bool,
	// formType: PropTypes.string,
	introVideo: PropTypes.string,
	introBanner: PropTypes.string,
};

SimpleDialog.defaultProps = {
	dialogTitle: '',
	images: null,
	button: false,
	alternateText: '',
	surveyForm: false,
	navButton: '',
	promoCode: false,
	// formType:'',
	introBanner: false,
	introVideo: false,
};

export default SimpleDialog;
