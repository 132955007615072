import { rest } from 'msw';

import API from '../../utils/constants/apiEndPoints';

const SendLockCode = [
	rest.post(`*${API.SEND_QR_CODE}`, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json('Email Sent!!!!!!!!!'));
	}),
];

export default SendLockCode;
