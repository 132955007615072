import { makeStyles } from '@material-ui/core';

import graphic3x from '../../assets/graphic3x.jpg';
import illustrationMobile from '../../assets/MobileGraphic.png';

const styles = makeStyles((theme) => ({
	publicHeader: {
		[theme.breakpoints.down('md')]: {
			minHeight: '10vh',
			width: '100vw',
			height: '10vh',
			background: `url(${illustrationMobile})`,
			backgroundSize: 'cover',
			display: 'flex',
			alignItems: 'end',
			paddingLeft: theme.spacing(3),
			paddingBottom: theme.spacing(1),
		},
		[theme.breakpoints.down('sm')]: {
			minHeight: '10vh',
			height: '10vh',
			width: '100%',
			background: `url(${illustrationMobile})`,
			backgroundSize: 'cover',
			display: 'flex',
			alignItems: 'end',
			paddingLeft: theme.spacing(3),
			paddingBottom: theme.spacing(1),
		},
	},
	publicTitle: {
		color: theme.palette.primary.main,
		fontWeight: 55,
		fontFamily: theme.typography.secondary,
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			fontSize: 24,
			fontWeight: 55,
		},
	},

	backgroundDiv: {
		minHeight: '93vh',
		position: 'relative',
		width: '100%',
		height: '100%',
		padding: theme.spacing(5),
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'repeat-y',
		backgroundColor: '#F2F0EA',
		backgroundImage: `url(${graphic3x})`,
		background:
			'radial-gradient(117.26% 117.26% at 74.59% 8.02%, rgba(129, 177, 250, 0.77) 6.1%, rgba(234, 150, 240, 0.77) 56.68%, rgba(255, 255, 255, 0.77) 94.68%), radial-gradient(117.26% 117.26% at 74.59% 8.02%, rgba(129, 177, 250, 0.42) 6.1%, rgba(234, 150, 240, 0.42) 56.68%, rgba(255, 255, 255, 0.42) 94.68%), radial-gradient(117.26% 117.26% at 74.59% 8.02%, rgba(242, 173, 109, 0.4) 6.1%, rgba(179, 226, 194, 0.4) 56.68%, rgba(255, 255, 255, 0.4) 94.68%)',
	},
	backgroundDivMobile: {
		position: 'relative',
		minHeight: '100%',
		maxHeight: '100%',
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			alignContent: 'center',
			alignItems: 'center',
		},
	},
	photoStyle: {
		position: 'absolute',
		top: 0,
		width: '100%',
		minHeight: '100%',
		maxHeight: '100%',
	},
	footerDiv: {
		backgroundColor: theme.palette.primary.main,
	},
	title: {
		zIndex: 100,
		position: 'absolute',
		left: theme.spacing(5),
		color: theme.palette.primary.main,
		fontWeight: 55,
		fontFamily: theme.typography.secondary,
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			zIndex: 100,
			fontSize: 24,
			fontWeight: 55,
			left: theme.spacing(3),
			top: '45%',
		},
		[theme.breakpoints.up('md')]: {
			zIndex: 100,
			position: 'absolute',
			color: theme.palette.primary.main,
			fontSize: 40,
			fontWeight: 55,
			top: '3%',
		},
	},
	textBoxes: {
		position: 'absolute',
		bottom: 80,
		maxWidth: '90%',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
			bottom: 80,
		},
	},
	text3: {
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: 16,
		fontFamily: theme.typography.secondary,
	},
}));

export default styles;
