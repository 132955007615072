import { Auth } from 'aws-amplify';

import API from '../utils/constants/apiEndPoints';
import { DEMO } from '../utils/constants/demoData';
import log from '../utils/log';

import { get } from '.';

const fetchUser = async () => {
	try {
		let authRes;
		if (process.env.REACT_APP_ENV !== DEMO) {
			authRes = await Auth.currentSession();
		}
		log('[fetchUser]authRes', authRes);
		const path = `${API.FETCH_USER}?userId=${authRes?.idToken?.payload?.sub}`;
		const userBody = await get(path, false);
		return userBody;
	} catch (err) {
		log('[MainApp] err fetching user', err);
	}
	return null;
};

export default fetchUser;
