import AuthHandler from './handlers/AuthHandler';
import CancelEEKOrder from './handlers/CancelEEKOrderHandler';
import CancelPlanBOrder from './handlers/CancelPlanBOrderHandler';
import CancelToxicologyOrder from './handlers/CancelToxicologyHandler';
import OrderPlanB from './handlers/CreatePlanBOrderHandler';
import CreateSupportTicket from './handlers/CreateSupportTicketHandler';
import FetchEEKOrder from './handlers/FetchEEKOrderHandler';
import FetchLockCode from './handlers/FetchLockCode';
import FetchOrgId from './handlers/FetchOrgIdHandler';
import FetchPlanBOrder from './handlers/FetchPlanBOrderHandler';
import FetchToxicologyOrder from './handlers/FetchToxicologyOrderHandler';
import FetchUserDetails from './handlers/FetchUserDetails';
import OrderEEK from './handlers/OrderEEK';
import OrderToxicology from './handlers/OrderToxicologyHandler';
import SendEmailNotification from './handlers/SendEmailNotification';
import SendLockCode from './handlers/SendLockCode';
import UpdateUserDetails from './handlers/UpdateUserDetailsHandler';

const handlers = [
	...FetchLockCode,
	...FetchOrgId,
	...FetchUserDetails,
	...AuthHandler,
	...OrderEEK,
	...SendEmailNotification,
	...SendLockCode,
	...UpdateUserDetails,
	...CreateSupportTicket,
	...CancelEEKOrder,
	...FetchEEKOrder,
	...OrderPlanB,
	...FetchPlanBOrder,
	...CancelPlanBOrder,
	...OrderToxicology,
	...FetchToxicologyOrder,
	...CancelToxicologyOrder,
];

export default handlers;
