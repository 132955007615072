/* eslint-disable no-prototype-builtins */
import { rest } from 'msw';

import API from '../../utils/constants/apiEndPoints';
import UpdateUserDetailsResponse from '../responses/updateUserDetailsResponse.json';
import UserDetailsWithKitIdResponse from '../responses/UserDetailsWithKitIdResponse.json';

const UpdateUserDetails = [
	rest.post(`*${API.UPDATE_USER}`, async (req, res, ctx) => {
		const requestBody = await req.json();
		if (requestBody.hasOwnProperty('ClientId')) {
			return res(ctx.status(200), ctx.json(UpdateUserDetailsResponse));
		}
		return res(ctx.status(200), ctx.json(UserDetailsWithKitIdResponse));
	}),
];

export default UpdateUserDetails;
