import { Dialog, DialogContent, DialogTitle, Divider, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import ModalCloseIcon from '../../assets/ModalCloseIcon.svg';

import useStyles from './Styles/SignUpAlertDialog.Style';

function SignUpAlertDialog({ open, setOpen, title, subTitle, isSubmitted, children }) {
	const classes = useStyles();

	return (
		<Dialog
			classes={{ root: classes.div, paper: classes.dialog }}
			onClose={() => setOpen(false)}
			open={open}
			scroll="body">
			{isSubmitted ? (
				<div
					onClick={() => setOpen(false)}
					onKeyPress={() => setOpen(false)}
					role="button"
					tabIndex="0">
					<img
						alt="An icon to separate the different information about STI/STD testing timeline"
						className={classes.image}
						src={ModalCloseIcon}
					/>
				</div>
			) : (
				<>
					<DialogTitle className={classes.heading}>
						<Typography className={classes.title}>{title}</Typography>
						<Typography className={classes.subTitle}>{subTitle}</Typography>
						<div
							onClick={() => setOpen(false)}
							onKeyPress={() => setOpen(false)}
							role="button"
							tabIndex="0">
							<img
								alt="An icon to close the modal"
								className={classes.image}
								src={ModalCloseIcon}
							/>
						</div>
					</DialogTitle>
					<Divider className={classes.divider} />
				</>
			)}
			<DialogContent className={classes.contentBox}>{children}</DialogContent>
		</Dialog>
	);
}

SignUpAlertDialog.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	title: PropTypes.string,
	subTitle: PropTypes.node,
	isSubmitted: PropTypes.bool,
	children: PropTypes.node,
};

SignUpAlertDialog.defaultProps = {
	open: false,
	setOpen: null,
	title: '',
	subTitle: null,
	isSubmitted: false,
	children: null,
};

export default SignUpAlertDialog;
