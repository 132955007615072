import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import userSignOut from '../Services/userSignOut';
import { useStore } from '../store';

import useStyles from './Styles/InactivityTracker.Style';

const IDLE_TIMEOUT = 1000 * 60 * 2;
const INACTIVE_TIMEOUT = 1000 * 60 * 2;
const WARNING_TIMER = 1000 * 60;
let isTimerStarted = false;

function InactivityTracker({ showPopup, inactiveTime, idealTime }) {
	const [openDialog, setOpenDialog] = useState(false);
	const classes = useStyles();
	const history = useHistory();
	const [, dispatch] = useStore();
	const [warningTimer, setWarningTimer] = useState(WARNING_TIMER);
	let inactivityTimer;
	const ref = useRef(null);

	// Function to start warning timer
	const startTimer = () => {
		isTimerStarted = true;
		setWarningTimer(WARNING_TIMER);
		if (ref.current) clearInterval(ref.current);
		const id = setInterval(() => {
			setWarningTimer((prevWarningTimer) => prevWarningTimer - 1000);
		}, 1000);
		ref.current = id;
	};

	const alertUser = () => {
		if (!isTimerStarted) {
			setOpenDialog(true);
			startTimer();
		}
	};

	const handleSignOut = () => {
		isTimerStarted = false;
		userSignOut(history, dispatch);
	};

	const resetInactivityTimer = () => {
		clearTimeout(inactivityTimer);
		inactivityTimer = setTimeout(alertUser, idealTime);
	};

	const getTimer = (time) => {
		const currTimer = time / 1000;
		let timerValue;

		if (currTimer === 60) {
			timerValue = '01:00';
		} else if (currTimer <= 0) {
			handleSignOut();
		} else {
			timerValue = `00:${currTimer > 9 ? currTimer : `0${currTimer}`}`;
		}
		return timerValue;
	};

	// Function to check tab status
	const checkVisibility = () => {
		if (!isTimerStarted) {
			if (document.hidden) {
				clearTimeout(inactivityTimer);
				inactivityTimer = setTimeout(alertUser, inactiveTime);
			} else {
				resetInactivityTimer();
			}
		}
	};

	const resetInactivity = () => {
		if (ref.current) clearInterval(ref.current);
		isTimerStarted = false;
		setOpenDialog(false);
	};

	const handleDialogClose = (_, reason) => {
		if (reason !== 'backdropClick') {
			setOpenDialog(false);
		}
	};

	useEffect(() => {
		document.addEventListener('visibilitychange', checkVisibility);
		document.addEventListener('load', resetInactivityTimer);
		document.addEventListener('click', resetInactivityTimer);
		document.addEventListener('keypress', resetInactivityTimer);
		document.addEventListener('touchstart', resetInactivityTimer);
		document.addEventListener('mousemove', resetInactivityTimer);
		document.addEventListener('mousedown', resetInactivityTimer);
		document.addEventListener('scroll', resetInactivityTimer);
		resetInactivityTimer();

		return () => {
			document.removeEventListener('visibilitychange', checkVisibility);
			document.removeEventListener('load', resetInactivityTimer);
			document.removeEventListener('click', resetInactivityTimer);
			document.removeEventListener('keypress', resetInactivityTimer);
			document.removeEventListener('touchstart', resetInactivityTimer);
			document.removeEventListener('mousemove', resetInactivityTimer);
			document.removeEventListener('mousedown', resetInactivityTimer);
			document.removeEventListener('scroll', resetInactivityTimer);
		};
	}, []);

	return (
		<Dialog
			classes={{ root: classes.div, paper: classes.dialog }}
			onClose={(_, reason) => handleDialogClose(_, reason)}
			style={{ display: showPopup ? 'inherit' : 'none' }}
			open={openDialog}>
			<DialogContent className={classes.dialogContent}>
				<Typography className={classes.title}>Are you still there?</Typography>
				<Typography className={classes.content}>
					{"If not, we'll close this session in: "}
					<b>{getTimer(warningTimer)}</b>
				</Typography>
			</DialogContent>
			<DialogActions className={classes.dialogAction}>
				<Button className={classes.button} onClick={() => resetInactivity()}>
					I&apos;m here
				</Button>
				<Button className={classes.button} onClick={(e) => handleSignOut(e)}>
					Sign out
				</Button>
			</DialogActions>
		</Dialog>
	);
}

InactivityTracker.propTypes = {
	showPopup: PropTypes.bool,
	inactiveTime: PropTypes.number,
	idealTime: PropTypes.number,
};

InactivityTracker.defaultProps = {
	showPopup: true,
	inactiveTime: INACTIVE_TIMEOUT,
	idealTime: IDLE_TIMEOUT,
};

export default InactivityTracker;
