import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	dialog: {
		minHeight: '25%',
		minWidth: '30%',
		borderRadius: 15,
		width: 500,
		alignItems: 'start',
		maxWidth: 'unset',
		justifyContent: 'space-between',
		padding: theme.spacing(6, 6, 4),
		[theme.breakpoints.down('sm')]: {
			alignItems: 'center',
			padding: theme.spacing(5),
		},
	},
	dialogContent: {
		textAlign: 'center',
		display: 'contents',
	},
	title: {
		fontWeight: 400,
		fontSize: 30,
		lineHeight: '37px',
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			fontSize: 24,
			lineHeight: '25px',
		},
	},
	content: {
		fontSize: 18,
		lineHeight: '20px',
		color: theme.palette.primary.main,
		padding: theme.spacing(3, 0),
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
			lineHeight: '20px',
			padding: theme.spacing(3, 0),
		},
	},
	button: {
		backgroundColor: '#BEE2E2',
		color: theme.palette.primary.main,
		height: 'fit-content',
		textTransform: 'none',
		borderRadius: 9,
		fontSize: 16,
		lineHeight: '20px',
		padding: theme.spacing(1.25, 4.25),
		maxWidth: theme.spacing(37.5),
		'&:hover': {
			background: '#BEE2E2',
			color: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: 0,
			padding: theme.spacing(1.25, 2.25),
			width: '100%',
			maxWidth: theme.spacing(37.5),
		},
	},
	dialogAction: {
		width: '100%',
		justifyContent: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
		},
	},
}));

export default useStyles;
