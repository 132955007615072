import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Styles/SelectState.Style';

function StateSelect(props) {
	const classes = styles();
	const { state, setState, width } = props;

	return (
		<FormControl className={classes.formControl} variant="outlined">
			{/* <InputLabel htmlFor="outlined-age-native-simple">State</InputLabel> */}
			<Select
				inputProps={{
					name: 'state',
					id: 'outlined-state-native-simple',
				}}
				native
				onChange={(e) => setState(e.target.value)}
				// classes={{ root: classes.select}}
				style={{ width, backgroundColor: 'white' }}
				value={state}>
				<option aria-label="None" value="">
					Select State
				</option>
				{/* <option value={'AL'}>{'AL'}</option>
        <option value={'AK'}>{'AK'}</option>
        <option value={'AZ'}>{'AZ'}</option>
        <option value={'AR'}>{'AR'}</option>
        <option value={'CO'}>{'CO'}</option>
        <option value="California">California</option>
        <option value={'CT'}>{'CT'}</option>
        <option value={'DE'}>{'DE'}</option>
        <option value={'DC'}>{'DC'}</option>
        <option value={'GA'}>{'GA'}</option>
        <option value="Florida">Florida</option>
        <option value={'HI'}>{'HI'}</option>
        <option value={'ID'}>{'ID'}</option>
        <option value={'IL'}>{'IL'}</option>
        <option value={'IN'}>{'IN'}</option>
        <option value={'IA'}>{'IA'}</option>
        <option value={'KS'}>{'KS'}</option>
        <option value={'KY'}>{'KY'}</option>
        <option value={'LA'}>{'LA'}</option>
        <option value={'ME'}>{'ME'}</option>
        <option value={'MD'}>{'MD'}</option>
        <option value={'MA'}>{'Massachusetts'}</option>
        <option value={'MI'}>{'Michigan'}</option>
        <option value={'MN'}>{'Minnesota'}</option>
        <option value={'MS'}>{'Mississippi'}</option>
        <option value={'MO'}>{'Missouri'}</option>
        <option value={'MT'}>{'Montana'}</option>
        <option value={'NE'}>{'Nebraska'}</option>
        <option value={'NV'}>{'Nevada'}</option>
        <option value={'NH'}>{'New Hampshire'}</option>
        <option value={'NJ'}>{'New Jersey'}</option>
        <option value={'NM'}>{'New Mexico'}</option>
        <option value={'NY'}>{'New York'}</option>
        <option value={'NC'}>{'North Carolina'}</option>
        <option value={'ND'}>{'North Dakota'}</option>
        <option value={'OH'}>{'Ohio'}</option>
        <option value={'OR'}>{'Oregon'}</option>
        <option value={'PA'}>{'Pennsylvania'}</option>
        <option value={'PR'}>{'Puerto Rico'}</option>
        <option value={'RI'}>{'Rhode Island'}</option> 
        <option value={'SC'}>{'South Carolina'}</option>
        <option value={'SD'}>{'South Dakota'}</option>
        <option value="TX">Texas</option>
        <option value={'TN'}>{'Tennessee'}</option> 
        <option value={'UT'}>{'Utah'}</option>
        <option value={'VT'}>{'Vermont'}</option>
        <option value={'WV'}>{'West Virginia'}</option>
        <option value={'WI'}>{'Wisconsin'}</option>
        <option value={'WY'}>{'Wyoming'}</option> 
        <option value={'VA'}>{'Virginia'}</option> */}
				<option value="WA">Washington</option>
				<option value="CA">California</option>
			</Select>
		</FormControl>
	);
}

StateSelect.propTypes = {
	state: PropTypes.string.isRequired,
	setState: PropTypes.func.isRequired,
	width: PropTypes.string,
};

StateSelect.defaultProps = {
	width: '',
};
export default StateSelect;
