import { rest } from 'msw';

import API from '../../utils/constants/apiEndPoints';

const CreateSupportTicket = [
	rest.post(`*${API.CREATE_SUPPORT_TICKET}`, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json({ msg: 'ticket submitted successfully' }));
	}),
];

export default CreateSupportTicket;
