import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import useStyles from '../Pages/Styles/LearnMore.Style';
import BANNER_IMAGES from '../utils/constants/avoidThingBanner';

function AvoidThingsBanner() {
	const classes = useStyles();
	return (
		<Grid alignItems="baseline" container item spacing={1}>
			{BANNER_IMAGES.map((item) => (
				<Grid item key={item.title} lg={2} md={2} xs={4}>
					<img alt="Backgound images for the tiles." className={classes.image} src={item.image} />
					<Typography className={classes.imgTitle}>{item.title}</Typography>
				</Grid>
			))}
		</Grid>
	);
}

export default AvoidThingsBanner;
