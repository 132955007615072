import { rest } from 'msw';

import API from '../../utils/constants/apiEndPoints';
import fetchEEKResponse from '../responses/orderEEKResponse.json';

const FetchEEKOrder = [
	rest.get(`*${API.FETCH_EEK_ORDER}`, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(fetchEEKResponse));
	}),
];

export default FetchEEKOrder;
