/* eslint-disable no-nested-ternary */
import { Box, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PropTypes from 'prop-types';
import React from 'react';
import QRCode from 'react-qr-code';

import log from '../utils/log';

import { imageskeleton } from './PickupCardSkeleton';
import useStyles from './Styles/PickUpKit.Style';

function QRCodeBox({ qrCode, qrcodeError, qrCodeRefreshing }) {
	const classes = useStyles();
	log('qr code being generated', qrCode);

	return (
		<Box className={classes.qrCodeBox}>
			{qrCode && !qrCodeRefreshing ? (
				<QRCode size={225} value={qrCode} />
			) : qrcodeError ? (
				<Typography className={classes.errorBox}>
					<ErrorOutlineIcon className={`${classes.marginLeft} ${classes.marginRight}`} />
					{qrcodeError}
				</Typography>
			) : (
				imageskeleton(250, 250)
				// <CircularProgress className={classes.marginLeft} />
			)}
		</Box>
	);
}

QRCodeBox.propTypes = {
	qrCode: PropTypes.string,
	qrcodeError: PropTypes.string,
	qrCodeRefreshing: PropTypes.bool,
};

export default QRCodeBox;
