import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import log from '../utils/log';

import styles from './Styles/PasswordRequirements.Style';

/* Passwords should contain:
	1. Atleast 1 special character  ( ! @ # $ & * ~ )
	2. Atleast 1 uppercase character
	3. 8-15 characters
	4. Atleast 1 digit
	5. Atleast 1 lowercase letter
*/

function PasswordRequirements(props) {
	const classes = styles();
	const { password } = props;
	const [hasDigits, setHasDigits] = useState(false);
	const [hasUppercase, setUppercase] = useState(false);
	const [hasLowercase, setLowercase] = useState(false);
	const [hasCorrectLength, setHasCorrectLength] = useState(false);
	const [hasSpecial, setHasSpecial] = useState(false);

	const checkDigits = (string) => {
		log('[] string', string);
		const res = /\d/.test(string);
		log('[checkDigits] res ', res);
		setHasDigits(res);
	};
	const checkUppercase = (string) => {
		log('[] string', string);
		const res = /[A-Z]/.test(string);
		log('[checkUppercase] res', res);
		setUppercase(res);
	};
	const checkLowercase = (string) => {
		log('[] string', string);
		const res = /[a-z]/.test(string);
		log('[checkLowercase] res', res);
		setLowercase(res);
	};
	const checkLength = (string) => {
		log('[] string', string);
		const res = string.length >= 8 && string.length <= 15;
		log('[checkLength] res', res);
		setHasCorrectLength(res);
	};
	const checkSpecial = (string) => {
		log('[] string', string);
		const res = /[!,@,#,$,&,*,~,_,-]/.test(string);
		log('[checkSpecial] res', res);
		setHasSpecial(res);
	};

	useEffect(() => {
		checkDigits(password);
		checkSpecial(password);
		checkLength(password);
		checkLowercase(password);
		checkUppercase(password);
	}, [password]);

	return (
		<div>
			<Typography className={classes.text}>{'A valid password must: '}</Typography>
			<Typography className={classes.text}>
				{hasCorrectLength ? (
					<CheckCircleIcon className={classes.icon} color="primary" />
				) : (
					<ErrorIcon className={classes.icon} color="secondary" />
				)}
				be 8-15 characters in length
			</Typography>
			<Typography className={classes.text}>
				{hasUppercase ? (
					<CheckCircleIcon className={classes.icon} color="primary" />
				) : (
					<ErrorIcon className={classes.icon} color="secondary" />
				)}
				contain atleast 1 uppercase letter
			</Typography>
			<Typography className={classes.text}>
				{hasLowercase ? (
					<CheckCircleIcon className={classes.icon} color="primary" />
				) : (
					<ErrorIcon className={classes.icon} color="secondary" />
				)}
				contain atleast 1 lowercase letter
			</Typography>
			<Typography className={classes.text}>
				{hasDigits ? (
					<CheckCircleIcon className={classes.icon} color="primary" />
				) : (
					<ErrorIcon className={classes.icon} color="secondary" />
				)}
				contain atleast 1 digit
			</Typography>
			<Typography className={classes.text}>
				{hasSpecial ? (
					<CheckCircleIcon className={classes.icon} color="primary" />
				) : (
					<ErrorIcon className={classes.icon} color="secondary" />
				)}
				contain atleast 1 special character
			</Typography>
		</div>
	);
}

PasswordRequirements.propTypes = {
	password: PropTypes.string.isRequired,
};

export default PasswordRequirements;
