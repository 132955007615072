import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import DeliveryIcon from '../assets/DeliveryIcon.svg';
import ModalCloseIcon from '../assets/ModalCloseIcon.svg';
import PickUpIcon from '../assets/PickUpIcon.svg';
import { dispatchQuizState } from '../Pages/Kit/Eligibility Quiz/helperFunction';
import { useStore } from '../store';
import ROUTES from '../utils/constants/routes';
import SERVICE_LIST from '../utils/constants/serviceList';

import useStyles from './Styles/KitDialog.Style';

function KitDialog({ open, setOpen, service, title, subTitle }) {
	const classes = useStyles();
	const [, dispatch] = useStore();
	const history = useHistory();

	const handleRoute = (option) => {
		if (service === SERVICE_LIST.PLAN_B) {
			history.push(option === 'delivery' ? ROUTES.ORDER_PLAN_B : ROUTES.PICK_UP_PLAN_B);
		} else if (service === SERVICE_LIST.TOXICOLOGY_SCREENING) {
			history.push(option === 'delivery' ? ROUTES.ORDER_TOXICOLOGY : ROUTES.PICK_UP_TOXICOLOGY);
		} else if (service === SERVICE_LIST.STI_TESTING) {
			history.push(option === 'delivery' ? ROUTES.ORDER_TEST : ROUTES.PICK_UP_STI);
		} else {
			dispatchQuizState(dispatch, null);
			history.push(option === 'delivery' ? ROUTES.ORDER_KIT : ROUTES.PICK_UP_KIT);
		}
	};

	return (
		<Dialog
			classes={{ root: classes.div, paper: classes.dialog }}
			onClose={(_, reason) => {
				if (reason !== 'backdropClick') {
					setOpen(false);
				}
			}}
			open={open}>
			<DialogTitle>
				<img
					aria-hidden
					alt="An icon to close Modal"
					className={classes.closeIcon}
					onClick={() => setOpen(false)}
					src={ModalCloseIcon}
				/>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				{title && <Typography className={classes.title}>{title}</Typography>}
				{subTitle && <Typography className={classes.content}>{subTitle}</Typography>}
			</DialogContent>
			<DialogActions className={classes.dialogAction}>
				<Box className={classes.button} component="button" onClick={() => handleRoute('delivery')}>
					<Box className={classes.imageBox}>
						<img alt="deliveryIcon" src={DeliveryIcon} />
					</Box>
					<Typography className={classes.buttonText}>Delivery</Typography>
					<Typography className={classes.subText}>Delivery available within 2 hours</Typography>
				</Box>
				<Box className={classes.button} component="button" onClick={() => handleRoute('pickUp')}>
					<Box className={classes.imageBox}>
						<img alt="pickupIcon" src={PickUpIcon} />
					</Box>
					<Typography className={classes.buttonText}>Pick Up</Typography>
					<Typography className={classes.subText}>
						Look for a blue box in your package drop-off area.
					</Typography>
				</Box>
			</DialogActions>
		</Dialog>
	);
}

KitDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func,
	service: PropTypes.string,
	title: PropTypes.string,
	subTitle: PropTypes.string,
};

export default KitDialog;
