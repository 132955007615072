import { rest } from 'msw';

import API from '../../utils/constants/apiEndPoints';
import FetchUserDetailsResponse from '../responses/fetchUserDetails.json';

const FetchUserDetails = [
	rest.get(`*${API.FETCH_USER}`, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(FetchUserDetailsResponse));
	}),
];

export default FetchUserDetails;
