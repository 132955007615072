import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.disabled.second,
		padding: theme.spacing(2),
	},
	arrow: {
		color: theme.palette.disabled.second,
	},
	box1: {
		// border: '2px solid red',
		width: '40%',
		height: '100vh',
		backgroundColor: theme.palette.tertiary.main,
	},
	main: {
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: '#e9f5f8',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			backgroundColor: '#e9f5f8',
			flexDirection: 'column',
			// border: '2px solid blue',
		},
	},
	side: {
		display: 'flex',
		flexDirection: 'column',
		paddingTop: theme.spacing(3),
		paddingLeft: theme.spacing(7),
		paddingRight: theme.spacing(7),
		maxHeight: '100vh',
		overflowY: 'scroll',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			backgroundColor: '#e9f5f8',
			flexDirection: 'column',
			width: '100%',
			height: '100vh',
			maxHeight: '100vh',
			overflowY: 'scroll',
			padding: theme.spacing(2),
			position: 'relative',
		},
	},
	box2: {
		backgroundColor: '#e9f5f8',
		width: '100%',
		height: '80vh',
		// paddingLeft: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			backgroundColor: '#e9f5f8',
			width: '100%',
			height: '80vh',
			// paddingLeft: theme.spacing(1)
		},
	},
	text2: {
		fontWeight: 20,
		marginTop: theme.spacing(1),
		// marginBottom: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			// marginBottom: theme.spacing(1)
		},
	},
	title: {
		// marginTop: -10,
		[theme.breakpoints.down('sm')]: {
			// marginTop: 8
		},
	},
	form: {
		padding: theme.spacing(2),
		maxHeight: '100%',
		width: '80%',
		maxWidth: 600,
		[theme.breakpoints.down('sm')]: {
			// marginTop: theme.spacing(2),
			minWidth: '100%',
			maxWidth: 600,
			maxHeight: '75vh',
			overflowY: 'scroll',
			// padding: theme.spacing(3)
		},
	},
	setupForm: {
		padding: theme.spacing(2),
		maxHeight: '100%',
		width: '80%',
		maxWidth: 600,
		position: 'absolute',
		top: '10vh',
		[theme.breakpoints.down('sm')]: {
			top: '8vh',
			width: '90%',
			maxWidth: 600,
			maxHeight: '75vh',
			overflowY: 'scroll',
			// padding: theme.spacing(3)
		},
	},
	input: {
		width: '100%',
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			color: theme.palette.primary.main,
		},
	},
	footer: {
		position: 'fixed',
		bottom: 0,
		padding: theme.spacing(5),
		color: theme.palette.default.main,
		backgroundColor: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			// position: 'fixed',
			// bottom: 0,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			minWidth: '100%',
			minHeight: '10vh',
			maxHeight: '10vh',
			flexDirection: 'column',
		},
	},
	submitBtn: {
		height: 52,
		width: '100%',
		marginTop: 40,
		backgroundColor: theme.palette.primary.second,
		'&:hover': {
			backgroundColor: theme.palette.primary.second,
		},
		'&:disabled': {
			backgroundColor: theme.palette.disabled.second,
		},
		[theme.breakpoints.down('sm')]: {
			'&:disabled': {
				backgroundColor: theme.palette.disabled.second,
			},
			height: 52,
			width: '100%',
			marginTop: theme.spacing(2),
			backgroundColor: theme.palette.primary.second,
			color: theme.palette.primary.main,
		},
	},
	boldColor: {
		color: '#F48273',
	},
	errorMsg: {
		marginTop: 8,
		color: 'red',
	},
	resetMsg: {
		marginTop: 8,
		color: 'green',
	},
	errorText: {
		color: 'red',
	},
	errorDiv: {
		paddingLeft: 8,
		marginTop: 8,
		color: 'red',
	},
	inputFilled: {
		width: '100%',
		color: theme.palette.primary.main,
		backgroundColor: '#E2FAE9',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			color: theme.palette.primary.main,
		},
	},
	emailBox: {
		display: 'flex',
		alignItems: 'center',
	},
	emailInput: {
		width: '100%',
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			color: theme.palette.primary.main,
		},
	},
	emailInputFilled: {
		width: '100%',
		color: theme.palette.primary.main,
		backgroundColor: '#E2FAE9',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			color: theme.palette.primary.main,
		},
	},
	error: {
		color: 'red',
	},
}));

export default styles;
