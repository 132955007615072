import ACTION_TYPE from '../utils/constants/actionType';
import API from '../utils/constants/apiEndPoints';
import log from '../utils/log';

import { get } from '.';

const fetchPlanBOrder = async (userId, orderId, dispatch) => {
	const PATH = `${API.FETCH_PLANB_ORDER}?userId=${userId}&orderId=${orderId}`;
	try {
		const responseData = await get(PATH, false);
		dispatch({
			type: ACTION_TYPE.SET_PLANB_ORDER_DETAILS,
			planBOrderDetails: responseData,
		});
		log('[fetchPlanBOrder] responseData', responseData);
		return responseData;
	} catch (e) {
		log('[fetchPlanBOrder] error', e);
	}
};

export default fetchPlanBOrder;
