/* eslint-disable no-nested-ternary */
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, withRouter } from 'react-router-dom';

import Loading from '../../../Components/Loading';
import confirmUser from '../../../Services/userConfirm';
import { useStore } from '../../../store';
import ACTION_TYPE from '../../../utils/constants/actionType';
import ROUTES from '../../../utils/constants/routes';
import ResetPassword from '../ResetPassword';

import SignupErrorModal from './SignupErrorModal';

function UserConfirmation({ location }) {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(false);
	const [tempPassword, setTempPassword] = useState('');
	const [cognitoUser, setCognitoUser] = useState({});
	const [, dispatch] = useStore();
	const history = useHistory();
	const searchPath = location?.search;
	const [token, id, email, oid, retry] = searchPath.substring(1).split('&');
	const userData = {
		t: token.split('=')[1],
		email: email.split('=')[1],
		id: id.split('=')[1],
		orgId: oid.split('=')[1],
		retry: retry.split('=')[1],
	};

	const verifyUser = async () => {
		try {
			setIsLoading(true);
			const res = await confirmUser(userData?.t, userData?.email, userData?.id);
			if (res && res?.tempPassword) {
				setTempPassword(res?.tempPassword);
				const user = await Auth.signIn(userData?.email.trim(), res?.tempPassword);
				setCognitoUser(user);
				dispatch({
					type: ACTION_TYPE.SET_ORG_ID,
					orgId: userData?.orgId,
				});
				dispatch({
					type: ACTION_TYPE.SET_USER,
					user,
				});
				dispatch({
					type: ACTION_TYPE.SET_USER_LOGGED_IN,
					isLoggedIn: true,
				});
				sessionStorage.setItem('userLoggedIn', true);
				setIsLoading(false);
			} else {
				setError(true);
			}
		} catch (err) {
			if (err?.response?.data === 'Registration link expired. Please request a new link.')
				setError(true);
			else if (err?.message === 'Incorrect username or password.') history.push(ROUTES.SIGN_IN);
			else history.push(ROUTES.ERROR);
		}
	};

	useEffect(() => {
		sessionStorage.clear();
		dispatch({
			type: ACTION_TYPE.SET_USER,
			user: null,
		});
		verifyUser();
	}, []);

	return (
		<>
			{userData?.t && userData?.email && userData?.id ? (
				isLoading ? (
					<Loading />
				) : (
					<ResetPassword tempPassword={tempPassword} userConfirm cognitoUser={cognitoUser} />
				)
			) : (
				<Redirect to={ROUTES.ERROR} />
			)}
			<SignupErrorModal
				open={error}
				setOpen={setError}
				email={userData?.email}
				token={userData?.t}
				orgId={userData?.orgId}
				id={userData?.id}
				retry={userData?.retry}
			/>
		</>
	);
}

UserConfirmation.propTypes = {
	location: PropTypes.objectOf(PropTypes.string),
};

export default withRouter(UserConfirmation);
