import ACTION_TYPE from './constants/actionType';

export const isServiceDeactivated = (service, serviceDisabled) =>
	serviceDisabled || service?.deactivated;

export const getOnClick = (
	onClick,
	serviceDisabled,
	dispatch,
	service,
	serviceDisableInDemo,
	alternateOnClick
) => {
	let dialogTitle;
	let dialogBody;
	if (!serviceDisabled && service?.deactivatedMessage) {
		dialogTitle = service?.deactivatedMessage?.title;
		dialogBody = service?.deactivatedMessage?.body;
	}
	const handleDispatch = () => {
		if (!serviceDisabled)
			dispatch({
				type: ACTION_TYPE.SET_DISABLE_DIALOG_MSG,
				disableDialogMsg: {
					title: dialogTitle,
					body: dialogBody,
				},
			});
		dispatch({ type: ACTION_TYPE.OPEN_DISABLE_DIALOG });
	};

	if (serviceDisableInDemo) return () => {};

	if (isServiceDeactivated(service, serviceDisabled)) return alternateOnClick || handleDispatch;

	return onClick;
};

export const getLabel = (label, serviceDisabled, alternateLabel = 'Learn more') => {
	return serviceDisabled ? alternateLabel : label;
};
