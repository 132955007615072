import { Auth } from 'aws-amplify';

import ACTION_TYPE from '../utils/constants/actionType';
import { DEMO } from '../utils/constants/demoData';
import ROUTES from '../utils/constants/routes';
import log from '../utils/log';

const userSignOut = async (history, dispatch) => {
	try {
		if (process.env.REACT_APP_ENV !== DEMO) {
			await Auth.signOut({ global: true });
		}
		dispatch({
			type: ACTION_TYPE.SET_USER,
			user: null,
		});
		dispatch({
			type: ACTION_TYPE.SET_USER_LOGGED_IN,
			isLoggedIn: false,
		});
		sessionStorage.clear();
		localStorage.clear();
		history.push(ROUTES.SIGN_IN);
	} catch (error) {
		log('error signing out: ', error);
	}
};

export default userSignOut;
