import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2.5, 8.5),
		borderBottom: '1px solid #C1C1C1',
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2, 6.25),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(4, 2, 1, 4),
			borderBottom: 0,
		},
		fontFamily: 'tropiline, serif',
	},
	logo: {
		textTransform: 'none',
		fontSize: 45,
		color: theme.palette.primary.main,
		[theme.breakpoints.down('md')]: {},
		[theme.breakpoints.down('sm')]: {
			fontSize: 22,
			paddingLeft: 0,
		},
		'&:hover': {
			background: '#FFFFFF',
		},
	},
	userIcon: {
		color: '#F48273',
		marginRight: theme.spacing(1.75),
		fontSize: '2rem',
	},
	userButton: {
		padding: theme.spacing(0.75, 0),
		textTransform: 'capitalize',
	},
	icon: {
		fontSize: '1.3rem',
		marginRight: 10,
	},
	menuItem: {
		padding: theme.spacing(1, 2),
		minHeight: 'auto',
	},
	partnershipTypography: {
		// display: 'flex',
		// marginTop: '-10px',
		fontSize: '12px',
		fontFamily: 'Gramatika, sans-serif',
		color: 'black',
		textAlign: 'center',
		[theme.breakpoints.down('md')]: {},
		[theme.breakpoints.down('sm')]: {
			fontSize: 8,
			paddingLeft: 0,
			textAlign: 'center',
		},
	},
	typography: {
		fontFamily: 'Gramatika, sans-serif',
	},
}));

export default useStyles;
