import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useStore } from '../store';

import styles from './Styles/MenuBar.Style';

function MenuBar(props) {
	const classes = styles();
	const history = useHistory();
	const [store] = useStore();
	const { isMobile } = store;
	const { publicHeader } = props;

	const handleClick = (path) => {
		history.push(`/${path}`);
	};

	return publicHeader ? (
		<div className={classes.publicHeader}>
			<Typography className={classes.publicTitle} onClick={() => handleClick('home')}>
				<b>Leda</b> Health
			</Typography>
		</div>
	) : (
		<div className={classes.backgroundDiv}>
			<Typography className={classes.title} onClick={() => handleClick('home')}>
				<b>Leda</b> Health
			</Typography>
			{isMobile ? null : (
				<div className={classes.textBoxes}>
					<Typography className={classes.text3} color="primary">
						At Leda Health, our mission is to transform existing systems of sexual assault
						prevention, care and justice to better serve survivors and the communities in which they
						live.
					</Typography>
				</div>
			)}
		</div>
	);
}

MenuBar.propTypes = {
	publicHeader: PropTypes.bool,
};

MenuBar.defaultProps = {
	publicHeader: false,
};

export default MenuBar;
