import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import EkkThumbnail from '../assets/eekThumbnail.svg';
import ModalCloseIcon from '../assets/ModalCloseIcon.svg';

import useStyles from './Styles/CancelOrderDialog.Style';

function CancelOrderDialog({
	open,
	setOpen,
	title,
	price,
	handleCancelOrder,
	product,
	cancelButtonLoading,
}) {
	const classes = useStyles();
	return (
		<Dialog classes={{ paper: classes.dialog }} onClose={() => setOpen(false)} open={open}>
			<DialogTitle>
				<img
					aria-hidden
					alt="An icon to close Modal"
					className={classes.closeIcon}
					onClick={() => setOpen(false)}
					src={ModalCloseIcon}
				/>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<Box>{title && <Typography className={classes.title}>{title}</Typography>}</Box>
				<Box className={classes.imgBox}>
					<Box className={classes.img}>
						<img
							alt={product}
							className={classes.img}
							height="inherit"
							src={EkkThumbnail}
							width="inherit"
						/>
					</Box>
					<Box className={classes.mainTextBox}>
						<Typography className={classes.textHeading}>{product}</Typography>
						<Typography className={classes.textSubHeading}>${price}</Typography>
					</Box>
				</Box>
				<Box>
					<Button
						className={classes.button}
						disabled={cancelButtonLoading}
						onClick={handleCancelOrder}>
						{cancelButtonLoading ? <CircularProgress size={24} /> : 'Yes, Cancel Order'}
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

CancelOrderDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func,
	title: PropTypes.string,
	price: PropTypes.number,
	handleCancelOrder: PropTypes.func,
	product: PropTypes.string,
	cancelButtonLoading: PropTypes.bool,
};

export default CancelOrderDialog;
