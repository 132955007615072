import API from '../utils/constants/apiEndPoints';
import PICKUP_KIT_ALIAS from '../utils/constants/pickUpKitAlias';
import log from '../utils/log';

import { post } from '.';

const fetchLockCode = async (user, setQRCodeRefreshing, service, lockId, refreshCode = false) => {
	setQRCodeRefreshing(true);
	let responseData;
	try {
		log('refreshCode', refreshCode);
		log('user', user);
		const body = {
			userId: user.cognitoSub,
			lockInfo: {
				orgId: user.orgId,
				lockId,
			},
			refreshCode,
			service: PICKUP_KIT_ALIAS[service],
		};
		const code = await post(API.FETCH_LOCK_CODE, false, { body });
		log('[PickUpKit][fetchLockCode] responseData', responseData);
		setQRCodeRefreshing(false);
		return code;
	} catch (e) {
		log('[PickUpKit][fetchLockCode] error fetching lock code', e.message);
		setQRCodeRefreshing(false);
	}
	return responseData;
};

export default fetchLockCode;
