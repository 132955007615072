import { Grid } from '@material-ui/core';
import React from 'react';

import { cardSkeleton, imageskeleton, lineskeleton } from './PickupCardSkeleton';

function PickupSekeleton() {
	return (
		<div
			style={{
				padding: '20px',
			}}>
			<Grid container spacing={0}>
				<Grid sx={12} md={8} item>
					{cardSkeleton}
					{lineskeleton('90%', 4)}
					{cardSkeleton}
				</Grid>
				<Grid sx={12} md={4} item>
					{imageskeleton(250, 250)}
				</Grid>
			</Grid>
			{lineskeleton('100%', 4)}
			<Grid container spacing={0}>
				<Grid sx={12} md={8} item>
					{cardSkeleton}
				</Grid>
				<Grid sx={12} md={4} item>
					{imageskeleton(250, 140)}
				</Grid>
			</Grid>
			{lineskeleton('100%', 4)}
			<Grid container spacing={0}>
				<Grid sx={12} md={8} item>
					{cardSkeleton}
				</Grid>
			</Grid>
			{lineskeleton('100%', 4)}
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
				}}>
				{lineskeleton('20%', 34)}
			</div>
		</div>
	);
}

export default PickupSekeleton;
