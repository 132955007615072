export const ORDER_FORM_FIELDS = [
	{
		label: 'AUTOGENERATED NAME ASSIGNED TO YOUR ORDER',
		name: 'contactName',
		disabled: true,
		placeholder: 'Venus',
		isRequired: true,
	},
	{ label: 'Street*', name: 'street', placeholder: 'Street', isRequired: true },
	{
		label: 'Unit',
		name: 'unit',
		placeholder: 'Apartment # / Building #',
		isRequired: false,
	},
	{ label: 'City*', name: 'city', placeholder: 'Enter City', isRequired: true },
	{
		label: 'State*',
		name: 'state',
		type: 'select',
		placeholder: 'Select State',
		isRequired: true,
	},
	{
		label: 'Zip Code*',
		name: 'zipCode',
		placeholder: 'Enter zip code',
		type: 'number',
		isRequired: true,
	},
	{
		label: 'Phone Number*',
		name: 'phoneNumber',
		placeholder: 'Eg - [xxx] [xxx-xxxx]',
		type: 'number',
		isRequired: true,
	},
	// One Rail does not need email address
	// {label: 'Email Address*', name: 'email', type: 'email', placeholder: 'Enter email address'},
	{
		label: 'Delivery Instructions',
		name: 'deliveryInstructions',
		placeholder: 'For example if your building needs special instructions',
		isRequired: false,
	},
];

export const ORDER_STI_TEST_FORM_FIELD = [
	{
		label: 'AUTOGENERATED NAME ASSIGNED TO YOUR ORDER',
		name: 'contactName',
		disabled: true,
		placeholder: 'Venus',
		isRequired: true,
	},
	{ label: 'Street*', name: 'street', placeholder: 'Street', isRequired: true },
	{
		label: 'Unit',
		name: 'unit',
		placeholder: 'Apartment # / Building #',
		isRequired: false,
	},
	{ label: 'City*', name: 'city', placeholder: 'Enter City', isRequired: true },
	{
		label: 'State*',
		name: 'state',
		type: 'select',
		placeholder: 'Select State',
		isRequired: true,
	},
	{
		label: 'Zip Code*',
		name: 'zipCode',
		type: 'number',
		placeholder: 'Enter zip code',
		isRequired: true,
	},
	{
		label: 'Phone Number*',
		name: 'phoneNumber',
		placeholder: 'Eg - [xxx] [xxx-xxxx]',
		isRequired: true,
	},
	{
		label: 'Email Address*',
		name: 'email',
		type: 'email',
		placeholder: 'Enter email address',
		isRequired: true,
	},
	{
		label: 'Delivery Instructions',
		name: 'deliveryInstructions',
		placeholder: 'For example if your building needs special instructions',
		isRequired: false,
	},
];

export const ORDER_PLAN_B_FORM_FIELDS = [
	{
		label: 'AUTOGENERATED NAME ASSIGNED TO YOUR ORDER',
		name: 'contactName',
		disabled: true,
		placeholder: 'Venus',
		isRequired: true,
	},
	{ label: 'Street*', name: 'street', placeholder: 'Street', isRequired: true },
	{
		label: 'Unit',
		name: 'unit',
		placeholder: 'Apartment # / Building #',
		isRequired: false,
	},
	{ label: 'City*', name: 'city', placeholder: 'Enter City', isRequired: true },
	{
		label: 'State*',
		name: 'state',
		type: 'select',
		placeholder: 'Select State',
		isRequired: true,
	},
	{
		label: 'Zip Code*',
		name: 'zipCode',
		placeholder: 'Enter zip code',
		type: 'number',
		isRequired: true,
	},
	{
		label: 'Phone Number*',
		name: 'phoneNumber',
		placeholder: 'Eg - [xxx] [xxx-xxxx]',
		isRequired: true,
	},
	// One Rail does not need email address
	// {label: 'Email Address*', name: 'email', type: 'email', placeholder: 'Enter email address'},
	{
		label: 'Delivery Instructions',
		name: 'deliveryInstructions',
		placeholder: 'For example if your building needs special instructions',
		isRequired: false,
	},
];

export const ORDER_TOXICOLOGY_FORM_FIELDS = [
	{
		label: 'AUTOGENERATED NAME ASSIGNED TO YOUR ORDER',
		name: 'contactName',
		disabled: true,
		placeholder: 'Venus',
		isRequired: true,
	},
	{ label: 'Street*', name: 'street', placeholder: 'Street', isRequired: true },
	{
		label: 'Unit',
		name: 'unit',
		placeholder: 'Apartment # / Building #',
		isRequired: false,
	},
	{ label: 'City*', name: 'city', placeholder: 'Enter City', isRequired: true },
	{
		label: 'State*',
		name: 'state',
		type: 'select',
		placeholder: 'Select State',
		isRequired: true,
	},
	{
		label: 'Zip Code*',
		name: 'zipCode',
		type: 'number',
		placeholder: 'Enter zip code',
		isRequired: true,
	},
	{
		label: 'Phone Number*',
		name: 'phoneNumber',
		placeholder: 'Eg - [xxx] [xxx-xxxx]',
		isRequired: true,
	},
	{
		label: 'Email Address*',
		name: 'email',
		type: 'email',
		placeholder: 'Enter email address',
		isRequired: true,
	},
	{
		label: 'Delivery Instructions',
		name: 'deliveryInstructions',
		placeholder: 'For example if your building needs special instructions',
		isRequired: false,
	},
];
