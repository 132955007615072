import API from '../utils/constants/apiEndPoints';

import { post } from '.';

const sendAccessRequest = (orgDetails) => {
	const myInit = {
		headers: {
			Authorization: process.env.REACT_APP_NOTIFICATION_TOKEN,
		},
		body: {
			...orgDetails,
		},
	};

	return post(API.REQUEST_ACCESS, true, myInit);
};

export default sendAccessRequest;
