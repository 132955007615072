import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	footer: {
		marginTop: 'auto',
		width: '100%',
	},
	footerDiv: {
		minWidth: '100%',
		minHeight: '7vh',
		maxHeight: '7vh',
		backgroundColor: theme.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(3),
			marginTop: theme.spacing(1),
			minHeight: '10vh',
			maxHeight: '10vh',
		},
		[theme.breakpoints.down('md')]: {
			bottom: 0,
			minWidth: '100%',
			minHeight: '7vh',
			maxHeight: '7vh',
			backgroundColor: theme.palette.primary.main,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		[theme.breakpoints.up('md')]: {
			bottom: 0,
			minWidth: '100%',
			minHeight: '7vh',
			maxHeight: '7vh',
			backgroundColor: theme.palette.primary.main,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
	supportDiv: {
		paddingLeft: theme.spacing(5),
		paddingRight: theme.spacing(5),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		border: '2px solid white',
		maxWidth: 'fit-content',
		marginBottom: theme.spacing(3),
	},
	disclaimerDiv: {
		position: 'absolute',
		bottom: 0,
		padding: theme.spacing(5),
		color: theme.palette.default.main,
		backgroundColor: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			minHeight: '10vh',
			maxHeight: '10vh',
			flexDirection: 'column',
		},
	},
	icon: {
		fontSize: 8,
		color: theme.palette.default.main,
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	footerText: {
		color: theme.palette.default.main,
		[theme.breakpoints.down('sm')]: {
			fontSize: 12,
		},
	},
	alertBox: {
		background: '#B3E2C2',
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center',
		justifyContent: 'center',
		padding: theme.spacing(5, 12.5),
	},
	alertText: {
		fontSize: 16,
		lineHeight: '25px',
		letterSpacing: '0.02em',
		color: '#000000',
	},
	disclaimerBox: {
		background: theme.palette.primary.main,
		padding: theme.spacing(5, 11.25),
		color: '#FFF',
		fontSize: 16,
		[theme.breakpoints.down('md')]: {
			padding: 20,
			paddingBottom: 60,
		},
	},
	disclaimerText: {
		color: '#FFF',
		fontSize: 14,
		padding: theme.spacing(1, 0),
		[theme.breakpoints.down('md')]: {
			paddingBottom: 10,
			fontSize: 12,
			padding: 0,
		},
	},
	disclaimerHeading: {
		fontSize: 20,
		fontWeight: 'bold',
		padding: theme.spacing(2.5, 0),
		display: 'flex',
		alignItems: 'center',
		gap: '15px',
	},
	supportBox: {
		border: '1px solid #FFF',
		padding: theme.spacing(2.5, 4.25),
		width: 'fit-content',
		borderRadius: 10,
	},
	supportText: {
		fontWeight: 600,
		fontSize: 16,
		[theme.breakpoints.down('md')]: {
			fontSize: 12,
			padding: 0,
		},
	},
	link: {
		color: '#f9bccb',
		fontWeight: 'bold',
		textDecoration: 'none',
	},
	footerTextLink: {
		color: theme.palette.default.main,
		cursor: 'pointer',
		textDecoration: 'underline',
		[theme.breakpoints.down('sm')]: {
			fontSize: 12,
			marginRight: theme.spacing(1),
		},
	},
	footerLinks: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'row',
		},
	},

	ledaLogoBox: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'top',
		justifyContent: 'center',
		padding: theme.spacing(2),
		paddingTop: '20px',
		width: '180px',
		height: '20px',
		[theme.breakpoints.down('sm')]: {
			padding: '0px',
			width: '120px',
		},
	},
	ledaLogo: {
		width: 150,
		padding: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			width: '100px',
		},
	},
	forSurvivorsBySurvivors: {
		width: 130,
		marginLeft: '10px',
		[theme.breakpoints.down('sm')]: {
			width: '80px',
			marginLeft: '10px',
		},
	},

	partnershipText: {
		padding: '5px',
		marginLeft: '15px',
		fontWeight: 'lighter',
		color: '#82A7CD',
		fontSize: '14px',
		width: '100%',

		[theme.breakpoints.down('sm')]: {
			fontSize: 10,
			marginLeft: '5px',
			padding: '5px',
			alignContent: 'center',
		},
	},
	schoolLogo: {
		width: '80px',
		filter: 'brightness(0) invert(1)',
		[theme.breakpoints.down('sm')]: {
			width: '60px',
		},
	},
	copyright: {
		width: '70%',
		alignItems: 'right',
		justifyContent: 'right',
		align: 'right',
		textAlign: 'right',
		padding: '10px',
		[theme.breakpoints.down('sm')]: {
			width: '20%',
		},
	},
}));

export default styles;
