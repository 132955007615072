import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ErrorDialog from '../../Components/ErrorDialog';
import TrackOrder from '../../Components/TrackOrder';
import cancelSTITestOrder from '../../Services/cancelSTITestOrder';
import fetchSTITestOrderDetails from '../../Services/fetchSTITestOrderDetails';
import { useStore } from '../../store';
import getStatus from '../../utils/constants/orderStatus';
import ROUTES from '../../utils/constants/routes';
import SERVICE_LIST from '../../utils/constants/serviceList';
import log from '../../utils/log';

function TrackSTITest() {
	let interval;
	const [store, dispatch] = useStore();
	const { user, userServiceList, stiOrderDetails } = store;
	const [cancelButtonLoading, setCancelButtonLoading] = useState(false);
	const [errorAlert, setErrorAlert] = useState(false);

	const history = useHistory();
	const serviceData = userServiceList[SERVICE_LIST.STI_TESTING];

	const [orderDetails, setOrderDetails] = useState({
		deliveryId: stiOrderDetails?.deliveryId ?? '',
		orderId: stiOrderDetails?.orderId ?? '',
		orderStatus: stiOrderDetails?.orderStatus ?? '',
		updatedDate: stiOrderDetails?.updatedDate ?? '',
		deliverTo: stiOrderDetails?.deliverTo?.label ?? '',
		proofOfDelivery: stiOrderDetails?.proofOfDelivery ?? '',
		price: serviceData?.totalCost ?? 0,
	});

	const handleCancelOrder = async () => {
		setCancelButtonLoading(true);
		const res = await cancelSTITestOrder(
			user?.orgId,
			orderDetails?.orderId,
			orderDetails?.deliveryId
		);
		if (res?.success) {
			setCancelButtonLoading(false);
			history.push(ROUTES.CANCEL_ORDER);
		} else {
			setErrorAlert(true);
			setCancelButtonLoading(false);
		}
	};

	const stopFetching = () => {
		if (interval) {
			clearInterval(interval);
			interval = null;
		}
	};

	const fetchStatus = async () => {
		if (
			stiOrderDetails?.orderStatus &&
			getStatus(stiOrderDetails?.orderStatus).label === 'Delivered'
		) {
			stopFetching();
			return;
		}
		try {
			if (user?.toxicologyOrderId) {
				const data = await fetchSTITestOrderDetails(
					user?.cognitoSub,
					user?.toxicologyOrderId,
					dispatch
				);
				setOrderDetails({
					...orderDetails,
					...data,
					deliverTo: data?.deliverTo?.label,
				});
				if (getStatus(data?.orderStatus).label === 'Delivered') stopFetching();
			} else {
				log('Cannot fetch sti test order details: stiOrderId is missing');
			}
		} catch (err) {
			log(['Error fetching sti test order details'], err);
		}
	};

	const startFetching = () => {
		if (!interval) {
			fetchStatus();

			interval = setInterval(() => {
				fetchStatus();
			}, 30000);
		}
	};

	useEffect(() => {
		startFetching();
		return () => stopFetching();
	}, [user]);

	return (
		<>
			<TrackOrder
				cancelButtonLoading={cancelButtonLoading}
				handleCancelOrder={handleCancelOrder}
				handleRefresh={fetchStatus}
				orderDetails={orderDetails}
				product={SERVICE_LIST.STI_TESTING}
				title="Your order for the STI Test has been submitted"
			/>
			<ErrorDialog openAlertDialog={errorAlert} setOpenAlertDialog={setErrorAlert} />
		</>
	);
}

export default TrackSTITest;
