import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#1E4C4E', // teal
			second: '#BEE2E2',
		},
		secondary: {
			main: '#F48273',
			second: '#fbd9d5', // orange peach
		},
		default: {
			main: '#FFFFFF',
		},
		error: {
			main: '#ff4d4d', // red
		},
		tertiary: {
			main: '#F4F0EA', // beige
		},
		disabled: {
			main: '#d3d3d3',
			second: '#BEE2E2', // light teal
		},
	},
	typography: {
		fontFamily: 'Montserrat, sans-serif',
	},
	button: {
		textTransform: 'none',
		height: 52,
	},
	buttonMobile: {
		textTransform: 'none',
		height: 52,
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 1000,
			lg: 1200,
			xl: 1536,
		},
	},
});

export default theme;
