import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	dialog: {
		minHeight: '25%',
		minWidth: '30%',
		borderRadius: 15,
		alignItems: 'center',
		width: 1105,
		maxWidth: 'unset',
		padding: theme.spacing(8.5, 14),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(7, 2),
		},
	},
	dialogContent: {
		textAlign: 'center',
		display: 'contents',
	},
	title: {
		fontWeight: 400,
		fontSize: 30,
		lineHeight: '37px',
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			fontSize: 20,
			lineHeight: '24px',
		},
	},
	dialogAction: {
		padding: theme.spacing(8.25, 0),
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			width: '100%',
			padding: theme.spacing(2.5, 0),
		},
	},
	closeIcon: {
		position: 'absolute',
		cursor: 'pointer',
		top: theme.spacing(6.25),
		right: theme.spacing(6.25),
		[theme.breakpoints.down('sm')]: {
			top: theme.spacing(5),
			right: theme.spacing(4),
			width: theme.spacing(1.5),
		},
	},
	button: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(8.75),
		background: '#BEE2E2 !important',
		borderRadius: '9px !important',
		color: `${theme.palette.primary.main} !important`,
		padding: '10px 34px !important',
		textTransform: 'none !important',
		width: 300,
		'&:hover': {
			background: '#BEE2E2',
			color: theme.palette.primary.main,
		},
	},
	imgBox: {
		display: 'flex',
		border: '1px solid #C7D2D3',
		borderRadius: 14,
		padding: theme.spacing(1.5),
		marginTop: theme.spacing(5.6),
		alignItems: 'center',
	},
	img: {
		borderRadius: 14,
		[theme.breakpoints.down('sm')]: {
			width: 125,
		},
	},
	mainTextBox: {
		margin: theme.spacing(0, 3.75, 0, 3.75),
		color: theme.palette.primary.main,
		textAlign: 'left',
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(0, 0, 0, 3),
		},
	},
	textHeading: {
		fontSize: 20,
		lineHeight: '25px',
	},
	textSubHeading: {
		fontSize: 14,
		lineHeight: '17px',
	},
}));

export default useStyles;
