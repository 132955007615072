import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	topBar: {
		// marginTop: theme.spacing(3),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		height: '8%',
		[theme.breakpoints.down('sm')]: {
			height: '5%',
			alignItems: 'center',
		},
	},
	topBarWithTitle: {
		// marginTop: theme.spacing(3),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		height: '10%',
		[theme.breakpoints.down('sm')]: {
			height: '5%',
			alignItems: 'center',
			paddingLeft: theme.spacing(1),
		},
	},
	topBar2: {
		// marginTop: theme.spacing(3),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	backBtn: {
		height: 'fit-content',
		minWidth: 200,
		maxWidth: 200,
		color: theme.palette.secondary.main,
		display: 'flex',
		justifyContent: 'left',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 0,
			height: 40,
			paddingRight: 0,
			minWidth: 100,
			maxWidth: 100,
			width: 100,
		},
	},
	otherBtn: {
		// height: 40,
		height: 'fit-content',
		minWidth: 200,
		maxWidth: 200,
		wordBreak: 'break-all',
		[theme.breakpoints.down('sm')]: {
			height: 40,
			minWidth: 100,
			maxWidth: 100,
			width: 100,
			wordBreak: 'break-all',
		},
	},
	otherBtn2: {
		display: 'flex',
		justifyContent: 'right',
		// height: 40,
		// height: 'fit-content',
		minWidth: 200,
		maxWidth: 200,
		// wordBreak: 'break-all',
		[theme.breakpoints.down('sm')]: {
			height: 40,
			minWidth: 100,
			maxWidth: 100,
			width: 100,
			// wordBreak: 'break-all'
		},
	},
	logoutBtn: {
		height: 40,
		minWidth: 150,
		maxWidth: 150,
		wordBreak: 'break-all',
		[theme.breakpoints.down('sm')]: {
			height: 40,
			minWidth: 150,
			maxWidth: 150,
			width: 150,
			wordBreak: 'break-all',
		},
	},
	icon: {
		paddingRight: theme.spacing(1),
		fontSize: 30,
		color: theme.palette.secondary.main,
		[theme.breakpoints.down('sm')]: {
			paddingRight: theme.spacing(1),
			fontSize: 25,
			color: theme.palette.secondary.main,
		},
	},
	faqButton: {
		textDecoration: 'none',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		minWidth: '100%',
	},
}));

export default styles;
