import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	loading: {
		padding: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'center',
		justifyItems: 'center',
		width: '100%',
		height: '100vh',
	},
	loadingTitle: {
		position: 'absoulte',
		top: '40%',
		left: '20%',
	},
}));

export default styles;
