import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	InputLabel,
	TextField,
	Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ArrowLeft from '../assets/ArrowLeft.svg';
import useStyles from '../Pages/Kit/Styles/OrderKit.Style';
import { useStore } from '../store';
import ROUTES from '../utils/constants/routes';
import { generateAlias } from '../utils/helperFunctions';
import log from '../utils/log';

import Footer from './Footer';
import Header from './Header';
import Loading from './Loading';
import SelectState from './SelectState';

function OrderKit({ children, formFields, title, subTitle, handleSubmit }) {
	const [store] = useStore();
	const { user, isMobile } = store;
	const history = useHistory();
	const classes = useStyles();
	const [state, setState] = useState('');
	const [orderKitDetails, setOrderKitDetails] = useState(
		formFields.reduce((obj, item) => ({ ...obj, [item.name]: '' }), {})
	);
	const [btnDisable, setBtnDisable] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [termsChecked, setTermsChecked] = useState(
		title === 'Order Toxicology' || title === 'Order STI Test'
	);

	const handleChange = (key, value) => {
		setOrderKitDetails({
			...orderKitDetails,
			[key]: value,
		});
	};

	const fetchAlias = () => {
		const aliasName = generateAlias();
		handleChange('contactName', aliasName);
		log('[OrderKit] alias name chosen', aliasName);
	};

	const updateEmail = () => {
		if (orderKitDetails?.contactName) handleChange('email', user?.email);
	};

	const checkInfo = () => {
		const isOrderDetailMissing = Object.keys(orderKitDetails).filter((item) => {
			const obj = formFields.find((i) => i.name === item);
			return orderKitDetails[item] === '' && obj.isRequired;
		});
		setBtnDisable(isOrderDetailMissing.length > 0);
		return isOrderDetailMissing;
	};

	useEffect(() => {
		handleChange('state', state);
	}, [state]);
	useEffect(checkInfo, [orderKitDetails]);
	useEffect(fetchAlias, []);
	useEffect(updateEmail, [user]);

	return user ? (
		<Box className={classes.root}>
			{!isMobile && <Header showProfileMenu />}
			<Box className={classes.main}>
				<Box className={classes.topBar}>
					<Button
						className={classes.button}
						onClick={() => history.push(ROUTES.HOME)}
						startIcon={
							<img
								alt="A left arrow icon to signify that the page will open in a new tab."
								src={ArrowLeft}
							/>
						}>
						Back
					</Button>
				</Box>
				<Box className={classes.container}>
					<Typography className={classes.title}>{title}</Typography>
					<Typography className={classes.subTitle}>{subTitle}</Typography>
					<form
						autoComplete="off"
						className={classes.formContainer}
						onSubmit={(e) => handleSubmit(e, orderKitDetails, setIsLoading)}>
						{formFields.map((item) => (
							<Box key={item.name}>
								<InputLabel className={classes.fieldInput}>{item.label}</InputLabel>
								{item?.type === 'select' ? (
									<SelectState setState={setState} state={state} />
								) : (
									<>
										<TextField
											InputProps={{
												classes: {
													notchedOutline: classes.textFieldOutline,
												},
											}}
											className={classes.textField}
											disabled={item?.disabled}
											fullWidth
											maxRows={item?.rows}
											minRows={item?.rows}
											multiline={Boolean(item?.rows)}
											name={item?.name}
											onChange={(e) => handleChange(item?.name, e.target.value)}
											placeholder={item?.placeholder}
											required={item?.isRequired}
											type={item?.type || 'text'}
											value={orderKitDetails[item?.name]}
											variant="outlined"
										/>
										{title === 'Order an Early Evidence Kit' && item.name === 'phoneNumber' && (
											<>
												<Box className={classes.checkBox}>
													<Checkbox
														className={classes.checkIcon}
														color="primary"
														onClick={() => setTermsChecked(!termsChecked)}
														value={termsChecked}
													/>
													<label className={classes.terms}>
														I acknowledge that to order an Early Evidence Kit and facilitate
														delivery, my information will be shared with the delivery company and
														that I may be contacted by the delivery company via a phone call if
														there are any issues with delivering the Early Evidence Kit to the
														address I have provided.
													</label>
												</Box>
												<br />
											</>
										)}
										{title === 'Order Plan B' && item.name === 'phoneNumber' && (
											<>
												<Box className={classes.checkBox}>
													<Checkbox
														className={classes.checkIcon}
														color="primary"
														onClick={() => setTermsChecked(!termsChecked)}
														value={termsChecked}
													/>
													<label className={classes.terms}>
														I acknowledge that to order Plan B and facilitate delivery, my
														information will be shared with the delivery company and that I may be
														contacted by the delivery company via a phone call if there are any
														issues with delivering the Early Evidence Kit to the address I have
														provided.
													</label>
												</Box>
												<br />
											</>
										)}
									</>
								)}
							</Box>
						))}
						{children}
						<Button
							className={classes.submitButton}
							disabled={btnDisable || !termsChecked}
							fullWidth
							type="submit">
							{isLoading ? <CircularProgress size={24} /> : 'Place Order'}
						</Button>
					</form>
				</Box>
			</Box>
			<Footer disclaimer />
		</Box>
	) : (
		<Box className={classes.loaderBox}>
			<Loading />
		</Box>
	);
}

OrderKit.propTypes = {
	formFields: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
	title: PropTypes.string,
	subTitle: PropTypes.string,
	children: PropTypes.node,
	handleSubmit: PropTypes.func,
};

export default OrderKit;
