import ACTION_TYPE from './constants/actionType';
import log from './log';

const updateUserDetails = (userBody, dispatch) => {
	const user = JSON.parse(JSON.stringify(userBody));
	const organizationDetails = JSON.parse(JSON.stringify(user.orgRes));
	const userServices = organizationDetails?.serviceDetails ?? [];

	const serviceListObject = {};
	userServices.forEach((service) => {
		serviceListObject[service?.name] = service;
	});

	delete user.orgRes;

	log('userBody', userBody.cognitoSub);
	dispatch({
		type: ACTION_TYPE.SET_USER,
		user,
	});
	dispatch({
		type: ACTION_TYPE.SET_USER_LOGGED_IN,
		isLoggedIn: true,
	});
	dispatch({
		type: ACTION_TYPE.SET_USER_SERVICE_LIST,
		userServiceList: serviceListObject,
	});
	dispatch({
		type: ACTION_TYPE.SET_USER_RESOURCE_LIST,
		userResources: organizationDetails?.orgResources ?? [],
	});
	dispatch({
		type: ACTION_TYPE.SET_USER_WELCOME_CONTENT,
		userWelcomeContent: organizationDetails?.orgWelcomeContent ?? {},
	});
	dispatch({
		type: ACTION_TYPE.SET_USER_EDUCATION,
		userEducation: organizationDetails?.orgEducation ?? [],
	});
	dispatch({
		type: ACTION_TYPE.SET_SERVICE_DISABLED,
		serviceDisabled: organizationDetails?.deactivated ?? false,
	});
	dispatch({
		type: ACTION_TYPE.SET_DISABLE_DIALOG_MSG,
		disableDialogMsg: {
			title: organizationDetails?.deactivatedMessage?.title,
			body: organizationDetails?.deactivatedMessage?.body,
		},
	});
	sessionStorage.setItem('userLoggedIn', true);
	const profileName = userBody?.email.split('@');
	log('[Home] userProfileName', profileName[0]);
	dispatch({
		type: ACTION_TYPE.SET_USER_PROFILE_NAME,
		userProfileName: profileName[0],
	});
};

export default updateUserDetails;
