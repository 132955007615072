const domainMapping = [
	{
		name: 'Southern Methodist University',
		emailDomain: '@smu.edu',
	},
	{
		name: 'The University of Texas at Arlington',
		emailDomain: '@uta.edu',
	},
	{
		name: 'Rice University',
		emailDomain: '@rice.edu',
	},
	{
		name: 'University of St.Thomas',
		emailDomain: '@stthom.edu',
	},
	{
		name: 'Texas Southern University',
		emailDomain: '@tsu.edu',
	},
	{
		name: 'University of Washington',
		emailDomain: '@uw.edu',
		groups: ['Kappa Delta'],
	},
	{
		name: 'University of Houston',
		emailDomain: '@uh.edu',
	},
	{
		name: 'University of Houston-Downtown',
		emailDomain: '@uhd.edu',
	},
	// THis is a test university
	{
		name: 'Leda',
		emailDomain: '@nyu.edu',
		groups: ['Product', 'Tech', 'Product/Tech', 'Kappa Delta'],
	},
	{
		name: 'University of North Florida',
		emailDomain: '@unf.edu',
	},
	{
		name: 'Jacksonville University',
		emailDomain: '@ju.edu',
	},
	{
		name: 'The University of Tampa',
		emailDomain: '@ut.edu',
	},
	{
		name: 'University of South Florida Main Campus',
		emailDomain: '@usf.edu',
	},
	{
		name: 'San Jose State University',
		emailDomain: '@sjsu.edu',
	},
	{
		name: 'Santa Clara University',
		emailDomain: '@scu.edu',
	},
	{
		name: 'San Diego Mesa College',
		emailDomain: '@sdmesa.edu',
	},
	{
		name: 'San Diego City College',
		emailDomain: '@sdccd.edu',
	},
	{
		name: 'University of San Diego',
		emailDomain: '@sandiego.edu',
	},
	{
		name: 'San Diego State University',
		emailDomain: '@sdsu.edu',
	},
	{
		name: 'University of California San Diego',
		emailDomain: '@ucsd.edu',
	},
	{
		name: 'Occidental College',
		emailDomain: '@oxy.edu',
	},
	{
		name: 'University of Southern California',
		emailDomain: '@usc.edu',
		groups: ['Alpha Phi'],
	},
	{
		name: 'California Institute of Technology',
		emailDomain: '@caltech.edu',
	},
	{
		name: 'California State University Los Angeles',
		emailDomain: '@calstatela.edu',
	},
];

export default domainMapping;
