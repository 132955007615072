export const servicesList = {
	// 'Immediate Care': [
	// 	{
	// 		heading: SERVICE_LIST.SEXUAL_ASSAULT_EXAM_MAP,
	// 		details: <AssualtExamMap />,
	// 		button: 'View Location Map',
	// 		action: 'https://www.leda.co/exam-locator',
	// 	},
	// 	{
	// 		heading: SERVICE_LIST.EARLY_EVIDENCE_KIT,
	// 		details: <EvidenceKit />,
	// 		button: <CheckEligibilityButton />,
	// 	},
	// 	{
	// 		heading: SERVICE_LIST.PEP,
	// 		details: <Pep />,
	// 		button: 'Get Started',
	// 		action: 'https://www.leda.co/exam-locator',
	// 	},
	// 	{
	// 		heading: SERVICE_LIST.PLAN_B,
	// 		details: <PlanB />,
	// 		button: <ConfirmationButton />,
	// 	},
	// 	{
	// 		heading: SERVICE_LIST.TOXICOLOGY_SCREENING,
	// 		details: <Toxicology />,
	// 		button: <ToxicologyGetStarted />,
	// 	},
	// ],
	// 'Follow-Up Care': [
	// 	{
	// 		heading: SERVICE_LIST.STI_TESTING,
	// 		details: <STITesting />,
	// 		button: <STIGetStarted />,
	// 	},
	// 	{
	// 		heading: SERVICE_LIST.CALLISTO,
	// 		details: <Callisto />,
	// 		button: 'Get Started',
	// 		action: 'https://www.callistovault.org/create-account',
	// 	},
	// ],
	// 'Long-Term Care': [
	// 	{
	// 		heading: SERVICE_LIST.PREP,
	// 		details: <PrEP />,
	// 		button: 'Get Started',
	// 		action: 'https://www.leda.co/exam-locator',
	// 	},
	// 	{
	// 		heading: SERVICE_LIST.BLOOM,
	// 		details: <Bloom />,
	// 		button: 'Learn More',
	// 		action: 'https://bloom.chayn.co/?utm_source=leda.co&utm_medium=referral&utm_id=ledahealth',
	// 	},
	// ],
	// '24/7 Live Support': [
	// 	{ heading: SERVICE_LIST.SEXUAL_ASSAULT_HOTLINE, details: <AssaultHotline /> },
	// 	{ heading: SERVICE_LIST.CRISIS_COUNSELORS, details: <CrisisCounselors /> },
	// 	{ heading: SERVICE_LIST.CARE_TEAM, details: <CareTeam />, button: <CareTeamButton /> },
	// ],
};

export const serviceColors = {
	'Immediate Care': 'blue',
	'Follow-Up Care': 'orange',
	'Long-Term Care': 'blue',
	'24/7 Live Support': 'white',
	'Early Evidence Kit': 'blue',
	'Toxicology Screening': 'blue',
	'STI/STD Testing': 'yellow',
};

export const filterList = [
	'All Services',
	'Immediate Care',
	'Follow-Up Care',
	'Long-Term Care',
	'24/7 Live Support',
];
