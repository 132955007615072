import ACTION_TYPE from '../utils/constants/actionType';
import API from '../utils/constants/apiEndPoints';
import ROUTES from '../utils/constants/routes';
import log from '../utils/log';

import { post } from './index';

const orderSTITest = async (
	stiOrderDetails,
	dispatch,
	setOpenAlertDialog,
	history,
	setIsLoading,
	orgId,
	userId,
	pickupLocation
) => {
	setIsLoading(true);
	const body = {
		...stiOrderDetails,
		orderDt: new Date(Date.now()).toISOString(),
		orgId,
		userId,
		pickupLocation,
	};

	log('[STI][handleOrderKit] Payload', body);
	try {
		const response = await post(API.ORDER_STI, false, { body });
		log('[STI] response', response);
		if (response) {
			log('inside response');
			dispatch({
				type: ACTION_TYPE.SET_STI_ORDERED,
				toxicologyOrdered: true,
			});

			dispatch({
				type: ACTION_TYPE.SET_STI_ORDER_DETAILS,
				stiOrderDetails: response,
			});
			history.push(ROUTES.TRACK_STI_ORDER);
		} else {
			setOpenAlertDialog(true);
		}
	} catch (err) {
		log('[STI] err catch', err);
		setOpenAlertDialog(true);
	}
	setIsLoading(false);
};

export default orderSTITest;
