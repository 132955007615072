import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	main: {
		height: '100%',
		width: '100%',
		display: 'flex',
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			flexDirection: 'row',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
			height: '100vh',
		},
	},
	publicMain: {
		height: '100%',
		width: '100%',
		display: 'flex',
		[theme.breakpoints.down('md')]: {
			height: '100%',
			width: '100%',
			display: 'flex',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column',
			height: '100vh',
		},
	},
	box1: {
		maxWidth: '40vw',
		width: '50%',
		[theme.breakpoints.down('md')]: {
			width: '50%',
		},
		[theme.breakpoints.down('sm')]: {
			height: '10vh',
			width: '100%',
			maxWidth: 'inherit',
		},
	},
	side: {
		display: 'flex',
		flexDirection: 'column',
		width: '60%',
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			flexDirection: 'column',
			width: '60%',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			width: '100%',
			position: 'relative',
		},
	},
	box2: {
		height: '90%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			height: '90vh',
		},
	},
	form: {
		padding: theme.spacing(2),
		maxHeight: '100%',
		width: '80%',
		maxWidth: 600,
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(2),
			minWidth: '100%',
			maxWidth: 600,
			maxHeight: '75vh',
			overflowY: 'auto',
			padding: theme.spacing(3),
		},
	},
	setupDivMobile: {
		marginTop: theme.spacing(5),
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	setupDiv: {
		display: 'flex',
		justifyContent: 'end',
		cursor: 'pointer',
		position: 'fixed',
		right: 50,
		top: '5%',
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(5),
			cursor: 'pointer',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			top: 'unset',
			right: 'unset',
			position: 'unset',
		},
	},
	text1: {
		wordWrap: 'break-word',
		fontSize: 16,
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			color: theme.palette.primary.main,
			fontWeight: 20,
		},
	},
	title: {
		fontFamily: theme.typography.fontFamily,
		fontSize: 45,
		fontStyle: 'normal',
		fontWeight: 400,
		// fontWeight: 'bold',
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			fontSize: 24,
			marginTop: -20,
			color: theme.palette.primary.main,
		},
	},
	input: {
		width: '100%',
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			color: theme.palette.primary.main,
		},
	},
	link: {
		cursor: 'pointer',
		color: theme.palette.secondary.main,
		textDecoration: 'underline',
	},
	linkBox: {
		cursor: 'pointer',
		color: theme.palette.primary.main,
		textDecoration: 'underline',
		margin: theme.spacing(0.5, 0),
	},
	linkBoxSuccess: {
		color: theme.palette.primary.main,
		margin: theme.spacing(0.5, 0),
	},
	text2: {
		fontSize: 16,
		minWidth: 280,
		color: theme.palette.primary.main,
		marginTop: theme.spacing(2),
		marginBottom: -theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			minWidth: 280,
			color: theme.palette.primary.main,
			marginTop: theme.spacing(2),
		},
	},
	submitBtn: {
		height: 52,
		width: '100%',
		marginTop: 40,
		backgroundColor: theme.palette.primary.second,
		'&:hover': {
			backgroundColor: theme.palette.primary.second,
		},
		'&:disabled': {
			backgroundColor: theme.palette.disabled.second,
		},
		[theme.breakpoints.down('sm')]: {
			'&:disabled': {
				backgroundColor: theme.palette.disabled.second,
			},
			height: 52,
			width: '100%',
			marginTop: theme.spacing(2),
			backgroundColor: theme.palette.primary.second,
			color: theme.palette.primary.main,
		},
	},
	inputFilled: {
		width: '100%',
		color: theme.palette.primary.main,
		backgroundColor: '#E2FAE9',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			color: theme.palette.primary.main,
		},
	},
	checkBoxLabel: {
		color: theme.palette.primary.main,
	},
	privacyLink: {
		color: theme.palette.secondary.main,
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	footer: {
		[theme.breakpoints.down('md')]: {
			bottom: 0,
			padding: theme.spacing(5),
			color: theme.palette.default.main,
			backgroundColor: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			bottom: 0,
			padding: theme.spacing(5),
			color: theme.palette.default.main,
			backgroundColor: theme.palette.primary.main,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			minWidth: '100%',
			minHeight: '10vh',
			maxHeight: '10vh',
			flexDirection: 'column',
		},
	},
	status: {
		fontSize: 14,
		fontWeight: 500,
		float: 'right',
		marginTop: 5,
	},
}));

export default styles;
