import { rest } from 'msw';

import API from '../../utils/constants/apiEndPoints';
import fetchEEKResponse from '../responses/orderEEKResponse.json';

const FetchPlanBOrder = [
	rest.get(`*${API.FETCH_PLANB_ORDER}`, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(fetchEEKResponse));
	}),
];

export default FetchPlanBOrder;
