import { rest } from 'msw';

import API from '../../utils/constants/apiEndPoints';

const CancelToxicologyOrder = [
	rest.post(`*${API.CANCEL_TOXICOLOGY_ORDER}`, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json({ success: true }));
	}),
];

export default CancelToxicologyOrder;
