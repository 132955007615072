import { rest } from 'msw';

import API from '../../utils/constants/apiEndPoints';
import orderPlanBResponse from '../responses/orderPlanBResponse.json';

const OrderPlanB = [
	rest.post(`*${API.PLAN_B_CREATE_ORDER}`, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(orderPlanBResponse));
	}),
];

export default OrderPlanB;
