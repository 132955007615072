/* eslint-disable no-nested-ternary */

import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import video from '../assets/BoxLock_Instructions_AdobeCreativeCloudExpress.gif';
import vid from '../assets/FindKitId.gif';
import log from '../utils/log';

import styles from './Styles/AlertDialog.Style';

function AlertDialog(props) {
	const [alertContentData, setAlertContentData] = useState({
		message: {
			title: '',
			line1: '',
			line2: '',
			line3: '',
			line4: '',
			line5: '',
		},
		level: '',
		path: '',
	});
	const classes = styles();

	const { alertContent, openAlertDialog, setOpenAlertDialog } = props;
	log('props', props);

	useEffect(() => {
		if (alertContent !== null && alertContent) {
			setAlertContentData(alertContent);
		}
	}, [alertContent]);

	return alertContent && alertContent !== '' && alertContent !== null ? (
		<Dialog
			classes={{ root: classes.div, paper: classes.dialog }}
			onClose={() => setOpenAlertDialog(false)}
			open={openAlertDialog}>
			<DialogTitle classes={{ root: classes.dialoTitle }} id="alert-dialog-title">
				{alertContentData.level === 'error' ? (
					<ErrorIcon className={classes.errorIcon} />
				) : alertContentData.level === 'success' ? (
					<CheckCircleIcon className={classes.icon} />
				) : null}
			</DialogTitle>
			<DialogContent classes={{ root: classes.dialogContent }}>
				<DialogContentText id="alert-dialog-description">
					{alertContentData.level === 'info' ? (
						<Typography align="center" variant="h6">
							<b>
								{alertContentData && alertContentData.message
									? alertContentData.message.title
									: null}
							</b>
						</Typography>
					) : (
						<Typography align="center">
							<b>
								{alertContentData && alertContentData.message
									? alertContentData.message.title
									: null}
							</b>
						</Typography>
					)}
					<br />
					<Typography align={alertContentData.level === 'info' ? 'center' : 'left'}>
						{alertContentData && alertContentData.message ? alertContentData.message.line1 : ''}
					</Typography>
					<Typography align={alertContentData.level === 'info' ? 'center' : 'left'}>
						{alertContentData.message ? alertContentData.message.line2 : ''}
					</Typography>
					<Typography>
						{alertContentData && alertContentData.message && alertContentData.message.line3
							? alertContentData.message.line3
							: null}
					</Typography>
					<Typography>
						{alertContentData && alertContentData.message && alertContentData.message.line4
							? alertContentData.message.line4
							: null}
					</Typography>
					<br />
					{alertContentData.level === 'info' ? (
						<img
							alt="A video that demonstrates the actions to be taken on this page."
							className={classes.photoStyle}
							src={vid}
						/>
					) : null}
					{alertContentData.level === 'locker_info' ? (
						<img
							alt="A video that describes locker information."
							className={classes.photoStyle}
							src={video}
						/>
					) : null}
				</DialogContentText>
			</DialogContent>
			<DialogActions classes={{ root: classes.dialogActions }}>
				<Button
					className={alertContentData.level === 'error' ? classes.errorButton : classes.button}
					fullWidth
					onClick={() => {
						setOpenAlertDialog(false);
					}}>
					Got it
				</Button>
			</DialogActions>
		</Dialog>
	) : null;
}

AlertDialog.propTypes = {
	alertContent: PropTypes.shape({
		message: PropTypes.shape(PropTypes.string),
		level: PropTypes.string.isRequired,
		path: PropTypes.string,
	}),
	openAlertDialog: PropTypes.bool,
	setOpenAlertDialog: PropTypes.func,
};

AlertDialog.defaultProps = {
	alertContent: {
		message: null,
		level: '',
		path: '',
	},
	openAlertDialog: false,
	setOpenAlertDialog: null,
};

export default AlertDialog;
