import { rest } from 'msw';

import API from '../../utils/constants/apiEndPoints';
import orderToxicologyResponse from '../responses/orderToxicologyResponse.json';

const FetchToxicologyOrder = [
	rest.get(`*${API.FETCH_TOXICOLOGY_ORDER}`, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(orderToxicologyResponse));
	}),
];

export default FetchToxicologyOrder;
