import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	div: {
		maxWidth: '100vw',
	},
	dialog: {
		padding: theme.spacing(3),
		minHeight: '30%',
		minWidth: '40%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			minHeight: '30%',
			maxHeight: '80%',
			boxSizing: 'border-box',
		},
	},
	dialogContent: {
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1),
			minHeight: '30%',
			maxHeight: '80%',
			maxWidth: '100%',
			boxSizing: 'border-box',
		},
	},
	linkText: {
		color: '#F48273',
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	closeIcon: {
		position: 'absolute',
		cursor: 'pointer',
		top: theme.spacing(3),
		right: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			top: theme.spacing(3.2),
			right: theme.spacing(2.5),
			width: theme.spacing(1.5),
		},
	},
}));

export default styles;
