import { rest } from 'msw';

import API from '../../utils/constants/apiEndPoints';

const SendEmailNotification = [
	rest.post(`*${API.NOTIFICATION_EMAIL_PUBLIC}`, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json('Email Sent!!!!!!!!!'));
	}),
];

export default SendEmailNotification;
