import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	photoStyle: {
		width: '100%',
		height: '100%',
		marginTop: theme.spacing(1),
		// margin: theme.spacing(1)
	},
	bannerStyle: {
		width: '100%',
		height: '99%',
		padding: 0,
		// marginTop: theme.spacing(1)
		// margin: theme.spacing(1)
	},
	pdfStyle: {
		width: '100%',
		height: '100%',
	},
	dialogWithPDF: {
		minWidth: '50%',
	},
	dialog: {
		padding: 0,
		minWidth: '40%',
		[theme.breakpoints.down('sm')]: {
			minWidth: '80%',
			padding: 0,
		},
	},
	formDialog: {
		padding: 0,
		minWidth: '40%',
		height: 600,
		// maxHeight: 600,
		// overflowY: 'hidden',
		borderRadius: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			minWidth: '80%',
		},
	},
	button: {
		margin: theme.spacing(2),
		backgroundColor: theme.palette.primary.main,
		color: 'white',
		width: '100%',
		height: 'fit-content',
		padding: theme.spacing(2),
		textTransform: 'none',
	},
	navButton: {
		margin: theme.spacing(2),
		backgroundColor: theme.palette.primary.main,
		color: 'white',
		width: '50%',
		height: 'fit-content',
		padding: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			backgroundColor: theme.palette.primary.main,
			color: 'white',
			margin: 0,
			width: '100%',
			padding: theme.spacing(2),
		},
	},
	formDialogContent: {
		padding: '0 !important',
		height: '100%',
		maxHeight: '100%',
		overflowY: 'hidden',
	},
	dialogContent: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'start',
		padding: 0,
		'&:first-child': {
			padding: 0,
		},
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
			padding: 0,
		},
	},
	eligibilityForm: {
		height: '100%',
		borderRadius: theme.spacing(2),
	},
	dialogButton: {
		textDecoration: 'none',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		color: '#FFFFFF',
	},
	forwardIcon: {
		marginLeft: 8,
		fontSize: 18,
	},
	closeIcon: {
		position: 'absolute',
		cursor: 'pointer',
		top: theme.spacing(2.5),
		right: theme.spacing(2.5),
		[theme.breakpoints.down('sm')]: {
			top: theme.spacing(5),
			right: theme.spacing(4),
			width: theme.spacing(1.5),
		},
	},
}));

export default styles;
