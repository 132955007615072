import { Box, Button, Dialog, Divider, Typography } from '@material-ui/core';
import CameraEnhanceOutlinedIcon from '@material-ui/icons/CameraEnhanceOutlined';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import activeIcon from '../assets/activeIcon.svg';
import myLabBoxIcon from '../assets/myLabBoxIcon.svg';
import RefreshIcon from '../assets/refreshIcon.svg';
import { useStore } from '../store';
import { DEMO } from '../utils/constants/demoData';
import getStatus from '../utils/constants/orderStatus';
import ROUTES from '../utils/constants/routes';
import SERVICE_LIST from '../utils/constants/serviceList';

import CancelOrderDialog from './CancelOrderDialog';
import Footer from './Footer';
import Header from './Header';
import Loading from './Loading';
import useStyles from './Styles/TrackOrder.Style';

function TrackOrder({
	title,
	product,
	handleRefresh,
	orderDetails,
	handleCancelOrder,
	cancelButtonLoading,
}) {
	const classes = useStyles();
	const [store] = useStore();
	const history = useHistory();
	const { isMobile, user, userServiceList } = store;

	const [cancelOrderDialog, setCancelOrderDialog] = useState(false);
	const [isViewerOpen, setIsViewerOpen] = useState(false);

	const openImageViewer = useCallback(() => {
		setIsViewerOpen(true);
	}, []);

	const status = useMemo(() => getStatus(orderDetails?.orderStatus), [orderDetails]);

	const planBData = userServiceList[SERVICE_LIST.PLAN_B];

	return user && orderDetails?.orderId ? (
		<Box className={classes.root}>
			{!isMobile && <Header showProfileMenu />}
			<Box className={classes.main}>
				<Box boxShadow={3} className={classes.container}>
					<Box className={classes.submitBox}>
						<Box className={classes.imgBox}>
							<img alt="Active icon" className={classes.imgCheck} src={activeIcon} />
						</Box>
						<Typography className={classes.title}>{title}</Typography>
					</Box>
					<Divider />
					<Box className={classes.orderBox}>
						<Box>
							<Typography className={classes.items}>
								Order Number: <b>{orderDetails?.orderId}</b>
							</Typography>
							<Typography className={classes.items}>
								{'Status: '}
								<span className={classes.statusBlock} style={{ color: status?.color }}>
									<b>{status?.label}</b>
									{orderDetails?.proofOfDelivery && status?.label === 'Delivered' ? (
										<Button
											className={classes.refreshButton}
											onClick={openImageViewer}
											startIcon={<CameraEnhanceOutlinedIcon />}
											variant="outlined">
											View
										</Button>
									) : (
										<Button
											className={classes.refreshButton}
											onClick={handleRefresh}
											startIcon={<img alt="refresh icon" src={RefreshIcon} />}
											variant="outlined">
											Refresh
										</Button>
									)}
								</span>
							</Typography>
							<Typography className={classes.items}>
								{`Deliver To: ${orderDetails?.deliverTo}`}
							</Typography>
							<Typography className={classes.items}>
								{'Total Cost: '}
								<span className={classes.statusBlock}>
									${orderDetails?.price} (Covered by your Organization)
								</span>
							</Typography>
							{product !== SERVICE_LIST.TOXICOLOGY_SCREENING &&
							product !== SERVICE_LIST.PLAN_B &&
							!planBData?.deactivated &&
							planBData.enabled ? (
								<Typography className={classes.items}>
									{`Plan B Included: ${orderDetails?.planBIncluded ? 'Yes' : 'No'}`}
								</Typography>
							) : null}
						</Box>
						{status?.label === 'Order Placed' ||
							('Ready for Dispatch' && (
								<Button
									className={classes.cancelButton}
									onClick={() => setCancelOrderDialog(true)}
									variant="outlined">
									<b>Cancel Order</b>
								</Button>
							))}
					</Box>
					<Divider />
					<Box className={classes.submitBox}>
						<Typography className={classes.subTitle}>
							<b>Your order has been placed. Here&apos;s what to expect:</b>
						</Typography>
						<Box className={classes.orderBox}>
							<ul className={classes.list}>
								<li className={classes.listItem}>
									<Typography>
										Your order will be delivered approximately in the next 2 hours.
									</Typography>
								</li>
								<br />
								{process.env.REACT_APP_ENV !== DEMO && (
									<>
										<li className={classes.listItem}>
											<Typography>
												{
													'Your order will arrive in a sealed white plastic bag with the alias name '
												}
												<b>Cera Medical</b>
												{' on it.'}
											</Typography>
										</li>
										<br />
									</>
								)}
								<li className={classes.listItem}>
									<Typography>
										This will be a contactless drop delivery, the driver will not contact you unless
										absolutely necessary.
									</Typography>
								</li>
							</ul>
							<ul className={classes.list}>
								<li className={classes.listItem}>
									<Typography>
										In the case they do need to contact you, please expect a text message and not a
										phone call.
									</Typography>
								</li>
								<br />
								<li className={classes.listItem}>
									<Typography>
										The kit will be delivered mimicing a food delivery (to your door) instead of a
										package delivery (to your mail room).
									</Typography>
								</li>
							</ul>
						</Box>
						{product === SERVICE_LIST.TOXICOLOGY_SCREENING && (
							<div className={classes.brandWrapper}>
								Powered By:
								<img
									alt="A QR code for the lock."
									className={
										isMobile ? classes.img : `${classes.marginLeft} ${classes.marginRight}`
									}
									src={myLabBoxIcon}
								/>
								&nbsp;
								<a
									style={{
										color: 'black',
										fontWeight: 'bold',
									}}
									href="https://www.mylabbox.com/product/at-home-comprehensive-drug-test/"
									rel="noreferrer"
									target="_blank">
									Learn more
								</a>
							</div>
						)}
					</Box>
					<Box className={classes.submitBox}>
						{product === SERVICE_LIST.TOXICOLOGY_SCREENING && (
							<Button
								className={classes.activateButton}
								onClick={() => window.open('https://www.mylabbox.com/register/', '_blank')}>
								Activate my Toxicology Test
							</Button>
						)}
						<Button
							className={classes.homeButton}
							onClick={() => history.push(ROUTES.KIT_DASHBOARD)}>
							Go to My Tests
						</Button>
					</Box>
				</Box>
			</Box>
			<Footer disclaimer />
			<CancelOrderDialog
				cancelButtonLoading={cancelButtonLoading}
				handleCancelOrder={handleCancelOrder}
				open={cancelOrderDialog}
				price={orderDetails?.price}
				product={product}
				setOpen={setCancelOrderDialog}
				title="Are you sure you want to cancel your order?"
			/>
			<Dialog onClose={() => setIsViewerOpen(false)} open={isViewerOpen}>
				<Box>
					<img alt="Delivery Proof" src={orderDetails?.proofOfDelivery} />
				</Box>
			</Dialog>
		</Box>
	) : (
		<Box className={classes.loaderBox}>
			<Loading />
		</Box>
	);
}

TrackOrder.propTypes = {
	cancelButtonLoading: PropTypes.bool,
	title: PropTypes.string,
	product: PropTypes.string,
	orderDetails: {
		orderId: PropTypes.string,
		orderStatus: PropTypes.string,
		proofOfDelivery: PropTypes.string,
		deliverTo: PropTypes.string,
		price: PropTypes.number,
		planBIncluded: PropTypes.bool,
	},
	handleRefresh: PropTypes.func,
	handleCancelOrder: PropTypes.func,
};

export default TrackOrder;
