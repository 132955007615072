import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect } from 'react';

import styles from './Styles/Loading.Style';

function Loading() {
	const classes = styles();
	const [progress, setProgress] = useState(0);
	const color = 'secondary';
	const size = '5rem';
	const thickness = 0.5;
	const value = progress;

	useEffect(() => {
		const timer = setInterval(() => {
			setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
		}, 200);
		return () => {
			clearInterval(timer);
		};
	}, []);

	return (
		<div className={classes.loading}>
			<CircularProgress
				color={color}
				size={size}
				thickness={thickness}
				value={value}
				variant="determinate"
			/>
			<Typography color="primary" component="div" variant="body2">
				{"We've "}
				<b>been there.</b>
			</Typography>
			<Typography color="primary" component="div" variant="body2">
				We
				<b>{' believe you.'}</b>
			</Typography>
			<Typography color="primary" component="div" variant="body2">
				We are
				<b>{' here to help you.'}</b>
			</Typography>
		</div>
	);
}

export default Loading;
