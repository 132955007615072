import domainMapping from './constants/orgMapping';
import log from './log';

export const validatePassword = (string) => {
	log('[validatePassword] string', string);
	const allowedCharacters = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
	if (string.match(allowedCharacters)) {
		return true;
	}
	return false;
};

export const validateSchoolEmailDomain = (university, email) => {
	log('[validateSchoolEmailDomain] email', email);
	log('[validateSchoolEmailDomain] university', university);
	const allowedDomain = domainMapping.find((uni) => uni.name === university);
	log('[validateSchoolEmailDomain] allowedDomain', allowedDomain);
	if (email.includes(allowedDomain.emailDomain)) {
		return true;
	}
	return false;
};
