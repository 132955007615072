import { rest } from 'msw';

import API from '../../utils/constants/apiEndPoints';
import orderToxicologyResponse from '../responses/orderToxicologyResponse.json';

const OrderToxicology = [
	rest.post(`*${API.ORDER_TOXICOLOGY}`, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(orderToxicologyResponse));
	}),
];

export default OrderToxicology;
