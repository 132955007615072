import { rest } from 'msw';

import API from '../../utils/constants/apiEndPoints';
import FetchLockCodeResponse from '../responses/fetchLockCode.json';

const FetchLockCode = [
	rest.post(`*${API.FETCH_LOCK_CODE}`, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(FetchLockCodeResponse));
	}),
];

export default FetchLockCode;
