import { Box, List, ListItem, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Styles/CustomCard.Style';

function CustomCard({ description, logo }) {
	const classes = styles();
	return (
		<Box className={classes.root}>
			<List>
				{description.map((item, index) => {
					return (
						<ListItem key={(item + index).toString()} className={classes.listItem}>
							<ListItemText
								primary={item?.body}
								primaryTypographyProps={{ className: classes.listText }}
							/>
						</ListItem>
					);
				})}
			</List>
			<div className={classes.imageLogo}>
				<img alt="Partnership Logo" src={logo} />
			</div>
		</Box>
	);
}

CustomCard.propTypes = {
	description: PropTypes.arrayOf(
		PropTypes.shape({
			body: PropTypes.string,
		})
	),
	logo: PropTypes.string,
};

export default CustomCard;
