import API from '../utils/constants/apiEndPoints';

import { post } from '.';

const confirmUser = (token, email, id) => {
	const headers = {
		Authorization: token,
	};
	const body = {
		t: token,
		id,
		email,
	};
	// return post(
	//	`${API.USER_CONFIRM}?t=${encodeURIComponent(token)}&id=${encodeURIComponent(id)}`,
	//	true,
	//	{ headers, body }
	// );
	return post(
		`${API.USER_CONFIRM}?t=${encodeURIComponent(token)}&email=${encodeURIComponent(email)}`,
		true,
		{ headers, body }
	);
};

export default confirmUser;
