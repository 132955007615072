/* eslint-disable unused-imports/no-unused-vars */
import {
	Button,
	CircularProgress,
	InputLabel,
	Tooltip,
	Typography,
	TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import AuthLayout from '../../Components/AuthLayout';
import Loading from '../../Components/Loading';
import PasswordRequirements from '../../Components/PasswordRequirements';
import userSignOut from '../../Services/userSignOut';
import { useStore } from '../../store/index';
import { validatePassword } from '../../utils/validators';
import sharedStyles from '../Styles/SharedStyles';

import styles from './Styles/ResetPassword.Style';

function SetNewPassword() {
	const classes = { ...styles(), ...sharedStyles() };
	const [store, dispatch] = useStore();
	const history = useHistory();
	const { user } = store;
	const [password, setPassword] = useState('');
	// const [oldPassword, setOldPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [formError, setFormError] = useState('');
	const [resetMessage, setResetMessage] = useState('');
	const [disableBtn, setDisableBtn] = useState(false);

	const handleSignOut = async (e) => {
		e.preventDefault();
		userSignOut(history, dispatch);
	};

	const handleSubmit = async (e) => {
		setDisableBtn(true);
		if (
			password !== '' &&
			// oldPassword !== '' &&
			confirmPassword !== '' &&
			password === confirmPassword &&
			validatePassword(password) &&
			validatePassword(confirmPassword)
		) {
			// try {
			//   const userAuth = await Auth.currentAuthenticatedUser();
			//   const resetRes = await Auth.changePassword(userAuth, oldPassword, password);
			//   log('[SetNewPassword] Response from resetting password', resetRes);
			//   setResetMessage(resetRes.message);
			//   await handleSignOut(e);
			// } catch (error) {
			//   const errorMessage =
			//     error.response?.data?.message ||
			//     error.message ||
			//     error.response.message ||
			//     error.response.data.message;
			//   log('error resetting password up:', errorMessage);
			//   setFormError(errorMessage);
			// }
		} else if (password !== confirmPassword) {
			setFormError('Passwords do not match');
		} else {
			setFormError(
				'A password should be between 8 to 15 characters, contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.'
			);
		}
		setDisableBtn(false);
	};

	return user ? (
		<AuthLayout>
			<div className={classes.main} style={{ justifyContent: 'center' }}>
				<div className={classes.side}>
					<div className={classes.box2}>
						<form autoComplete="off" className={classes.form}>
							<Typography className={classes.title} color="primary" variant="h5">
								Set New Password
							</Typography>
							<Typography className={classes.text1} color="primary" variant="subtitle1">
								Please enter a password for your account.
							</Typography>
							<InputLabel className={classes.text2} color="primary" required>
								NEW PASSWORD
							</InputLabel>
							<Tooltip
								arrow
								classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
								open={password !== '' && !validatePassword(password)}
								placement="right"
								title={<PasswordRequirements password={password} />}>
								<TextField
									classes={{
										root: password === '' ? classes.input : classes.inputFilled,
									}}
									defaultValue=""
									margin="normal"
									onChange={(e) => {
										setFormError('');
										setPassword(e.target.value);
									}}
									required
									type="password"
									variant="outlined"
								/>
							</Tooltip>
							<InputLabel className={classes.text2} required>
								CONFIRM PASSWORD
							</InputLabel>
							<Tooltip
								arrow
								classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
								open={confirmPassword !== '' && !validatePassword(confirmPassword)}
								placement="right"
								title={<PasswordRequirements password={confirmPassword} />}>
								<TextField
									classes={{
										root: confirmPassword === '' ? classes.input : classes.inputFilled,
									}}
									defaultValue=""
									margin="normal"
									onChange={(e) => {
										setFormError('');
										setConfirmPassword(e.target.value);
									}}
									required
									type="password"
									variant="outlined"
								/>
							</Tooltip>
							<br />
							<div className={classes.errorMsg}>{formError}</div>
							<div className={classes.resetMsg}>{resetMessage}</div>
							<Button
								classes={{
									root: classes.submitBtn,
									disabled: classes.disabledBtn,
								}}
								disabled={
									password === '' || confirmPassword === '' || formError !== '' || disableBtn
								}
								onClick={(e) => handleSubmit(e)}
								variant="contained">
								<b>{disableBtn ? <CircularProgress size={24} /> : 'Submit'}</b>
							</Button>
						</form>
					</div>
				</div>
			</div>
		</AuthLayout>
	) : (
		<Loading />
	);
}
export default SetNewPassword;
