import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

import KitDialog from '../../Components/KitDialog';
import UserLayout from '../../Components/UserLayout';
import { showIntercom } from '../../Services/intercom/method';
import { useStore } from '../../store';
import { DEMO } from '../../utils/constants/demoData';
import ROUTES from '../../utils/constants/routes';
import SERVICE_LIST from '../../utils/constants/serviceList';
import { getOnClick } from '../../utils/disableServices';

import useStyles from './Styles/STIReviewPage.Style';

function STIReviewPage() {
	const classes = useStyles();
	const [store, dispatch] = useStore();
	const history = useHistory();
	const { userServiceList, serviceDisabled } = store;

	const [openDialog, setOpenDialog] = React.useState(false);
	const careTeamServiceData = userServiceList[SERVICE_LIST.CARE_TEAM];

	const serviceDisableInDemo = process.env.REACT_APP_ENV === DEMO;

	const handleClick = () => {
		const serviceData = userServiceList[SERVICE_LIST.STI_TESTING];
		const pickup = serviceData?.pickup;
		const delivery = serviceData?.delivery;

		if (pickup && delivery) {
			setOpenDialog(true);
		} else if (pickup) {
			history.push(ROUTES.PICK_UP_STI);
		} else if (delivery) {
			history.push(ROUTES.ORDER_KIT);
		}
	};

	const handleCareTeamClick = async () => {
		await showIntercom();
	};

	return (
		<UserLayout>
			<div className={classes.root}>
				<Typography className={classes.heading}>STI/STD Testing</Typography>
				<Typography className={classes.subHeading}>
					Please Review the Following Information
				</Typography>
				<Typography className={classes.text}>
					Leda Health offers at-home STI/STD testing in partnership with{' '}
					<a
						href="https://www.mylabbox.com/product/at-home-comprehensive-drug-test/"
						rel="noreferrer"
						style={{ textDecoration: 'underline', color: '#F48273' }}
						target="_blank">
						MyLAB Box
					</a>
					{'. '}
					This testing helps identify if you have an STI or STD.
				</Typography>
				<Typography className={classes.textHeading}>Why take an STI test?</Typography>
				<Typography className={classes.text}>
					If you get an STI or STD and you’re symptomatic, you’ll likely notice the uncomfortable
					symptoms, however, it’s common for people to have no symptoms at all. These at-home
					STI/STD tests provide lab-verified results of any potential exposure; it’s important to
					get tested every time you have a new partner or post-sexual assault.
				</Typography>
				<Typography>
					If you have questions about if you should take a STI/STD test, the Leda Care Team is
					available 24/7 to support you and walk you through your options.
				</Typography>
				<Typography className={classes.textHeading}>Included in the box</Typography>
				<Typography className={classes.text}>
					Urinalysis, blood testing, and genital swab testing to detect STI/STD/HIV in multiple
					areas of the body and a pre-addressed return envelope (Postage Paid).
				</Typography>
				<Typography className={classes.textHeading}>Time frame For Testing</Typography>
				<Typography className={classes.text}>
					A large number of STIs and STDs have an incubation period of 2 weeks, therefore we
					recommend you wait 2 weeks post-exposure to test. If you are experiencing symptoms, we
					recommend you test as soon as possible in order to receive any necessary treatment quickly
					and avoid further transmission.
				</Typography>

				<Typography className={classes.textHeading}>Results</Typography>
				<Typography className={classes.text}>
					Results are delivered virtually within 3-5 business days via the MyLAB Box portal.
				</Typography>
				<Typography>
					Additionally, telehealth clinician visits are offered for immediate help and long-term
					clinical care post-testing.
				</Typography>
				<div className={classes.buttonWrapper}>
					<Button className={classes.submitButton} onClick={handleClick}>
						Proceed
					</Button>
					<Button
						className={classes.chatButton}
						onClick={getOnClick(
							handleCareTeamClick,
							serviceDisabled,
							dispatch,
							careTeamServiceData,
							serviceDisableInDemo
						)}>
						Speak to the Care Team
					</Button>
				</div>
				<KitDialog
					open={openDialog}
					service={SERVICE_LIST.STI_TESTING}
					setOpen={setOpenDialog}
					title="How would you like to receive your STI Test?"
				/>
			</div>
		</UserLayout>
	);
}

export default STIReviewPage;
