const ORDER_STATUS = [
	{
		status: 'order placed',
		label: 'Order Placed',
		color: '#D7B04C',
	},
	{
		status: 'ready for dispatch',
		label: 'Ready for Dispatch',
		color: '#D7B04C',
	},
	{
		status: 'picked up',
		label: 'Picked Up',
		color: '#81B1FA',
	},
	{
		status: 'out for delivery',
		label: 'Out for Delivery',
		color: '#BD5FC2',
	},
	{
		status: 'en route to delivery',
		label: 'En Route to Delivery',
		color: '#BD5FC2',
	},
	{
		status: 'delivered',
		label: 'Delivered',
		color: '#8EB045',
	},
	{
		status: 'canceled by onerail',
		label: 'Cancelled by OneRail',
		color: '#EA592A',
	},
	{
		status: 'canceled by shipper',
		label: 'Cancelled by Shipper',
		color: '#EA592A',
	},
	{
		status: 'in lab',
		label: 'In Lab',
		color: '#8EB045',
	},
	{
		status: 'activated',
		label: 'Activated',
		color: '#8EB045',
	},
];

const getStatus = (deliveryStatus = '') => {
	const newStr = deliveryStatus.toLowerCase().replace(/_/g, ' ');

	const status = ORDER_STATUS.filter((item) => item.status === newStr)[0];

	if (status) {
		return {
			label: status?.label,
			color: status?.color,
		};
	}
	return {
		label: deliveryStatus,
		color: 'blue',
	};
};

export default getStatus;
