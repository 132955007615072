import { makeStyles } from '@material-ui/core';

const styles = makeStyles(() => ({
	errorDiv: {
		paddingLeft: 8,
		marginTop: 8,
		color: '#DF1714',
	},
	errorText: {
		color: '#DF1714',
	},
	passwordBox: {
		display: 'block',
		float: 'right',
		cursor: 'pointer',
	},
}));

export default styles;
