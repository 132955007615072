import { makeStyles } from '@material-ui/core';

import GradientBlue from '../../assets/Gradient_Blue.svg';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		color: theme.palette.primary.main,
		backgroundColor: '#FAFAFA',
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
		backgroundImage: `url(${GradientBlue})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	},
	main: {
		width: '100%',
		color: theme.palette.primary.main,
		padding: theme.spacing(0, 12.5, 5),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2.5, 7),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(4, 4),
		},
	},
	topBar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: theme.spacing(2.5),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(0, -4),
			flexDirection: 'column',
			alignItems: 'start',
			borderBottom: '1px solid #C1C1C1',
		},
	},
	button: {
		textTransform: 'none',
		fontSize: 16,
		fontWeight: 500,
		marginBottom: 15,
		color: theme.palette.primary.main,
		'&:hover': {
			background: '#FAFAFA',
		},
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(4),
		},
	},
	container: {
		maxWidth: 1262,
		textAlign: 'center',
		margin: theme.spacing(0, 'auto'),
		[theme.breakpoints.down('sm')]: {
			maxHeight: 'unset',
			margin: theme.spacing(4, 'auto'),
		},
	},
	title: {
		fontSize: 30,
		lineHeight: '37px',
	},
	subTitle: {
		fontSize: 16,
		lineHeight: '20px',
		margin: theme.spacing(3.5, 0),
	},
	listBox: {
		background: '#FFFFFF',
		boxShadow: '0px 20px 44px rgba(208, 208, 208, 0.25)',
		borderRadius: 15,
		padding: theme.spacing(5.5),
		paddingBottom: 0,
		marginBottom: theme.spacing(5),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(4, 3),
		},
	},
	qrBox: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	listItemIcon: {
		display: 'flex',
		color: theme.palette.primary.main,
		textAlign: 'center',
		fontWeight: '500',
		fontSize: 20,
		lineHeight: '24px',
		width: 66,
		height: 66,
		background: 'rgba(30, 76, 78, 0.19)',
		borderRadius: 15,
		alignItems: 'center',
		justifyContent: 'center',
	},
	listText: {
		fontSize: 20,
		lineHeight: '24px',
		margin: theme.spacing(1, 14, 0, 7),
		'&:first-child': {
			marginBottom: theme.spacing(5),
		},
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(2.5, 0),
		},
	},
	firstElement: {
		borderBottom: '1px solid #B0BDBA',
		padding: theme.spacing(1, 14, 5, 0),
		alignItems: 'flex-start',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			paddingRight: 0,
		},
	},
	listContent: {
		borderBottom: '1px solid #B0BDBA',
	},
	listItem: {
		borderBottom: '1px solid #B0BDBA',
		padding: theme.spacing(8, 0),
		maxHeight: 270,
		alignItems: 'center',
		'&:last-child': {
			border: 0,
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			maxHeight: 'unset',
			alignItems: 'flex-start',
			padding: theme.spacing(4, 0),
		},
	},
	sendEmailtext: {
		fontSize: 20,
		lineHeight: '24px',
		textDecoration: 'underline',
		fontWeight: 600,
		padding: theme.spacing(3, 0, 0),
		cursor: 'pointer',
		color: theme.palette.primary.main,
		width: 'fit-content',
	},
	linkText: {
		textDecoration: 'underline',
		textTransform: 'none',
		fontWeight: 600,
		cursor: 'pointer',
	},
	qrCodeBox: {
		marginLeft: 24,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minWidth: 330,
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0,
			minWidth: 227,
			width: 'inherit',
		},
	},
	errorBox: {
		display: 'flex',
		alignItems: 'center',
	},
	marginRight: {
		marginRight: 8,
	},
	marginLeft: {
		marginLeft: 24,
	},
	img: {
		width: 'inherit',
	},
	loaderBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh',
		width: '100%',
	},
}));

export default useStyles;
