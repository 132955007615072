import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
	footer: {
		[theme.breakpoints.down('md')]: {
			bottom: 0,
			padding: theme.spacing(5),
			color: theme.palette.default.main,
			backgroundColor: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			bottom: 0,
			padding: theme.spacing(5),
			color: theme.palette.default.main,
			backgroundColor: theme.palette.primary.main,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			minWidth: '100%',
			minHeight: '10vh',
			maxHeight: '10vh',
			flexDirection: 'column',
		},
	},
}));

export default useStyles;
