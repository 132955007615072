import { Box, Typography } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import disclaimerIcon from '../assets/disclaimer.png';
import forSurvivorsBySurvivors from '../assets/for-survivors-by-survivors.png';
import ledaHealthWhite from '../assets/leda-health-white.png';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import UserAgreement from '../Pages/UserAgreement';
import { useStore } from '../store/index';

import styles from './Styles/Footer.Style';

function Footer(props) {
	const classes = styles();
	const [store] = useStore();
	const { isMobile, userWelcomeContent } = store;
	const { disclaimer, customClass } = props;

	const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
	const [openUserAgreement, setOpenUserAgreement] = useState(false);

	return disclaimer ? (
		<Box className={classes.footer}>
			<Box
				className={classes.disclaimerBox}
				style={{ backgroundColor: `#${userWelcomeContent?.secondaryColor}` }}>
				<Box className={classes.supportBox}>
					<Typography className={classes.supportText}>
						{
							"If you have any ideas, feedback, service inquiries, or would like to discuss additional partnership possibilities, we're all ears! Please feel free to contact us at "
						}
						<a className={classes.link} href="mailto:info@leda.co" rel="noreferrer" target="_blank">
							info@leda.co
						</a>
					</Typography>
				</Box>
				<Typography className={classes.disclaimerHeading}>
					<img
						style={{ width: '20px', padding: '1px', alignItems: 'center' }}
						src={disclaimerIcon}
						alt="disclaimer"
					/>{' '}
					Disclaimer
				</Typography>
				<Typography className={classes.disclaimerText}>
					The information and materials provided on this portal are designed to foster understanding
					and dialogue on topics related to sexual health, sexual violence, and misconduct. This
					content is intended for educational purposes only and is not a substitute for professional
					medical, legal, or psychological advice, diagnosis, treatment, or counseling. Individuals
					are strongly encouraged to consult with a licensed healthcare provider, attorney, or
					mental health professional for advice specific to their situation. Use of this portal does
					not establish a patient-client relationship with Leda Health or any associated
					professionals.
				</Typography>
				<Typography className={classes.disclaimerText}>
					If you are in a crisis, you or any other person may be in danger, or believe you are
					experiencing a medical emergency, please do not rely on this portal or delay seeking
					urgent care. Contact emergency services immediately by calling 911, going to your closest
					hospital, or reaching{' '}
					<strong>
						<a
							className={classes.link}
							href="https://www.rainn.org/"
							rel="noreferrer"
							target="_blank">
							RAINN&apos;s 24/7 crisis hotline
						</a>
					</strong>
					:
				</Typography>
				<ul>
					<li className={classes.disclaimerText}>
						From the U.S. & Canada: <strong>1.888.407.4747</strong>
					</li>
					<li className={classes.disclaimerText}>
						From Overseas: <strong>+1 202.501.4444</strong>
					</li>
				</ul>
				<Typography className={classes.disclaimerText}>
					Leda Health is an educational resource provider and does not offer medical or legal
					services. We strongly encourage survivors of sexual violence to report incidents to law
					enforcement and to seek immediate medical care at the nearest emergency department or
					healthcare facility.
				</Typography>

				<Box
					style={{
						backgroundColor: `#${userWelcomeContent?.secondaryColor}`,
						borderTop: '1px solid #82A7CD',
						padding: '10px',
						display: 'flex',
						justifyContent: 'left',
						alignItems: 'left',
						marginTop: '15px',
						alignContent: 'center',
					}}>
					<div>
						<img src={userWelcomeContent?.logo} className={classes.schoolLogo} alt="disclaimer" />
					</div>
					<div>
						<Typography className={classes.partnershipText}>In Partnership With</Typography>
					</div>
					<div>
						<Box className={classes.ledaLogoBox}>
							<img src={ledaHealthWhite} className={classes.ledaLogo} alt="Leda Logo" />
							<img
								src={forSurvivorsBySurvivors}
								className={classes.forSurvivorsBySurvivors}
								alt="For Survivors by Survivors"
							/>
						</Box>
					</div>
					<div className={classes.copyright}>
						<Typography
							style={{
								align: 'right',
								fontWeight: 'lighter',
								color: '#82A7CD',
								fontSize: '14px',
							}}>
							&#169; {`${new Date().getFullYear()}`}
						</Typography>
					</div>
				</Box>
			</Box>
		</Box>
	) : (
		<div className={customClass || classes.footerDiv}>
			<Typography className={classes.footerText}>
				ⓒ 2019-{new Date().getFullYear()} Leda Health Corporation. All rights reserved.
			</Typography>
			<div className={classes.footerLinks}>
				{isMobile ? '  ' : <FiberManualRecordIcon classes={{ root: classes.icon }} />}
				<Typography className={classes.footerTextLink} onClick={() => setOpenUserAgreement(true)}>
					User Agreement
				</Typography>
				{isMobile ? '  ' : <FiberManualRecordIcon classes={{ root: classes.icon }} />}
				<Typography className={classes.footerTextLink} onClick={() => setOpenPrivacyPolicy(true)}>
					Privacy Policy
				</Typography>
				{isMobile ? '  ' : <FiberManualRecordIcon classes={{ root: classes.icon }} />}
				<Typography className={classes.footerText}>
					<a
						className={classes.link}
						href="mailto:support@leda.co"
						rel="noreferrer"
						target="_blank">
						support@leda.co
					</a>
				</Typography>
			</div>
			<PrivacyPolicy
				openPrivacyPolicy={openPrivacyPolicy}
				setOpenPrivacyPolicy={setOpenPrivacyPolicy}
			/>
			<UserAgreement
				openUserAgreement={openUserAgreement}
				setOpenUserAgreement={setOpenUserAgreement}
			/>
		</div>
	);
}

Footer.propTypes = {
	disclaimer: PropTypes.bool,
	customClass: PropTypes.string,
};

Footer.defaultProps = {
	disclaimer: false,
	customClass: '',
};
export default Footer;
