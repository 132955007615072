const ACTION_TYPE = {
	SET_USER: 'ACTION_SET_USER',
	SET_COGNITO_USER: 'ACTION_SET_COGNITO_USER',
	SET_ORG_ID: 'ACTION_SET_ORG_ID',
	SET_IS_MOBILE_VIEW: 'ACTION_SET_IS_MOBILE_VIEW',
	SET_USER_LOGGED_IN: 'ACTION_SET_USER_LOGGED_IN',
	LOGOUT: 'ACTION_LOGOUT',
	SET_SCHOOL_EMAIL_VERIFIED: 'ACTION_SET_SCHOOL_EMAIL_VERIFIED',
	SET_KIT_DETAILS_FROM_LAB: 'ACTION_SET_KIT_DETAILS_FROM_LAB',
	SET_STI_TESTING_STATUS: 'ACTION_SET_STI_TESTING_STATUS',
	SET_STI_RESULTS: 'ACTION_SET_STI_RESULTS',
	SET_KIT_ORDERED: 'ACTION_SET_KIT_ORDERED',
	SET_KIT_ACTIVATED: 'ACTION_SET_KIT_ACTIVATED',
	SET_KIT_ORDER_DETAILS: 'ACTION_SET_KIT_ORDER_DETAILS',
	SET_OTP_SESSION_TOKEN: 'ACTION_SET_OTP_SESSION_TOKEN',
	SET_USER_PROFILE_NAME: 'ACTION_SET_USER_PROFILE_NAME',
	SET_USER_LOCK_LOCATION: 'ACTION_SET_USER_LOCK_LOCATION',
	SET_SHOW_LEARN_MORE_CARD: 'ACTION_SET_SHOW_LEARN_MORE_CARD',
	SET_USER_SERVICE_LIST: 'ACTION_SET_USER_SERVICE_LIST',
	SET_USER_RESOURCE_LIST: 'ACTION_SET_USER_RESOURCE_LIST',
	SET_USER_WELCOME_CONTENT: 'ACTION_SET_USER_WELCOME_CONTENT',
	SET_USER_EDUCATION: 'ACTION_SET_USER_EDUCATION',
	SET_ELIGIBILITY_QUIZ_RESPONSE: 'ACTION_SET_ELIGIBILITY_QUIZ_RESPONSE',
	SET_PLAN_B_ORDER: 'ACTION_SET_PLAN_B_ORDERED',
	SET_PLANB_ORDER_DETAILS: 'ACTION_SET_PLANB_ORDER_DETAILS',
	SET_CONTACT_SUPPORT: 'ACTION_SET_CONTACT_SUPPORT',
	SET_CARE_TEAM_SUPPORT: 'ACTION_SET_CARE_TEAM_SUPPORT',
	SET_TECH_SUPPORT: 'ACTION_TECH_SUPPORT',
	OPEN_DISABLE_DIALOG: 'OPEN_DISABLE_DIALOG',
	CLOSE_DISABLE_DIALOG: 'CLOSE_DISABLE_DIALOG',
	SET_SERVICE_DISABLED: 'SET_SERVICE_DISABLED',
	SET_DISABLE_DIALOG_MSG: 'ACTION_SET_DISABLE_DIALOG_MSG',
	SET_TOXICOLOGY_ORDERED: 'ACTION_SET_TOXICOLOGY_ORDERED',
	SET_TOXICOLOGY_ORDER_DETAILS: 'ACTION_SET_TOXICOLOGY_ORDER_DETAILS',
	SET_STI_ORDERED: 'ACTION_SET_STI_ORDERED',
	SET_STI_ORDER_DETAILS: 'ACTION_SET_STI_ORDER_DETAILS',
};

export default ACTION_TYPE;
