import {
	Button,
	Dialog,
	InputLabel,
	TextField,
	DialogTitle,
	DialogActions,
	DialogContent,
	CircularProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ModalCloseIcon from '../assets/ModalCloseIcon.svg';
import { post } from '../Services';
import API from '../utils/constants/apiEndPoints';
import log from '../utils/log';

import useStyles from './Styles/SendQRCode.Style';

function SendQRCode(props) {
	const classes = useStyles();
	const { openSendDialog, setOpenSendDialog, qrCode } = props;
	const [email, setEmail] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [disableBtn, setDisableBtn] = useState(false);

	const handleSendCode = async () => {
		log('[SendQRCode][HandleSendCode] email', email);
		if (email !== '') {
			try {
				const myInit = {
					body: {
						email,
						qrcode: qrCode,
					},
				};
				const confirmRes = await post(API.SEND_QR_CODE, false, myInit);
				log('[SendQRCode][HandleSendCode] confirmRes', confirmRes);
				setErrorMessage(confirmRes.message);
			} catch (err) {
				log('[SendQRCode][HandleSendCode] err', err);
				setErrorMessage(err.message);
			}
		} else {
			setErrorMessage('Email cannot be empty');
		}
		setDisableBtn(false);
	};

	return (
		<Dialog
			classes={{ root: classes.div, paper: classes.dialog }}
			onClose={() => setOpenSendDialog(false)}
			open={openSendDialog}>
			<img
				aria-hidden
				alt="An icon to signify feature to close the modal."
				className={classes.closeIcon}
				onClick={() => setOpenSendDialog(false)}
				src={ModalCloseIcon}
			/>
			<DialogTitle classes={{ root: classes.title }} disableTypography>
				Send the QR Code to your email
			</DialogTitle>
			<DialogContent classes={{ root: classes.dialogContent }}>
				<InputLabel className={classes.inputText} htmlFor="name">
					<b>Your Email Address</b>
				</InputLabel>
				<TextField
					className={classes.input}
					defaultValue=""
					fullWidth
					margin="normal"
					onChange={(e) => {
						setErrorMessage('');
						setDisableBtn(false);
						setEmail(e.target.value);
					}}
					required
					variant="outlined"
				/>
				<div className={classes.errorText}>{errorMessage}</div>
			</DialogContent>
			<DialogActions className={classes.dialogAction}>
				<Button
					className={classes.button}
					disabled={disableBtn}
					onClick={(e) => {
						handleSendCode(e);
						setOpenSendDialog(false);
					}}>
					{disableBtn ? <CircularProgress size={24} /> : 'Send Code'}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

SendQRCode.propTypes = {
	openSendDialog: PropTypes.bool,
	setOpenSendDialog: PropTypes.func,
	qrCode: PropTypes.string,
};

export default SendQRCode;
