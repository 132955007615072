import { rest } from 'msw';

import API from '../../utils/constants/apiEndPoints';
import OrderEEKResponse from '../responses/orderEEKResponse.json';

const OrderEEK = [
	rest.post(`*${API.ORDER_EEK}`, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(OrderEEKResponse));
	}),
];

export default OrderEEK;
