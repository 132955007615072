import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	icon: {
		fontSize: '0.73em',
		color: theme.palette.primary.main,
		marginTop: theme.spacing(3),
	},
	heading: {
		color: '#1E4C4E',
		fontSize: '30px',
		fontWeight: 700,
		textAlign: 'center',
	},
	subText: {
		color: theme.palette.primary.main,
		fontSize: '16px',
		fontWeight: 400,
	},
	errorText: {
		color: 'red',
		fontSize: '16px',
		fontWeight: 400,
	},
	dialogBox: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		padding: theme.spacing(2),
		borderRadius: theme.spacing(2),
	},
	button: {
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5),
		background: '#BEE2E2 !important',
		borderRadius: '9px !important',
		color: `${theme.palette.primary.main} !important`,
		padding: '10px 34px !important',
		textTransform: 'none !important',
		width: 300,
		'&:hover': {
			background: '#BEE2E2',
			color: theme.palette.primary.main,
		},
	},
	contactUsText: {
		fontWeight: 400,
		fontSize: '16px',
		textDecorationLine: 'underline',
		cursor: 'pointer',
	},
}));

export default styles;
