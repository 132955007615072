/* eslint-disable no-prototype-builtins */
import { rest } from 'msw';

import clientIdResponse from '../responses/clientIDResponse.json';
import IdentityIdResponse from '../responses/IdentityIDResponse.json';
import passwordVerifierAccessTokenResponse from '../responses/passwordVerifierAccessToken.json';
import passwordVerifierResponse from '../responses/passwordVerifierResponse.json';
import userLoggedInAccessToken from '../responses/userLogedInAccessToken.json';
import userNotFoundResponse from '../responses/userNotFoundResponse.json';

const AuthHandler = [
	rest.post('https://cognito-idp.us-east-1.amazonaws.com/', async (req, res, ctx) => {
		const requestBody = await req.json();
		if (requestBody?.AuthFlow === 'USER_SRP_AUTH') {
			if (
				requestBody?.AuthParameters?.USERNAME === 'demo@leda.co' ||
				window.location.pathname === '/enter-email' ||
				window.location.pathname === '/enter-verification-code'
			) {
				return res(ctx.status(200), ctx.json(passwordVerifierResponse));
			}
			// user not found
			return res(ctx.status(400), ctx.json(userNotFoundResponse));
		}
		if (requestBody?.ChallengeName === 'PASSWORD_VERIFIER') {
			return res(ctx.status(200), ctx.json(passwordVerifierAccessTokenResponse));
		}
		if (requestBody.hasOwnProperty('PreviousPassword')) {
			localStorage.setItem('resettingPassword', true);
			return res(ctx.status(200), ctx.json({ msg: 'reset password handler' }));
		}
		if (requestBody.hasOwnProperty('AccessToken')) {
			const isUserLoggedIn = sessionStorage.getItem('userLoggedIn');
			const resettingPassword = localStorage.getItem('resettingPassword');

			if (resettingPassword) {
				localStorage.removeItem('resettingPassword');
				return res(ctx.status(200), ctx.json({}));
			}

			if (isUserLoggedIn) {
				return res(ctx.status(200), ctx.json({}));
			}

			return res(ctx.status(200), ctx.json(userLoggedInAccessToken));
		}
		if (requestBody.hasOwnProperty('ClientId')) {
			return res(ctx.status(), ctx.json(clientIdResponse));
		}
		// email verification
		if (requestBody.hasOwnProperty('ConfirmationCode')) {
			return res({ msg: 'email verification mock api' });
		}
		return res(ctx.status(200), ctx.json({ msg: 'this is aws api' }));
	}),

	rest.post('https://cognito-identity.us-east-1.amazonaws.com/', async (req, res, ctx) => {
		const requestBody = await req.json();
		if (requestBody.hasOwnProperty('IdentityPoolId')) {
			return res(
				ctx.json(200),
				ctx.json({
					IdentityId: 'Identity-id',
				})
			);
		}

		if (requestBody.hasOwnProperty('IdentityId')) {
			return res(ctx.status(200), ctx.json(IdentityIdResponse));
		}
		return res(ctx.status(200), ctx.json({ msg: 'this is aws api' }));
	}),
];

export default AuthHandler;
