import {
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from '@material-ui/core';
import React from 'react';

import ModalCloseIcon from '../assets/ModalCloseIcon.svg';
import { useStore } from '../store';
import ACTION_TYPE from '../utils/constants/actionType';

import styles from './Styles/ServiceDisableDialog.Style';

function ServiceDisableDialog() {
	const classes = styles();
	const [store, dispatch] = useStore();
	const { openDisableDialog, disableDialogMsg } = store;

	const handleCLose = () => {
		dispatch({
			type: ACTION_TYPE.CLOSE_DISABLE_DIALOG,
		});
	};

	return (
		<Dialog
			classes={{ paper: classes.dialog }}
			onClose={() => handleCLose()}
			open={openDisableDialog}
			scroll="body">
			<DialogTitle className={classes.dialogTitle}>
				<Typography className={classes.title}>{disableDialogMsg?.title}</Typography>
				<img
					aria-hidden
					alt="An icon to separate the different information about STI/STD testing timeline "
					className={classes.image}
					onClick={() => handleCLose()}
					src={ModalCloseIcon}
				/>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<DialogContentText className={classes.content}>
					<Typography>{disableDialogMsg?.body}</Typography>
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
}

export default ServiceDisableDialog;
