import ACTION_TYPE from '../utils/constants/actionType';

const reducers = (state, action) => {
	switch (action.type) {
		case ACTION_TYPE.SET_USER:
			return {
				...state,
				user: action.user,
			};
		case ACTION_TYPE.SET_COGNITO_USER:
			return {
				...state,
				cognitoUser: action.cognitoUser,
			};
		case ACTION_TYPE.SET_ORG_ID:
			return {
				...state,
				orgId: action.orgId,
			};
		case ACTION_TYPE.SET_IS_MOBILE_VIEW:
			return {
				...state,
				isMobile: action.isMobile,
			};
		case ACTION_TYPE.SET_USER_LOGGED_IN:
			return {
				...state,
				isLoggedIn: true,
			};
		case ACTION_TYPE.SET_SCHOOL_EMAIL_VERIFIED:
			return {
				...state,
				isSchoolEmailVerified: true,
			};
		case ACTION_TYPE.LOGOUT:
			return {
				...state,
				isLoggedIn: false,
			};
		case ACTION_TYPE.SET_KIT_DETAILS_FROM_LAB:
			return {
				...state,
				kitResults: action.kitResults,
			};
		case ACTION_TYPE.SET_STI_TESTING_STATUS:
			return {
				...state,
				stiTestingStatus: action.stiTestingStatus,
			};
		case ACTION_TYPE.SET_STI_RESULTS:
			return {
				...state,
				stiResults: action.stiResults,
			};
		case ACTION_TYPE.SET_KIT_ORDERED:
			return {
				...state,
				kitOrdered: action.kitOrdered,
			};
		case ACTION_TYPE.SET_KIT_ACTIVATED:
			return {
				...state,
				kitActivated: action.kitActivated,
			};
		case ACTION_TYPE.SET_KIT_ORDER_DETAILS:
			return {
				...state,
				kitOrderDetails: action.kitOrderDetails,
			};
		case ACTION_TYPE.SET_OTP_SESSION_TOKEN:
			return {
				...state,
				sessionToken: action.sessionToken,
			};
		case ACTION_TYPE.SET_USER_PROFILE_NAME:
			return {
				...state,
				userProfileName: action.userProfileName,
			};
		case ACTION_TYPE.SET_USER_LOCK_LOCATION:
			return {
				...state,
				userLockLocation: action.userLockLocation,
			};
		case ACTION_TYPE.SET_SHOW_LEARN_MORE_CARD:
			return {
				...state,
				showLearnMoreCard: action.showLearnMoreCard,
			};
		case ACTION_TYPE.SET_USER_SERVICE_LIST:
			return {
				...state,
				userServiceList: action.userServiceList,
			};
		case ACTION_TYPE.SET_USER_RESOURCE_LIST:
			return {
				...state,
				userResources: action.userResources,
			};
		case ACTION_TYPE.SET_USER_WELCOME_CONTENT:
			return {
				...state,
				userWelcomeContent: action.userWelcomeContent,
			};
		case ACTION_TYPE.SET_USER_EDUCATION:
			return {
				...state,
				userEducation: action.userEducation,
			};
		case ACTION_TYPE.SET_ELIGIBILITY_QUIZ_RESPONSE:
			return {
				...state,
				eligibilityQuiz: action.eligibilityQuiz,
			};
		case ACTION_TYPE.SET_PLAN_B_ORDER:
			return {
				...state,
				planBOrdered: action.planBOrdered,
			};
		case ACTION_TYPE.SET_PLANB_ORDER_DETAILS:
			return {
				...state,
				planBOrderDetails: action.planBOrderDetails,
			};
		case ACTION_TYPE.SET_CONTACT_SUPPORT:
			return {
				...state,
				openContactSupport: action.openContactSupport,
			};
		case ACTION_TYPE.SET_CARE_TEAM_SUPPORT:
			return {
				...state,
				openCareTeamSupport: action.openCareTeamSupport,
			};
		case ACTION_TYPE.SET_TECH_SUPPORT:
			return {
				...state,
				openTechSupport: action.openTechSupport,
			};
		case ACTION_TYPE.OPEN_DISABLE_DIALOG:
			return {
				...state,
				openDisableDialog: true,
			};
		case ACTION_TYPE.CLOSE_DISABLE_DIALOG:
			return {
				...state,
				openDisableDialog: false,
			};
		case ACTION_TYPE.SET_DISABLE_DIALOG_MSG:
			return {
				...state,
				disableDialogMsg: action.disableDialogMsg,
			};
		case ACTION_TYPE.SET_SERVICE_DISABLED:
			return {
				...state,
				serviceDisabled: action.serviceDisabled,
			};
		case ACTION_TYPE.SET_TOXICOLOGY_ORDERED:
			return {
				...state,
				toxicologyOrdered: action.toxicologyOrdered,
			};
		case ACTION_TYPE.SET_TOXICOLOGY_ORDER_DETAILS:
			return {
				...state,
				toxicologyOrderDetails: action.toxicologyOrderDetails,
			};
		case ACTION_TYPE.SET_STI_ORDERED:
			return {
				...state,
				stiOrdered: action.stiOrdered,
			};
		case ACTION_TYPE.SET_STI_ORDER_DETAILS:
			return {
				...state,
				stiOrderDetails: action.stiOrderDetails,
			};
		default:
			throw new Error();
	}
};

export default reducers;
