import log from '../../utils/log';

import IntercomAPI from './api';

export const bootIntercom = async (metaData) => {
	if (!window.Intercom) {
		log('bootIntercom', 'Intercom instance is not initialized');
		return;
	}
	window.intercomSettings = metaData;
	await IntercomAPI('onShow', () => {
		window.intercomSettings.isOpen = true;
	});
	await IntercomAPI('onHide', () => {
		window.intercomSettings.isOpen = false;
	});
	await IntercomAPI('boot', metaData);
};

export const shutdownIntercom = async () => {
	await IntercomAPI('shutdown');
	delete window.Intercom;
	delete window.intercomSettings;
};

export const hideIntercom = () => {
	if (!window.Intercom) {
		log('hideIntercom', 'Intercom instance is not initialized');
		return;
	}
	IntercomAPI('hide');
};

export const showIntercom = () => {
	if (!window.Intercom) {
		log('showIntercom', 'Intercom instance is not initialized');
		return;
	}
	IntercomAPI('show');
};
