import ACTION_TYPE from '../utils/constants/actionType';
import API from '../utils/constants/apiEndPoints';
import log from '../utils/log';

import { get } from '.';

const fetchKitStatusFromLab = async (dispatch, kitId, userId) => {
	log('[fetchKitStatusFromLab] inside kit', userId);
	let resBody;
	try {
		if (kitId) {
			const path = `${API.FETCH_EEK_RESULTS}?kitId=${kitId}&userId=${userId}`;
			const labResponse = await get(path, false);
			log('[fetchKitStatusFromLab] response from lab portal', labResponse);
			resBody = JSON.parse(labResponse.body);
			log('[fetchKitStatusFromLab] resBody', resBody);
			dispatch({
				type: ACTION_TYPE.SET_KIT_DETAILS_FROM_LAB,
				kitResults: resBody,
			});
		}
	} catch (error) {
		log('[Home][fetchKitStatusFromLab] error fetching kit from lab', error);
	}
	return resBody;
};

export default fetchKitStatusFromLab;
