import {
	Box,
	Button,
	CircularProgress,
	InputLabel,
	Select,
	TextField,
	Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import activeIcon from '../../assets/activeIcon.svg';
import sendAccessRequest from '../../Services/sendAccessRequest';
import { OPTIONS_ORG_MODAL, ORG_DETAILS_FORM } from '../../utils/constants/orgModal';
import log from '../../utils/log';

import useStyles from './Styles/SignUpAlertDialog.Style';

function OrganizationModal({ isSubmitted, setIsSubmitted }) {
	const classes = useStyles();
	const [errorResponse, setErrorResponse] = useState('');
	const [orgDetails, setOrgDetails] = useState(
		ORG_DETAILS_FORM.reduce((obj, item) => ({ ...obj, [item.name]: '' }), {})
	);
	const [isLoading, setIsLoading] = useState(false);

	const handleChange = (key, value) => {
		setOrgDetails({
			...orgDetails,
			[key]: value,
		});
	};

	const isFilled = (key) => orgDetails[key] !== '';

	const handleSubmit = async (e) => {
		e.preventDefault();
		log('Org Modal', orgDetails);
		try {
			setIsLoading(true);
			const res = await sendAccessRequest(orgDetails);
			log('[Org] details submitted', res);
			setIsSubmitted(true);
			setIsLoading(false);
		} catch (err) {
			setErrorResponse(err?.message);
			setIsLoading(false);
			log('[Org] error', err);
		}
	};

	return (
		<Box>
			{isSubmitted ? (
				<Box className={classes.confirmationBox}>
					<Box className={classes.imgBox}>
						<img alt="" src={activeIcon} />
					</Box>
					<Box className={classes.textBox}>
						<Typography className={classes.title}>
							Your information has been submitted successfully
						</Typography>
						<Typography className={classes.subTitle}>
							We will be in touch with you through your provided email.
						</Typography>
					</Box>
					<Box>
						<Button className={classes.button}>
							<a
								href="https://www.leda.co/blog"
								rel="noreferrer"
								style={{ textDecoration: 'none', color: 'inherit' }}
								target="_blank">
								<Typography>
									<b>Explore Our Blog</b>
								</Typography>
							</a>
						</Button>
					</Box>
				</Box>
			) : (
				<form autoComplete="off" className={classes.form} onSubmit={(e) => handleSubmit(e)}>
					{ORG_DETAILS_FORM.map((item) => (
						<Box key={item?.name}>
							<InputLabel className={classes.label}>{item?.label}</InputLabel>
							{item?.type === 'select' ? (
								<Select
									className={classes.textField}
									name={item?.name}
									native
									onChange={(e) => handleChange(item?.name, e.target.value)}
									placeholder={item?.placeholder}
									required={item?.isRequired}
									value={orgDetails[item?.name]}
									variant="outlined">
									<option value="">&nbsp;</option>
									{OPTIONS_ORG_MODAL.map((orgName) => (
										<option key={orgName} value={orgName}>
											{orgName}
										</option>
									))}
								</Select>
							) : (
								<TextField
									className={isFilled(item?.name) ? classes.inputFilled : classes.textField}
									fullWidth
									name={item?.name}
									onChange={(e) => handleChange(item?.name, e.target.value)}
									placeholder={item?.placeholder}
									required={item?.isRequired}
									type={item?.type || 'text'}
									value={orgDetails[item?.name]}
									variant="outlined"
								/>
							)}
						</Box>
					))}
					<Typography style={{ color: 'red' }}>{errorResponse}</Typography>
					<Button className={classes.button} disabled={isLoading} type="submit">
						{isLoading ? <CircularProgress size={24} /> : <b>SUBMIT</b>}
					</Button>
				</form>
			)}
		</Box>
	);
}

OrganizationModal.propTypes = {
	isSubmitted: PropTypes.bool,
	setIsSubmitted: PropTypes.func,
};

export default OrganizationModal;
