import ACTION_TYPE from '../../../utils/constants/actionType';

// Function to update quiz active step
export const updateQuizState = (obj, key, value) => {
	const newObj = {
		...obj,
		[key]: value,
	};
	return newObj;
};

// Function to update user response
export const updateQuizResponse = (obj, key, value) => {
	const newResponse = (obj[key] && obj[key].length !== 0 && [...obj[key], value]) || [value];
	const newObj = {
		...obj,
		[key]: newResponse,
	};
	return newObj;
};

// Function to add user response to store
export const dispatchQuizState = (dispatch, response) => {
	dispatch({
		type: ACTION_TYPE.SET_ELIGIBILITY_QUIZ_RESPONSE,
		eligibilityQuiz: response,
	});
};
