import { makeStyles } from '@material-ui/core';

import GradientBlue from '../../../assets/Gradient_Blue.svg';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		color: theme.palette.primary.main,
		backgroundColor: '#FAFAFA',
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
		backgroundImage: `url(${GradientBlue})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	},
	main: {
		width: '100%',
		color: theme.palette.primary.main,
		padding: theme.spacing(0, 12.5, 5),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2.5, 7),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(4, 4),
		},
	},
	topBar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginTop: theme.spacing(2.5),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(0, -4),
			flexDirection: 'column',
			alignItems: 'start',
			borderBottom: '1px solid #C1C1C1',
		},
	},
	button: {
		textTransform: 'none',
		fontSize: 16,
		fontWeight: 500,
		marginBottom: 15,
		color: theme.palette.primary.main,
		'&:hover': {
			background: '#FAFAFA',
		},
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(4),
		},
	},
	container: {
		maxWidth: 1262,
		textAlign: 'center',
		margin: theme.spacing(0, 'auto'),
		background: '#FFFFFF',
		boxShadow: '0px 20px 44px rgba(208, 208, 208, 0.25)',
		borderRadius: 15,
		padding: theme.spacing(12, 5.5),
		[theme.breakpoints.down('sm')]: {
			maxHeight: 'unset',
			padding: theme.spacing(5.5, 3.5),
			margin: theme.spacing(6, 'auto'),
		},
	},
	title: {
		fontSize: 30,
		lineHeight: '37px',
	},
	subTitle: {
		fontSize: 16,
		lineHeight: '20px',
		margin: theme.spacing(3.5, 'auto'),
		maxWidth: 176,
	},
	formContainer: {
		width: 576,
		margin: 'auto',
		textAlign: 'initial',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	fieldInput: {
		fontSize: 16,
		lineHeight: '20px',
		letterSpacing: '0.095em',
		textTransform: 'uppercase',
		color: theme.palette.primary.main,
	},
	textFieldOutline: {
		borderRadius: 7,
		border: '1px solid #B0BDBA',
	},
	textField: {
		marginBottom: theme.spacing(3),
		'& .MuiInputBase-root.Mui-disabled': {
			color: '#000000',
			backgroundColor: '#d3d3d3',
		},
	},
	checkIcon: {
		borderRadius: 5,
		padding: 0,
		color: '#B0BDBA',
		marginRight: theme.spacing(1.25),
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
	divider: {
		border: '1px dashed #B0BDBA',
		margin: theme.spacing(5, 0),
	},
	terms: {
		fontSize: 16,
		lineHeight: '20px',
		color: theme.palette.primary.main,
	},
	checkBox: {
		display: 'flex',
	},
	buttonBox: {
		textAlign: 'center',
	},
	submitButton: {
		background: '#BEE2E2',
		textTransform: 'none',
		borderRadius: 9,
		fontSize: 16,
		lineHeight: '20px',
		margin: theme.spacing(5, 0),
		padding: theme.spacing(3),
		color: theme.palette.primary.main,
		'&:hover': {
			background: '#BEE2E2',
		},
	},
	loaderBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh',
		width: '100%',
	},
	planbBox: {
		display: 'flex',
		border: '1px solid #B0BDBA',
		borderRadius: '7px',
		padding: theme.spacing(1.25),
	},
	img: {
		margin: theme.spacing(1.5),
	},
	mainTextBox: {
		margin: theme.spacing(0, 3.75, 0, 3.75),
		color: theme.palette.primary.main,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(0, 0, 0, 3),
		},
	},
	textHeading: {
		fontSize: 20,
		lineHeight: '25px',
	},
	textSubHeading: {
		fontSize: 14,
		lineHeight: '17px',
	},
}));

export default useStyles;
