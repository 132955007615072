import {
	Button,
	Box,
	CircularProgress,
	InputLabel,
	Typography,
	TextField,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import AuthLayout from '../../Components/AuthLayout';
import amplifyFailedAuthSignInResponse from '../../mocks/responses/amplifyFailedAuthSignIn.json';
import amplifySuccessAuthSignInResponse from '../../mocks/responses/amplifySuccessAuthSignIn.json';
import { get } from '../../Services';
import sendNotificationEmail from '../../Services/sendNotificationEmail';
import { useStore } from '../../store/index';
import ACTION_TYPE from '../../utils/constants/actionType';
import API from '../../utils/constants/apiEndPoints';
import { DEMO, DEMO_SIGN_IN_PASSWORD } from '../../utils/constants/demoData';
import ROUTES from '../../utils/constants/routes';
import { getOnClick } from '../../utils/disableServices';
import log from '../../utils/log';
import updateUserDetails from '../../utils/updateUserDetails';
import sharedStyles from '../Styles/SharedStyles';

import OrganizationModal from './OrganizationModal';
import SignUpAlertDialog from './SignUpAlertDialog';
import styles from './Styles/SignIn.Style';

function SignInWithEmail() {
	const classes = { ...styles(), ...sharedStyles() };
	const history = useHistory();
	const [store, dispatch] = useStore();
	const { cognitoUser } = store;
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [signInError, setSignInError] = useState('');
	const [disableBtn, setDisableBtn] = useState(false);
	const [openOrgModal, setOpenOrgModal] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setDisableBtn(true);
		if (email !== '' && password !== '') {
			try {
				let user;
				const username = email.trim();
				if (process.env.REACT_APP_ENV === DEMO) {
					if (DEMO_SIGN_IN_PASSWORD === password) {
						user = amplifySuccessAuthSignInResponse;
					} else {
						throw amplifyFailedAuthSignInResponse;
					}
				} else {
					user = await Auth.signIn(username, password);
				}
				log('[signIn][handleSubmit] user signed in', user.signInUserSession.idToken.jwtToken);
				const path = `${API.FETCH_USER}?userId=${user.username}`;
				const myInit = {
					headers: {
						Authorization: user.signInUserSession.idToken.jwtToken,
					},
				};
				sendNotificationEmail('signIn', false);
				const userBody = await get(path, true, myInit);
				log('[signInWithEmail[fetchUser] userBody', userBody);
				if (userBody) {
					updateUserDetails(userBody, dispatch);
					if (userBody.isSchoolEmailVerified) {
						history.push(ROUTES.HOME);
					} else {
						history.push(ROUTES.ENTER_EMAIL);
					}
				} else {
					log('[signIn][handleSubmit] error signing in userBody', userBody);
					setSignInError(userBody);
				}
			} catch (err) {
				log('[signIn][handleSubmit] error signing in', err);
				if (err?.code === 'UserNotConfirmedException') {
					dispatch({
						type: ACTION_TYPE.SET_COGNITO_USER,
						cognitoUser: {
							...cognitoUser,
							username: email,
						},
					});
					try {
						await Auth.resendSignUp(email);
						log('code resent successfully');
						history.push(ROUTES.ENTER_VERIFICATION_CODE);
					} catch (error) {
						log('error resending code: ', error);
					}
				}
				setSignInError(err.message);
			}
		} else {
			setDisableBtn(false);
		}
	};

	return (
		<AuthLayout>
			<Box className={classes.box2}>
				<form autoComplete="off" className={classes.form} onSubmit={(e) => handleSubmit(e)}>
					<Typography className={classes.title} color="primary">
						Welcome back
					</Typography>
					<br />
					<Typography className={classes.text1} color="primary">
						Please enter your details to sign in to the portal
					</Typography>
					<br />
					<InputLabel className={classes.text2} color="primary" required>
						EMAIL ADDRESS
					</InputLabel>
					<TextField
						classes={{
							root: email === '' ? classes.input : classes.inputFilled,
						}}
						defaultValue=""
						margin="normal"
						onChange={(e) => {
							setSignInError('');
							setDisableBtn(false);
							setEmail(e.target.value);
						}}
						placeholder="Email"
						required
						variant="outlined"
					/>
					<InputLabel className={classes.text2} required>
						PASSWORD
					</InputLabel>
					<TextField
						classes={{
							root: password === '' ? classes.input : classes.inputFilled,
						}}
						defaultValue=""
						margin="normal"
						onChange={(e) => {
							setSignInError('');
							setDisableBtn(false);
							setPassword(e.target.value);
						}}
						placeholder="Password"
						required
						type="password"
						variant="outlined"
					/>
					<br />
					<Box className={classes.errorDiv}>{signInError}</Box>
					<Typography
						className={classes.passwordBox}
						color="primary"
						onClick={getOnClick(() => history.push(ROUTES.FORGOT_PASSWORD))}
						variant="subtitle1">
						<b>
							<u>Forgot Password?</u>
						</b>
					</Typography>
					<Button
						classes={{
							root: classes.submitBtn,
							disabled: classes.disabledBtn,
						}}
						disabled={disableBtn || signInError !== '' || email === '' || password === ''}
						id="signInButton"
						type="submit"
						variant="contained">
						<b>{disableBtn ? <CircularProgress size={24} /> : 'Sign in'}</b>
					</Button>
					<Box className={classes.setupDiv} onClick={getOnClick(() => setOpenOrgModal(true))}>
						<Typography color="primary" variant="subtitle1">
							{'New to Leda?  '}
							<u>
								<b>Request Access</b>
							</u>
						</Typography>
					</Box>
				</form>
				<SignUpAlertDialog
					isSubmitted={isSubmitted}
					open={openOrgModal}
					setOpen={setOpenOrgModal}
					subTitle={`If you're looking for more information or a demo, please fill out the form below and we'll get in touch:`}
					title="Access to the Leda Health Portal is only available for members of partner organizations">
					<OrganizationModal isSubmitted={isSubmitted} setIsSubmitted={setIsSubmitted} />
				</SignUpAlertDialog>
			</Box>
		</AuthLayout>
	);
}
export default SignInWithEmail;
