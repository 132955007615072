import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	dialog: {
		minWidth: '30%',
		maxHeight: '90%',
		overflowY: 'auto',
		borderRadius: 15,
		alignItems: 'center',
		width: '70%',
		maxWidth: 1103,
		[theme.breakpoints.down('sm')]: {
			width: '90%',
		},
	},
	heading: {
		background: '#DBF5F5',
		width: '100%',
		padding: theme.spacing(6.5, 0, 1.55, 0),
		textAlign: 'center',
	},
	title: {
		fontSize: 25,
		lineHeight: '37px',
		margin: theme.spacing(0, 'auto'),
		maxWidth: 610,
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			maxWidth: '90%',
			fontSize: 15,
			lineHeight: '24px',
		},
	},
	subTitle: {
		fontSize: 16,
		lineHeight: '24px',
		padding: theme.spacing(3, 4),
		margin: theme.spacing(0, 'auto'),
		maxWidth: 610,
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			maxWidth: '90%',
			fontSize: 12,
			padding: theme.spacing(3, 0),
			lineHeight: '20px',
		},
	},
	divider: {
		height: 0,
		width: '80%',
	},
	contentBox: {
		width: '80%',
		maxWidth: 792,
		overflow: 'hidden',
		height: 'fit-content',
		margin: theme.spacing(0, 'auto'),
		padding: theme.spacing(3, 0),
		fontSize: 16,
		lineHeight: '20px',
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: theme.spacing(4, 2.5),
		},
	},
	list: {
		fontSize: 16,
		lineHeight: '20px',
		color: theme.palette.primary.main,
		listStyle: 'outside',
		margin: theme.spacing(0, 5),
		padding: 0,
		textAlign: 'initial',
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(0, 2.5),
		},
	},
	listItem: {
		padding: theme.spacing(1.25, 0),
		display: 'list-item',
	},
	link: {
		padding: theme.spacing(2.5, 0),
	},
	banner: {
		textAlign: 'center',
		background: 'rgba(30, 76, 78, 0.04)',
		// border: "1px solid #B0BDBA",
		borderRadius: 15,
		padding: theme.spacing(1, 2),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(0, -1.25),
		},
	},
	image: {
		position: 'absolute',
		top: theme.spacing(6.25),
		right: theme.spacing(6.25),
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			top: theme.spacing(5),
			right: theme.spacing(4),
			width: theme.spacing(1.5),
		},
	},
	textField: {
		marginBottom: theme.spacing(3),
		borderRadius: 7,
		width: '100%',
		color: theme.palette.primary.main,
		// border: '1px solid #B0BDBA',
	},
	inputFilled: {
		width: '100%',
		color: theme.palette.primary.main,
		backgroundColor: '#ECF8F0',
		borderRadius: 7,
		marginBottom: theme.spacing(3),
		border: '1px solid #B0BDBA',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			color: theme.palette.primary.main,
		},
	},
	form: {
		margin: 'auto',
		width: 576,
		[theme.breakpoints.down('sm')]: {
			maxWidth: 576,
			width: '100%',
		},
	},
	label: {
		fontSize: 16,
		lineHeight: '20px',
		letterSpacing: '0.095em',
		textTransform: 'uppercase',
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			letterSpacing: 0,
		},
	},
	input: {
		width: '100%',
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			color: theme.palette.primary.main,
		},
	},
	confirmationBox: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		height: 'inherit',
		minHeight: 460,
	},
	imgBox: {
		background: '#C8E8D1',
		borderRadius: '50%',
		width: 'fit-content',
	},
	textBox: {
		padding: theme.spacing(3.5, 2),
		textAlign: 'center',
		maxWidth: 490,
	},
	button: {
		background: '#BEE2E2',
		borderRadius: 9,
		fontSize: 16,
		height: 52,
		textDecoration: 'bold',
		lineHeight: '20px',
		textTransform: 'none',
		width: '100%',
		padding: theme.spacing(3.75),
		color: theme.palette.primary.main,
		'&:hover': {
			background: '#BEE2E2',
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2.5),
		},
	},
}));

export default useStyles;
