import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import React from 'react';

import ErrorImage from '../assets/errorDialog/error.svg';
import { showIntercom } from '../Services/intercom/method';

import styles from './Styles/ErrorDialog.Style';

function ErrorDialog({ openAlertDialog, setOpenAlertDialog }) {
	const classes = styles();

	const handleClose = () => {
		setOpenAlertDialog(false);
	};

	const handleContactUs = async () => {
		setOpenAlertDialog(false);
		await showIntercom();
	};

	return (
		<div>
			<Dialog onClose={handleClose} open={openAlertDialog}>
				<DialogContent>
					<Box className={classes.dialogBox}>
						<Box className={classes.icon}>
							<img src={ErrorImage} alt="alt" />
						</Box>
						<Typography className={classes.heading}>
							There seems to be something wrong with the information you entered.
						</Typography>
						<Typography className={classes.subText}>
							That &apos; s ok, this happens sometimes.
						</Typography>
						<Typography className={classes.errorText}>
							Please try the following to resolve the error:
						</Typography>
						<Button className={classes.button} onClick={handleClose}>
							OK
						</Button>
						<Typography className={classes.contactUsText} onClick={handleContactUs}>
							Need help? Contact Us
						</Typography>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

ErrorDialog.propTypes = {
	openAlertDialog: PropTypes.bool,
	setOpenAlertDialog: PropTypes.func,
};

export default ErrorDialog;
