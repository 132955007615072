import { API, Auth } from 'aws-amplify';

import { DEMO } from '../utils/constants/demoData';
import log from '../utils/log';

const apiService = async (type, path, isPublic, { ...options } = { options: {} }) => {
	let apiOptions;
	if (!isPublic && process.env.REACT_APP_ENV !== DEMO) {
		const authRes = await Auth.currentSession();
		const authenticatedUser = await Auth.currentAuthenticatedUser();
		log('[Services][index]authRes', authRes);
		log('[Services][index]authenticatedUser', authenticatedUser);
		apiOptions = {
			headers: {
				Authorization: authRes.idToken.jwtToken,
			},
		};
	}
	apiOptions = {
		...apiOptions,
		...options,
	};

	return API[type]('userportal', path, apiOptions);
};

export const get = (...params) => apiService('get', ...params);

export const post = (...params) => apiService('post', ...params);
