import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	text: {
		color: 'black',
		display: 'flex',
		alignContent: 'center',
		margin: theme.spacing(1),
	},
	icon: {
		marginRight: theme.spacing(1),
	},
}));

export default styles;
