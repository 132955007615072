import API from '../utils/constants/apiEndPoints';
import NOTIFICATION_EMAIL from '../utils/constants/notificationEmailStatus';
import log from '../utils/log';

import { post } from '.';

const sendNotificationEmail = async (
	status,
	isPublic,
	errorBody = null,
	isUserNotification = false,
	userEmail = null
) => {
	let myInit = {};
	let notificationRoute = API.NOTIFICATION_EMAIL;
	if (isPublic) {
		myInit = {
			...myInit,
			headers: {
				Authorization: process.env.REACT_APP_NOTIFICATION_TOKEN,
			},
		};
		notificationRoute = API.NOTIFICATION_EMAIL_PUBLIC;
	}
	if (errorBody) {
		myInit = {
			...myInit,
			body: {
				subject: NOTIFICATION_EMAIL[status]?.heading,
				body: errorBody,
			},
		};
	} else {
		myInit = {
			...myInit,
			body: {
				subject: NOTIFICATION_EMAIL[status]?.heading,
				body: NOTIFICATION_EMAIL[status]?.body,
				isUserNotification,
				userEmail,
			},
		};
	}

	try {
		const res = await post(notificationRoute, isPublic, myInit);
		log('[Notification]', res);
	} catch (error) {
		log('[Notification] error', error);
	}
};

export default sendNotificationEmail;
