import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import React from 'react';

import MainApp from './MainApp';
import { StoreProvider } from './store/index';
import theme from './theme';
import log from './utils/log';

const generateClassName = createGenerateClassName({
	productionPrefix: 'leda-health-',
});

function App() {
	// Initial state
	const initialLoggedInState = () => sessionStorage.getItem('userLoggedIn') === 'true';
	const initialState = {
		isLoggedIn: initialLoggedInState(),
	};
	log('inside App');

	return (
		<StoreProvider initialValue={initialState}>
			<StylesProvider generateClassName={generateClassName}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<MainApp />
				</ThemeProvider>
			</StylesProvider>
		</StoreProvider>
	);
}

export default App;
