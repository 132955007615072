import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		color: theme.palette.primary.main,
		background: '#FFFFFF',
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
	},
	body: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			// padding: theme.spacing(0, 4),
			background: '#FFFFFF',
			gap: '30px',
		},
	},
	homeBox: {
		background: '#FFFFFF',
		display: 'flex',
		flexDirection: 'column',
		// minHeight: '100vh',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			flexDirection: 'row',
			background: '#FFFFFF',
			gap: '10px',
		},
	},
	homeBoxContent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'top',
		justifyContent: 'center',
		width: '65%',
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			flexDirection: 'column',
			textAlign: 'center',
			background: '#FFFFFF',
			width: '100%',
			padding: '1px',
			gap: '30px',
			// marginTop: '10px',
		},
	},
	homeBoxTextContent: {
		width: '100%',
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2),
		},
	},
	homeBoxTitle: {
		fontSize: 30,
		fontWeight: '400',
		fontFamily: 'Tropiline',
		lineHeight: '37px',
		color: '#003366',
		marginBottom: theme.spacing(2),
		paddingLeft: theme.spacing(1),
	},

	// light blue container, bottom half of page
	homeBoxNoticeContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: theme.spacing(2),
		width: '100%',
		backgroundColor: '#F9FCFF',
		// border: '1px solid #BDC7C3',
		padding: theme.spacing(10),
		[theme.breakpoints.down('md')]: {
			padding: 1,
		},
	},

	homeBoxNotice: {
		fontSize: 30,
		fontWeight: 400,
		lineHeight: '37px',
		color: '#003366',
		backgroundColor: '#ffffff',
		marginBottom: theme.spacing(2),
		padding: theme.spacing(1),
		// border: '1px solid #BDC7C3',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'left',
		justifyContent: 'left',
		width: '80%',
		[theme.breakpoints.down('md')]: {
			fontSize: 15,
		},
	},

	homeBoxNoticeComponent: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'left',
		justifyContent: 'left',
		padding: theme.spacing(1),
	},

	homeBoxNoticeComponentTitle: {
		fontSize: '30px',
		fontWeight: 'bold',
		paddingLeft: '10px',
		paddingTop: '5px',
		fontFamily: 'Tropiline',

		[theme.breakpoints.down('md')]: {
			fontSize: 20,
		},
	},

	homeBoxNoticeComponentText: {
		fontSize: '18px',
		paddingLeft: '10px',
		width: '60%',
		[theme.breakpoints.down('md')]: {
			fontSize: 13,
			width: '100%',
		},
	},

	ledaLogoBox: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'top',
		justifyContent: 'center',
		backgroundColor: '#1d4c4e',
		padding: theme.spacing(2),
		width: '180px',
		height: '180px',
		borderRadius: '10%',
		marginRight: theme.spacing(6),
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '230px',
			// margin: 'auto',
			justifyContent: 'center',
			alignItems: 'center',
			margin: 'auto',
			borderRadius: 15,
			padding: theme.spacing(5.5, 11.25),
			maxWidth: 'unset',
		},
	},
	ledaLogo: {
		width: 150,
		padding: theme.spacing(1),
		[theme.breakpoints.down('md')]: {
			margin: 'auto',
			width: 180,
			marginTop: '40px',
		},
	},
	forSurvivorsBySurvivors: {
		width: 130,
		marginLeft: '10px',
		[theme.breakpoints.down('md')]: {
			margin: 'auto',
			width: 150,
			marginBottom: '40px',
		},
	},
	startBox: {
		background: '#FFFFFF',
		[theme.breakpoints.down('md')]: {
			padding: 15,
			maxWidth: 'unset',
		},
	},
	card: {
		maxWidth: 1364,
		margin: '0px auto',
		padding: theme.spacing(7.5, 4),
		background: '#FFFFFF',
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(5.5, 11.25),
			maxWidth: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			padding: 0,
		},
	},
	startCard: {
		borderRadius: 15,
		textAlign: 'center',
		// backgroundImage: `url(${GettingStartedBg})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundImage:
			'linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73))',
		[theme.breakpoints.down('md')]: {
			borderRadius: 32,
		},
	},
	content: {
		color: theme.palette.primary.main,
	},
	cardContent: {
		[theme.breakpoints.down('sm')]: {
			fontSize: 14,
			lineHeight: '17px',
		},
		fontSize: 18,
		lineHeight: '22px',
		fontWeight: '900',
		fontFamily: 'Tropiline',
	},
	cardHeading: {
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(5),
			fontSize: 25,
		},
		marginTop: theme.spacing(6),
		fontSize: 40,
		color: '#003366',
		fontFamily: 'Tropiline',
		textTransform: 'uppercase',
		fontWeight: '900',
	},
	cardBottomText: {
		fontSize: 16,
		fontWeight: 'bold',
		fontFamily: 'Tropiline',

		color: theme.palette.primary.main,
	},
	startButton: {
		textTransform: 'none',
		border: '1px solid #1E4C4E',
		boxSizing: 'border-box',
		borderRadius: 9,
		color: theme.palette.primary.main,
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 16,
		lineHeight: '20px',
		padding: theme.spacing(3.25, 6.25),
		margin: theme.spacing(4, 0),
		width: 'fit-content',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1.5, 3),
			margin: theme.spacing(3.5),
			width: 'unset',
		},
	},
	serviceHeading: {
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: 20,
		lineHeight: '24px',
		color: theme.palette.primary.main,
		marginTop: theme.spacing(4),
		fontFamily: theme.typography.fontFamily,
	},
	box: {
		marginBottom: theme.spacing(3.75),
	},
	localResourcesCard: {
		border: '1px solid #BDC7C3',
		boxSizing: 'border-box',
		borderRadius: 15,
		marginBottom: theme.spacing(2),
		marginRight: theme.spacing(1),
		marginTop: theme.spacing(1),
		height: 220,
		[theme.breakpoints.down('sm')]: {
			height: 'unset',
			width: 'unset',
			margin: theme.spacing(0, 0, 2, 0.5),
		},
	},
	resourceContainer: {
		backgroundColor: '#FAFAFA',
		[theme.breakpoints.down('md')]: {
			padding: 0,
			backgroundColor: 'unset',
			paddingBottom: 20,
		},
	},
	resourceBox: {
		maxWidth: 1364,
		marginTop: 100,
		marginBottom: 100,
		padding: theme.spacing(0, 4),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(0, 11.25),
			maxWidth: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			padding: 0,
			maxWidth: 'unset',
		},
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	resourceTitle: {
		fontWeight: 400,
		fontSize: 30,
		lineHeight: '37px',
		padding: theme.spacing(7.5, 5, 2.5, 3),
		color: theme.palette.primary.main,
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(4, 5, 2.5, 3),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(6.25, 8.75, 2.5, 3),
		},
	},
	resourceContent: {
		fontWeight: 400,
		fontSize: 18,
		lineHeight: '22px',
		color: theme.palette.primary.main,
		backgroundColor: '#FFFFFF',
		width: '100%',
		padding: theme.spacing(0, 2.5, 1.25, 1.25),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(0, 2.5, 1.25, 1.25),
			fontSize: 14,
		},
	},
	loaderBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh',
		width: '100%',
	},
	chatFab: {
		position: 'fixed',
		right: theme.spacing(2),
		bottom: theme.spacing(3),
		[theme.breakpoints.down('md')]: {
			position: 'fixed',
			right: theme.spacing(2),
			bottom: theme.spacing(3),
		},
	},
	errorDiv: {
		display: 'flex',
		margin: 'auto',
		flexDirection: 'column',
		height: '100vh',
		width: '100vw',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			margin: 'auto',
			flexDirection: 'column',
			height: '100vh',
			width: '80vw',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
		},
	},
	errorIcon: {
		fontSize: 48,
	},
	supportText: {
		color: '#F48273',
	},
	supportDiv: {
		overflowY: 'auto',
		width: 350,
		borderRadius: theme.spacing(1),
		border: '1px solid',
		backgroundColor: '#FFFFFF',
		position: 'fixed',
		right: 50,
		bottom: 100,
		[theme.breakpoints.down('sm')]: {
			minHeight: 'fit-content',
			maxHeight: 500,
			height: '70vh',
			width: 340,
			overflowY: 'auto',
			border: '1px solid',
			borderRadius: theme.spacing(1),
			backgroundColor: '#FFFFFF',
			position: 'fixed',
			right: theme.spacing(2),
			bottom: theme.spacing(15),
		},
	},
	partnershipContentLeft: {
		textAlign: 'left',
		marginLeft: theme.spacing(12.5),
		color: theme.palette.primary.main,
	},
	partnershipContentRight: {
		textAlign: 'right',
		marginRight: theme.spacing(12.5),
		color: theme.palette.primary.main,
	},
	mobileViewCardContent: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		margin: 'auto',
	},
}));

export default useStyles;
