import { makeStyles } from '@material-ui/core';

import GradientBlue from '../../assets/Gradient_Blue.svg';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		color: theme.palette.primary.main,
		backgroundColor: '#FAFAFA',
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
		backgroundImage: `url(${GradientBlue})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	},
	main: {
		width: '100%',
		color: theme.palette.primary.main,
		padding: theme.spacing(0, 12.5, 5),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2.5, 7),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(4, 3),
		},
	},
	container: {
		borderRadius: theme.spacing(2),
		padding: theme.spacing(5, 10, 7, 10),
		background: '#FFFFFF',
		maxWidth: 1262,
		margin: theme.spacing(9, 'auto'),
		[theme.breakpoints.down('md')]: {
			height: 'unset',
		},
		[theme.breakpoints.down('sm')]: {
			margin: 0,
			padding: theme.spacing(5.5),
			height: 'unset',
		},
	},
	submitBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		color: '#000000',
	},
	imgBox: {
		background: '#C8E8D1',
		borderRadius: '50%',
		width: 'fit-content',
	},
	imgCheck: {
		width: '83px',
	},
	title: {
		fontSize: 30,
		lineHeight: '37px',
		maxWidth: 518,
		margin: theme.spacing(5, 0),
		textAlign: 'center',
	},
	subTitle: {
		fontSize: 20,
		lineHeight: '24px',
		margin: theme.spacing(6, 0, 0),
		color: '#000000',
	},
	orderBox: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		padding: theme.spacing(5, 10),
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			padding: theme.spacing(5, 0),
		},
	},
	statusBlock: {
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			alignItems: 'center',
			margin: theme.spacing(2, 0),
			justifyContent: 'space-between',
		},
	},
	refreshButton: {
		textTransform: 'none',
		color: theme.palette.primary.main,
		border: '1px solid #C7D2D3',
		borderRadius: 9,
		fontSize: 14,
		lineHeight: '30px',
		marginLeft: theme.spacing(2),
		'&:hover': {
			background: '#FFFFFF',
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0,
		},
	},
	cancelButton: {
		textTransform: 'none',
		color: '#DF1714',
		border: '1px solid #FB8166',
		borderRadius: 9,
		fontSize: 14,
		lineHeight: '30px',
		'&:hover': {
			background: '#FFFFFF',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: 35,
		},
	},
	list: {
		marginRight: 42,
		[theme.breakpoints.down('sm')]: {
			marginRight: 0,
			paddingLeft: theme.spacing(2.25),
		},
	},
	listItem: {
		fontSize: 16,
		lineHeight: '30px',
	},
	items: {
		fontSize: 20,
		lineHeight: '24px',
		margin: theme.spacing(4, 0, 0),
		color: '#000000',
		'&:first-child': {
			margin: 0,
		},
	},
	loaderBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh',
		width: '100%',
	},
	activateButton: {
		background: '#BEE2E2 !important',
		borderRadius: '9px !important',
		color: `${theme.palette.primary.main} !important`,
		padding: '10px 34px !important',
		textTransform: 'none !important',
		width: 576,
		height: 80,
		fontSize: 16,
		fontWeight: 700,
		'&:hover': {
			background: '#BEE2E2',
			color: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			width: 'auto',
			height: 'auto',
		},
	},
	homeButton: {
		textDecoration: 'underline',
		marginTop: theme.spacing(3.75),
	},
	brandWrapper: {
		display: 'flex',
		alignItems: 'center',
		color: 'black',
		width: '100%',
		marginLeft: theme.spacing(25),
		marginBottom: theme.spacing(6.25),
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0,
			paddingLeft: theme.spacing(2.25),
		},
	},
}));

export default useStyles;
