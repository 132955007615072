import API from '../utils/constants/apiEndPoints';

import { post } from '.';

const cancelSTITestOrder = async (orgId, orderId, deliveryId) => {
	const body = {
		orgId,
		orderId,
		deliveryId,
	};
	try {
		const response = await post(API.CANCEL_STI_ORDER, false, { body });
		if (response) {
			return {
				success: true,
				res: response,
			};
		}
		return {
			success: true,
			res: response,
		};
	} catch (error) {
		return {
			success: false,
			res: error,
		};
	}
};

export default cancelSTITestOrder;
