/* eslint-disable no-nested-ternary */
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import SuccessIcon from '../../../assets/checkMarkIcon.svg';
import ErrorImage from '../../../assets/errorDialog/exclaimation.svg';
import sendNotificationEmail from '../../../Services/sendNotificationEmail';
import userActivationLink from '../../../Services/userActivationLink';
import ROUTES from '../../../utils/constants/routes';
import log from '../../../utils/log';

import styles from './Styles/SignupErrorModal.Style';

function SignupErrorModal({ open, setOpen, email, token, orgId, id, retry }) {
	const classes = styles();
	const history = useHistory();
	const [response, setResponse] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	log('inside error modal emailaddresss', email);

	const RESPONSES = {
		'User not found': {
			heading: 'Account not found',
			subHeading: `There was an error locating your account. A member of the Leda support team will reach out to you at ${email} within 1 business day.`,
		},
		'Error generating new link': {
			heading: 'An error occurred',
			subHeading: `You have reached the maximum limit for requesting a new link. A member of the Leda support team will reach out to you at ${email} within 1 business day.`,
		},
		'Something went wrong': {
			heading: 'Something went wrong',
			subHeading: `Something went wrong. A member of the Leda support team will reach out to you at ${email} within 1 business day.`,
		},
		'New activation link created successfully.': {
			heading: 'Sent',
			subHeading: `A new link has been sent to your email address.`,
		},
	};

	const handleClose = () => {
		sendNotificationEmail('email_activation_error', true);
		setOpen(false);
		history.push(ROUTES.DEFAULT);
	};

	const handleButtonClick = async () => {
		try {
			setIsLoading(true);
			if (Number(retry) >= 3) {
				setResponse('Error generating new link');
			} else {
				const res = await userActivationLink(token, email, id, orgId);
				setResponse(res);
			}
			setIsLoading(false);
		} catch (err) {
			const errRes =
				err.message === 'User not found'
					? 'User not found'
					: err.message === 'Error generating new link'
					? 'Error generating new link'
					: 'Something went wrong';
			setResponse(errRes);
			setIsLoading(false);
		}
	};

	return (
		<div>
			<Dialog
				classes={{
					paper: classes.root,
				}}
				open={open}>
				<DialogContent>
					<Box className={classes.dialogBox}>
						<Box className={classes.icon}>
							{response === 'New activation link created successfully.' ? (
								<img src={SuccessIcon} alt="alt" />
							) : (
								<img src={ErrorImage} alt="alt" />
							)}
						</Box>
						<Typography className={classes.heading}>
							{response ? RESPONSES[response].heading : 'Your invitation has expired'}
						</Typography>
						<Typography className={classes.subText}>
							{response
								? RESPONSES[response].subHeading
								: 'This link has expired for security reasons. Please request a new link below. Once you have requested a new link, only the newest link in your email will work.'}
						</Typography>
						<Button className={classes.button} onClick={response ? handleClose : handleButtonClick}>
							{isLoading ? <CircularProgress size={24} /> : response ? 'OK' : 'Request New Link'}
						</Button>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

SignupErrorModal.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	email: PropTypes.string,
	token: PropTypes.string,
	orgId: PropTypes.string,
	id: PropTypes.string,
	retry: PropTypes.string,
};

export default SignupErrorModal;
