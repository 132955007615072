import log from './log';

const dateDiffInYears = (newDate) => {
	let today = new Date();
	const dd = String(today.getDate()).padStart(2, '0');
	const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
	const yyyy = today.getFullYear();
	today = `${mm}/${dd}/${yyyy}`;
	const dateToday = new Date(today);
	const MS_PER_YEAR = 365 * 1000 * 60 * 60 * 24;
	// const MS_PER_DAY = 1000 * 3600 * 24;
	// console.log('[dateDiffInYears] newDate', newDate);
	const newDate2 = new Date(newDate);
	let utc1;
	let utc2;
	// Discard the time and time-zone information.
	if (newDate2 !== '') {
		utc1 = Date.UTC(dateToday.getFullYear(), dateToday.getMonth(), dateToday.getDate());
		utc2 = Date.UTC(newDate2.getFullYear(), newDate2.getMonth(), newDate2.getDate());
	}
	return Math.floor((utc1 - utc2) / MS_PER_YEAR);
};

const choose = (choices) => {
	const index = Math.floor(Math.random() * choices.length);
	return choices[index];
};

const generateAlias = () => {
	const namesList = ['Mars', 'Venus', 'Mercury', 'Jupiter', 'Saturn', 'Neptune', 'Pluto'];
	const name = choose(namesList);
	log('alias chosen', name);
	return name;
};

const timeForFrontEnd = (dateString) => {
	// log('[timeForFrontEnd] dateString', dateString);
	const dateString2 = new Date(dateString);
	let newTime;
	if (dateString2 !== '' && dateString2 !== null && typeof dateString2 !== 'undefined') {
		if (dateString2.getMinutes() < 10) {
			newTime = `${dateString2.getHours()}:0${dateString2.getMinutes()}`;
		} else {
			newTime = `${dateString2.getHours()}:${dateString2.getMinutes()}`;
		}
		// const newTime = dateString.toISOString();
		log('[timeForFrontEnd] newTime', newTime);
	}
	return newTime;
};

const generateRandomId = (pre) => {
	return `${pre}_${new Date().getTime()}`;
};

const statusForFrontEnd = (status) => {
	log('[statusForFrontEnd] status', status);
	let newStatus;
	switch (status) {
		case 'could_not_deliver':
			newStatus = 'Could not Deliver';
			break;
		case 'delivery_created':
			newStatus = 'Delivery Created';
			break;
		case 'dasher_confirmed':
			newStatus = 'Dasher Confirmed';
			break;
		case 'driver_batched':
			newStatus = 'Driver Batched';
			break;
		case 'dasher_enroute_to_pickup':
			newStatus = 'Dasher Enroute to Pickup';
			break;
		case 'dasher_confirmed_store_arrival':
			newStatus = 'Dasher Confirmed Store Arrival';
			break;
		case 'dasher_picked_up':
			newStatus = 'Dasher Pickup Up';
			break;
		case 'dasher_enroute_to_dropoff':
			newStatus = 'Dasher Enroute to Dropoff';
			break;
		case 'dasher_confirmed_consumer_arrival':
			newStatus = 'Dasher Confirmed Consumer Arrival';
			break;
		case 'dasher_dropped_off':
			newStatus = 'Dasher Dropped Off';
			break;
		case 'delivery_pending_return':
			newStatus = 'Dasher Pending Return';
			break;
		case 'dasher_enroute_to_return':
			newStatus = 'Dasher Enroute to Return';
			break;
		case 'dasher_confirmed_return_arrival':
			newStatus = 'Dasher Confirmed Return Arrival';
			break;
		case 'dasher_dropped_off_return':
			newStatus = 'Dasher Dropped Off Return';
			break;
		case 'delivery_cancelled':
			newStatus = 'Delivery Cancelled';
			break;
		case 'delivery_attempted':
			newStatus = 'Delivery Attempted';
			break;
		case 'picked_up':
			newStatus = 'Picked Up';
			break;
		case 'pending_return':
			newStatus = 'Pending Return';
			break;
		case 'enroute_to_pickup':
			newStatus = 'Enroute to Pickup';
			break;
		case 'arrived_at_store':
			newStatus = 'Arrived at Store';
			break;
		case 'enroute_to_dropoff':
			newStatus = 'Enroute to Drop Off';
			break;
		case 'arrived_at_customer':
			newStatus = 'Arrived at Customer';
			break;
		case 'enroute_to_return':
			newStatus = 'Enroute to Return';
			break;
		case 'arrived_at_return':
			newStatus = 'Arrived at Return';
			break;
		case 'dropped_off_return':
			newStatus = 'Dropped Off Return';
			break;
		default:
			newStatus = status.charAt(0).toUpperCase() + status.slice(1);
	}
	log('[statusForFrontEnd] newStatus', newStatus);
	return newStatus;
};

const debounce = (func, delay = 1000) => {
	let timeout;
	return (...args) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			func(...args);
		}, delay);
	};
};

export {
	dateDiffInYears,
	timeForFrontEnd,
	statusForFrontEnd,
	generateAlias,
	generateRandomId,
	debounce,
};
