// @flow

// Building the initial State
const initialState = {
	user: null,
	isSchoolEmailVerified: false,
	openContactSupport: false,
	openCareTeamSupport: false,
	openTechSupport: false,
	cognitoUser: null,
	isLoggedIn: false,
	isMobile: false,
	orgId: null,
	kitOrdered: false,
	kitResults: null,
	kitActivated: false,
	trackingNumber: '',
	kitOrderDetails: null,
	planBOrdered: false,
	stiResults: null,
	stiTestingStatus: 'Pending',
	sessionToken: '',
	showLearnMoreCard: true,
	userProfileName: '',
	userLockLocation: '',
	userServiceList: {},
	userWelcomeContent: {},
	userResources: [],
	userEducation: [],
	alertContent: {
		message: null,
		level: 'success',
		path: '',
	},
	eligibilityQuiz: null,
	openDisableDialog: false,
	disableDialogMsg: {
		title: 'Services Temporarily Disabled',
		body: 'It is with heavy hearts that we share that in order to comply with a cease and desist from the Washington Attorney General, Leda Health is currently unable to provide Early Evidence Kits and Emergency Contraception. We are deeply committed to survivor well-being and access to support for all. Upon resolution of this legal matter, we hope to return with our entire suite of services and look forward to our ongoing partnership with Kappa Delta.',
	},
	serviceDisabled: false,
	toxicologyOrdered: false,
	toxicologyOrderDetails: null,
	stiOrdered: false,
	stiOrderDetails: null,
};

export default initialState;
