import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	dialog: {
		minHeight: '25%',
		minWidth: '30%',
		borderRadius: 15,
		alignItems: 'center',
		width: 1105,
		maxWidth: 'unset',
		padding: theme.spacing(8.5, 14),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(7, 4),
		},
	},
	dialogContent: {
		textAlign: 'center',
		display: 'contents',
	},
	title: {
		fontWeight: 400,
		fontSize: 30,
		lineHeight: '37px',
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			fontSize: 20,
			lineHeight: '24px',
		},
	},
	closeIcon: {
		position: 'absolute',
		cursor: 'pointer',
		top: theme.spacing(6.25),
		right: theme.spacing(6.25),
		[theme.breakpoints.down('sm')]: {
			top: theme.spacing(5),
			right: theme.spacing(4),
			width: theme.spacing(1.5),
		},
	},
	content: {
		fontSize: 16,
		lineHeight: '20px',
		color: theme.palette.primary.main,
		padding: theme.spacing(2.5, 8.75),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1.75, 0),
		},
	},
	dialogAction: {
		padding: theme.spacing(8.25, 0),
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			width: '100%',
			padding: theme.spacing(2.5, 0),
		},
	},
	button: {
		border: '1px solid #C7D2D3',
		borderRadius: 14,
		backgroundColor: '#FFFFFF',
		width: 380,
		height: 253,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		cursor: 'pointer',
		'&:hover': {
			border: '2px solid #81B1FA',
			background: '#FFFFFF',
			color: theme.palette.primary.main,
		},
		'&:first-child': {
			marginRight: theme.spacing(9),
		},
		[theme.breakpoints.down('sm')]: {
			'&:first-child': {
				marginRight: 0,
				marginBottom: theme.spacing(2.5),
			},
			'&:not(:first-child)': {
				marginLeft: 0,
			},
			width: '90%',
			maxWidth: 308,
			height: 'unset',
			padding: theme.spacing(2.5, 0),
		},
	},
	buttonText: {
		textTransform: 'none',
		color: theme.palette.primary.main,
		fontSize: 20,
		lineHeight: '24px',
		padding: theme.spacing(1.75, 0),
	},
	subText: {
		color: theme.palette.primary.main,
		fontSize: 14,
		lineHeight: '17px',
		maxWidth: 220,
	},
	imageBox: {
		background: 'rgba(179, 226, 194, 0.31)',
		width: 86,
		height: 86,
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			width: 62,
			height: 62,
		},
	},
}));

export default useStyles;
