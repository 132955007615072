import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import ArrowLeft from '../assets/ArrowLeft.svg';
import { useStore } from '../store';

import Footer from './Footer';
import Header from './Header';
import Loading from './Loading';
import useStyles from './Styles/UserLayout.Style';

function UserLayout({ children }) {
	const [store] = useStore();
	const { user, isMobile } = store;
	const history = useHistory();
	const classes = useStyles();

	return user ? (
		<Box className={classes.root}>
			{!isMobile && <Header showProfileMenu />}
			<Box className={classes.main}>
				<Box className={classes.topBar}>
					<Button
						className={classes.button}
						onClick={() => history.goBack()}
						startIcon={
							<img
								alt="A left arrow icon to signify that the page will open in a new tab."
								src={ArrowLeft}
							/>
						}>
						Back
					</Button>
				</Box>
				<Box className={classes.container}>
					<Box className={classes.listBox}>{children}</Box>
				</Box>
			</Box>

			<Footer disclaimer />
		</Box>
	) : (
		<Box className={classes.loaderBox}>
			<Loading />
		</Box>
	);
}

UserLayout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default UserLayout;
