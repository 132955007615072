import Anything from '../../assets/learnMoreIcon/Anything.svg';
import bathroom from '../../assets/learnMoreIcon/bathroom.svg';
import brushTeeth from '../../assets/learnMoreIcon/brush teeth.svg';
import FoodnDrink from '../../assets/learnMoreIcon/Food n drink.svg';
import Shower from '../../assets/learnMoreIcon/Shower.svg';
import Smoking from '../../assets/learnMoreIcon/Smoking.svg';

const BANNER_IMAGES = [
	{ title: 'Eating & drinking', image: FoodnDrink },
	{ title: 'Brushing teeth & rinsing mouth', image: brushTeeth },
	{ title: 'Using the restroom', image: bathroom },
	{ title: 'Showering', image: Shower },
	{ title: 'Smoking', image: Smoking },
	{ title: 'Anything that will disturb your body', image: Anything },
];

export default BANNER_IMAGES;
