/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "userportal",
            "endpoint": "https://kbuigetha6.execute-api.us-east-1.amazonaws.com/devel",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:5b9d1749-6000-4127-a1be-0971ca48d0cb",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_W7ld1ihp8",
    "aws_user_pools_web_client_id": "to7363d93hsn90tg39ot6r2d6",
    "oauth": {
        "domain": "leda-health-kit-pool-v1-testing.auth.us-east-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": [
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "eeks-devel",
            "region": "us-east-1"
        },
        {
            "tableName": "organizations-devel",
            "region": "us-east-1"
        },
        {
            "tableName": "planbs-devel",
            "region": "us-east-1"
        },
        {
            "tableName": "prospects-devel",
            "region": "us-east-1"
        },
        {
            "tableName": "stikits-devel",
            "region": "us-east-1"
        },
        {
            "tableName": "toxicology-devel",
            "region": "us-east-1"
        },
        {
            "tableName": "verificationcodes-devel",
            "region": "us-east-1"
        },
        {
            "tableName": "witness-devel",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;