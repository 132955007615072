import ACTION_TYPE from '../utils/constants/actionType';
import API from '../utils/constants/apiEndPoints';
import log from '../utils/log';

import { get } from '.';

const fetchSTITestOrderDetails = async (userId, orderId, dispatch) => {
	const PATH = `${API.FETCH_STI_ORDER}?userId=${userId}&orderId=${orderId}`;
	try {
		const responseData = await get(PATH, false);
		dispatch({
			type: ACTION_TYPE.SET_STI_ORDER_DETAILS,
			stiOrderDetails: responseData,
		});

		return responseData;
	} catch (e) {
		log('[fetchSTITestOrderDetails] error', e);
	}
};

export default fetchSTITestOrderDetails;
