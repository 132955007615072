const API = {
	FETCH_USER: '/user/fetch-details',
	UPDATE_USER: '/user/update-details',
	FETCH_LOCK_CODE: '/user/fetch-lock-code',
	SEND_QR_CODE: '/user/send-lock-code-email',
	CONFIRM_SIGN_UP: '/user/verify-personal-email-address',
	VERIFY_MEMBER: '/user/fetch-organization-id',
	SEND_OTP: '/user/send-email-address-confirmation-email',
	FORGET_PASSWORD: '/user/send-forgot-password-email',
	RESET_PASSWORD: '/user/send-reset-password-email',
	SIGN_UP: '/user/create-account',
	VERIFY_UNIVERSITY_EMAIL: '/user/verify-organization-email-address',
	CREATE_SUPPORT_TICKET: '/user/create-support-ticket',
	FETCH_EEK_RESULTS: '/eek/fetch-results',
	ORDER_EEK: '/eek/create-order',
	FETCH_STI_RESULTS: '/sti-kit/fetch-results',
	ORDER_STI_TEST: '/sti-kit/create-order',
	PLAN_B_CREATE_ORDER: '/plan-b/create-order',
	FETCH_EEK_ORDER: '/eek/fetch-order-details',
	FETCH_PLANB_ORDER: '/plan-b/fetch-order-details',
	CANCEL_EEK_ORDER: '/eek/cancel-order',
	CANCEL_PLAN_B_ORDER: '/plan-b/cancel-order',
	NOTIFICATION_EMAIL: '/user/send-notification-email',
	NOTIFICATION_EMAIL_PUBLIC: '/send-notification-email',
	USER_CONFIRM: '/user/confirm-account',
	FETCH_USER_EMAIL: '/user/fetch-user-email',
	USER_ACTIVATION_LINK: '/user/send-activation-link',
	REQUEST_ACCESS: '/user/send-access-request',
	ORDER_TOXICOLOGY: '/toxicology/create-order',
	FETCH_TOXICOLOGY_ORDER: '/toxicology/fetch-order-details',
	CANCEL_TOXICOLOGY_ORDER: '/toxicology/cancel-order',
	ORDER_STI: '/sti-test/create-order',
	FETCH_STI_ORDER: '/sti-test/fetch-order-details',
	CANCEL_STI_ORDER: '/sti-test/cancel-order',
};

export default API;
