import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	main: {
		width: '100%',
		color: theme.palette.primary.main,
		background: '#FFFFFF',
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100vh',
	},
	root: {
		width: '100%',
		color: theme.palette.primary.main,
		background: '#FFFFFF',
		padding: theme.spacing(0, 12.5, 5),
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(2.5, 7),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(4, 4),
		},
	},
	recommendBox: {
		background: 'rgba(30, 76, 78, 0.04)',
		border: '1px solid #B0BDBA',
		boxSizing: 'border-box',
		borderRadius: 15,
		margin: theme.spacing(2),
		padding: '20px 70px !important',
		[theme.breakpoints.down('md')]: {
			padding: '20px 48px !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '20px !important',
		},
	},
	link: {
		color: 'inherit',
		textDecoration: 'underline',
	},
	title: {
		fontSize: theme.spacing(2.5),
		fontWeight: 600,
		color: theme.palette.primary.main,
		paddingBottom: theme.spacing(2),
	},
	index: {
		fontWeight: 300,
		fontSize: 16,
		color: theme.palette.primary.main,
		marginRight: 25,
	},
	listItem: {
		fontWeight: 300,
		flexWrap: 'nowrap',
	},
	divider: {
		width: '100%',
	},
	separator: {
		margin: theme.spacing(2.5, -4, 5.25, -4),
	},
	button: {
		textTransform: 'none',
		fontSize: 16,
		fontWeight: 500,
		color: theme.palette.primary.main,
		margin: theme.spacing(2.5, 0, 5.25, 0),
		[theme.breakpoints.down('sm')]: {
			margin: 0,
		},
		'&:hover': {
			background: '#FFFFFF',
		},
	},
	banner: {
		textAlign: 'center',
		padding: '16px 56px !important',
		[theme.breakpoints.down('md')]: {
			padding: '16px 56px !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '16px !important',
		},
	},
	box: {
		padding: '16px 90px !important',
		[theme.breakpoints.down('md')]: {
			padding: '16px 64px !important',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '16px !important',
		},
	},
	image: {
		width: theme.spacing(16.25),
		[theme.breakpoints.down('md')]: {
			width: theme.spacing(13.75),
		},
		[theme.breakpoints.down('sm')]: {
			width: theme.spacing(10.5),
		},
	},
	imgTitle: {
		[theme.breakpoints.down('sm')]: {
			fontSize: 14,
		},
	},
	loaderBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh',
		width: '100%',
	},
}));

export default useStyles;
