import { rest } from 'msw';

import API from '../../utils/constants/apiEndPoints';

const FetchOrgId = [
	rest.post('*/user/fetch-organization-id', (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(222222));
	}),
	rest.post(`${API.SEND_OTP}`, (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.json({
				message: 'OTP generated',
				data: {
					sessionToken: 'sbYN5jyep4S0ge143NogrDjitDbDruQA',
				},
			})
		);
	}),
	rest.post(`*${API.VERIFY_UNIVERSITY_EMAIL}`, (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.json({
				message: 'Validated',
			})
		);
	}),
];

export default FetchOrgId;
