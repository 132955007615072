import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	div: {},
	dialog: {
		minHeight: '30%',
		borderRadius: 15,
		minWidth: '50%',
		alignItems: 'center',
		position: 'relative',
		padding: theme.spacing(14),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(10, 3.5, 6),
			textAlign: 'center',
		},
	},
	title: {
		fontSize: 30,
		lineHeight: '37px',
		color: theme.palette.primary.main,
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: 0,
		},
	},
	inputText: {
		fontSize: 16,
		lineHeight: '20px',
		letterSpacing: '0.095em',
		textTransform: 'uppercase',
	},
	input: {
		width: '100%',
	},
	dialogContent: {
		width: '100%',
		margin: theme.spacing(6.25, 0),
		[theme.breakpoints.down('sm')]: {
			padding: 0,
			margin: theme.spacing(6, 0, 3.25),
		},
	},
	dialogAction: {
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			padding: 0,
		},
	},
	errorText: {
		color: theme.palette.error.main,
	},
	closeIcon: {
		position: 'absolute',
		top: theme.spacing(5),
		right: theme.spacing(6),
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			right: theme.spacing(3),
		},
	},
	button: {
		backgroundColor: '#BEE2E2',
		color: theme.palette.primary.main,
		height: 'fit-content',
		textTransform: 'none',
		borderRadius: 9,
		fontSize: 16,
		lineHeight: '20px',
		padding: theme.spacing(2.5, 4.25),
		width: theme.spacing(37.5),
		maxWidth: theme.spacing(37.5),
		'&:hover': {
			background: '#BEE2E2',
			color: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			width: 'inherit',
		},
	},
}));

export default useStyles;
