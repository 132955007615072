import { rest } from 'msw';

import API from '../../utils/constants/apiEndPoints';

const CancelPlanBOrder = [
	rest.post(`*${API.CANCEL_PLAN_B_ORDER}`, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json({ success: true }));
	}),
];

export default CancelPlanBOrder;
