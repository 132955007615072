import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { shutdownIntercom } from '../Services/intercom/method';
import { useStore } from '../store';

import Footer from './Footer';
import MenuBar from './MenuBar';
import styles from './Styles/AuthLayout.Style';

function AuthLayout({ children, showSupportBubble }) {
	const classes = styles();
	const [store] = useStore();
	const { isMobile } = store;

	useEffect(async () => {
		if (!showSupportBubble) await shutdownIntercom();
	}, []);

	return (
		<div className={classes.root}>
			<div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
				<div style={{ width: isMobile ? '100%' : '40%' }}>
					<MenuBar publicHeader={isMobile} />
				</div>
				<div style={{ width: isMobile ? '100%' : '60%' }}>{children}</div>
			</div>
			<Footer customClass={isMobile ? classes.footer : ''} />
		</div>
	);
}

AuthLayout.propTypes = {
	children: PropTypes.node.isRequired,
	showSupportBubble: PropTypes.bool,
};

AuthLayout.defaultProps = {
	showSupportBubble: false,
};

export default AuthLayout;
