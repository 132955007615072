import API from '../utils/constants/apiEndPoints';

import { post } from '.';

const userActivationLink = (token, email, id, orgId) => {
	const headers = {
		Authorization: token,
	};
	const body = {
		oid: orgId,
		email,
		id,
	};

	// return post(
	//	`${API.USER_ACTIVATION_LINK}?t=${encodeURIComponent(token)}&id=${encodeURIComponent(id)}`,
	//	true,
	//	{ headers, body }
	// );
	return post(
		`${API.USER_ACTIVATION_LINK}?t=${encodeURIComponent(token)}&email=${encodeURIComponent(email)}`,
		true,
		{ headers, body }
	);
};

export default userActivationLink;
