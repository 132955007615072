import {
	Button,
	CircularProgress,
	InputLabel,
	Tooltip,
	Typography,
	TextField,
	Link,
	Box,
} from '@material-ui/core';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import AuthLayout from '../../Components/AuthLayout';
import InactivityTracker from '../../Components/InactivityTracker';
import Loading from '../../Components/Loading';
import PasswordRequirements from '../../Components/PasswordRequirements';
import Toolbar from '../../Components/Toolbar';
import { post } from '../../Services';
import sendNotificationEmail from '../../Services/sendNotificationEmail';
import userSignOut from '../../Services/userSignOut';
import { useStore } from '../../store/index';
import API from '../../utils/constants/apiEndPoints';
import { DEMO } from '../../utils/constants/demoData';
import ROUTES from '../../utils/constants/routes';
import { dateDiffInYears } from '../../utils/helperFunctions';
import log from '../../utils/log';
import updateUserDetails from '../../utils/updateUserDetails';
import { validatePassword } from '../../utils/validators';
import sharedStyles from '../Styles/SharedStyles';

import SignUpAlertDialog from './SignUpAlertDialog';
import styles from './Styles/ResetPassword.Style';
import UnderAgeModal from './UnderAgeModal';

function ResetPassword({ tempPassword, userConfirm, cognitoUser }) {
	const classes = { ...styles(), ...sharedStyles() };
	const [store, dispatch] = useStore();
	const history = useHistory();
	const { user, userProfileName, orgId, isMobile } = store;
	const [password, setPassword] = useState('');
	const [oldPassword, setOldPassword] = useState(tempPassword);
	const [confirmPassword, setConfirmPassword] = useState('');
	const [formError, setFormError] = useState('');
	const [resetMessage, setResetMessage] = useState('');
	const [disableBtn, setDisableBtn] = useState(false);
	const [dateOfBirth, setDateOfBirth] = useState('');
	const [underAgeModal, setUnderAgeModal] = useState(false);
	const [dateErrorMessage, setDateErrorMessage] = useState('');
	const [showNewPasswordAlert, setShowNewPasswordAlert] = useState(false);
	const [showConfirmPasswordAlert, setShowConfirmPasswordAlert] = useState(false);

	const handleSignOut = async (e) => {
		e.preventDefault();
		userSignOut(history, dispatch);
	};

	const handleSubmit = async (e) => {
		setDisableBtn(true);
		if (
			password !== '' &&
			oldPassword !== '' &&
			confirmPassword !== '' &&
			password === confirmPassword &&
			validatePassword(password) &&
			validatePassword(confirmPassword)
		) {
			try {
				if (userConfirm) {
					if (cognitoUser?.challengeName === 'NEW_PASSWORD_REQUIRED') {
						const authRes = await Auth.completeNewPassword(cognitoUser, password);
						const myInit = {
							body: {
								userId: authRes.username,
								orgId,
								userDOB: dateOfBirth,
							},
						};
						const updateRes = await post(API.UPDATE_USER, false, myInit);
						log('[Reset Password] updateRes ', updateRes);
						updateUserDetails(updateRes, dispatch);
						await sendNotificationEmail('account_activated', false);
						await sendNotificationEmail(
							'user_account_activated',
							false,
							null,
							true,
							updateRes?.email
						);
						history.push(ROUTES.HOME);
					} else {
						setFormError('Error while setting new password for account');
					}
				} else {
					if (process.env.REACT_APP_ENV !== DEMO) {
						const userAuth = await Auth.currentAuthenticatedUser();
						const resetRes = await Auth.changePassword(userAuth, oldPassword, password);
						log('[ResetPassword] Response from resetting password', resetRes);
						setResetMessage(resetRes.message);
					}
					await handleSignOut(e);
				}
			} catch (error) {
				const errorMessage =
					error.response?.data?.message ||
					error.message ||
					error.response.message ||
					error.response.data.message;
				log('error resetting password up:', errorMessage);
				await sendNotificationEmail('error', false, error.toString());
				setFormError(errorMessage);
			}
		} else if (password !== confirmPassword) {
			setFormError('Passwords do not match');
			setDisableBtn(false);
		} else {
			setFormError(
				'A password should be between 8 to 15 characters, contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.'
			);
			setDisableBtn(false);
		}
	};

	return user ? (
		<AuthLayout>
			<InactivityTracker showPopup={false} />
			{!userConfirm && <Toolbar backButton userProfileName={userProfileName} />}
			<div className={classes.main} style={{ justifyContent: 'center' }}>
				<div className={classes.side}>
					<div className={classes.box2} style={{ justifyContent: isMobile ? 'normal' : 'center' }}>
						<form autoComplete="off" className={classes.form} onSubmit={handleSubmit}>
							<Typography className={classes.title} color="primary" variant="h5">
								{userConfirm ? 'Welcome to Leda' : 'Reset Password'}
							</Typography>
							<Typography className={classes.text1} color="primary" variant="subtitle1">
								{userConfirm
									? 'Please confirm your date of birth and set up a password to activate your account.'
									: 'Please enter a password for your account.'}
							</Typography>
							{!userConfirm && (
								<>
									<InputLabel className={classes.text2} color="primary" required>
										OLD PASSWORD
									</InputLabel>
									<TextField
										classes={{
											root: oldPassword === '' ? classes.input : classes.inputFilled,
										}}
										defaultValue=""
										margin="normal"
										onChange={(e) => {
											setFormError('');
											setDisableBtn(false);
											setOldPassword(e.target.value);
										}}
										required
										type="password"
										variant="outlined"
									/>
								</>
							)}
							{userConfirm && (
								<>
									<InputLabel className={classes.text2} color="primary" required>
										BIRTHDAY (DD/MM/YYYY)
									</InputLabel>
									<div className={classes.emailBox}>
										<TextField
											classes={{
												root: dateOfBirth === '' ? classes.emailInput : classes.emailInputFilled,
											}}
											margin="normal"
											onBlur={() => {
												if (dateErrorMessage) {
													setUnderAgeModal(true);
												}
											}}
											onChange={(e) => {
												setDateErrorMessage('');
												setDateOfBirth(e.target.value);
												const newDate = new Date(e.target.value);
												const difference = dateDiffInYears(newDate);
												if (difference < 18) {
													setDateErrorMessage('Invalid entry!');
												}
											}}
											required
											type="date"
											variant="outlined"
										/>
									</div>
									{dateErrorMessage !== '' && (
										<Box className={classes.error}>
											{'You must be over the age of 18. See more information '}
											<span
												aria-hidden
												className={classes.link}
												onClick={() => setUnderAgeModal(true)}>
												here
											</span>
											.
										</Box>
									)}
								</>
							)}
							<InputLabel className={classes.text2} color="primary" required>
								NEW PASSWORD
							</InputLabel>
							<Tooltip
								arrow
								classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
								open={showNewPasswordAlert}
								placement={isMobile ? 'bottom' : 'right'}
								title={<PasswordRequirements password={password} />}>
								<TextField
									classes={{
										root: password === '' ? classes.input : classes.inputFilled,
									}}
									onBlur={() => setShowNewPasswordAlert(false)}
									onFocus={() =>
										password !== '' && !validatePassword(password) && setShowNewPasswordAlert(true)
									}
									defaultValue=""
									margin="normal"
									onChange={(e) => {
										if (password !== '' && !validatePassword(password))
											setShowNewPasswordAlert(true);
										setFormError('');
										setPassword(e.target.value);
									}}
									required
									type="password"
									variant="outlined"
								/>
							</Tooltip>
							<InputLabel className={classes.text2} required>
								CONFIRM PASSWORD
							</InputLabel>
							<Tooltip
								arrow
								classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
								open={showConfirmPasswordAlert}
								placement={isMobile ? 'bottom' : 'right'}
								title={<PasswordRequirements password={confirmPassword} />}>
								<TextField
									classes={{
										root: confirmPassword === '' ? classes.input : classes.inputFilled,
									}}
									onBlur={() => setShowConfirmPasswordAlert(false)}
									onFocus={() =>
										confirmPassword !== '' &&
										!validatePassword(confirmPassword) &&
										setShowConfirmPasswordAlert(true)
									}
									defaultValue=""
									margin="normal"
									onChange={(e) => {
										if (confirmPassword !== '' && !validatePassword(confirmPassword))
											setShowConfirmPasswordAlert(true);
										setFormError('');
										setConfirmPassword(e.target.value);
									}}
									required
									type="password"
									variant="outlined"
								/>
							</Tooltip>
							<br />
							<div className={classes.errorMsg}>{formError}</div>
							<div className={classes.resetMsg}>{resetMessage}</div>
							<Button
								type="submit"
								classes={{
									root: classes.submitBtn,
									disabled: classes.disabledBtn,
								}}
								disabled={
									(userConfirm && (dateErrorMessage !== '' || dateOfBirth === '')) ||
									password === '' ||
									confirmPassword === '' ||
									formError !== '' ||
									disableBtn ||
									oldPassword === ''
								}
								onClick={(e) => handleSubmit(e)}
								variant="contained">
								<b>{disableBtn ? <CircularProgress size={24} /> : 'Submit'}</b>
							</Button>
						</form>
					</div>
				</div>
			</div>
			<SignUpAlertDialog
				open={underAgeModal}
				setOpen={setUnderAgeModal}
				subTitle={
					<>
						{'If you have been sexually assaulted, we recommend a sexual assault exam, and '}
						<Link
							href="https://www.leda.co/exam-locator"
							rel="noopener noreferrer"
							target="_blank"
							underline="always">
							we can help you find one here.
						</Link>
					</>
				}
				title="Because you are under the age of 18, you cannot access Leda Health's Services ">
				<UnderAgeModal />
			</SignUpAlertDialog>
		</AuthLayout>
	) : (
		<Loading />
	);
}

ResetPassword.propTypes = {
	tempPassword: PropTypes.string,
	userConfirm: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	cognitoUser: PropTypes.any,
};

ResetPassword.defaultProps = {
	tempPassword: '',
	userConfirm: false,
	cognitoUser: {},
};
export default ResetPassword;
