import ACTION_TYPE from '../utils/constants/actionType';
import API from '../utils/constants/apiEndPoints';
import log from '../utils/log';

import { get } from '.';

const fetchToxicologyOrderDetails = async (userId, orderId, dispatch) => {
	const PATH = `${API.FETCH_TOXICOLOGY_ORDER}?userId=${userId}&orderId=${orderId}`;
	try {
		const responseData = await get(PATH, false);
		dispatch({
			type: ACTION_TYPE.SET_TOXICOLOGY_ORDER_DETAILS,
			toxicologyOrderDetails: responseData,
		});

		return responseData;
	} catch (e) {
		log('[fetchToxicologyOrderDetails] error', e);
	}
};

export default fetchToxicologyOrderDetails;
