export const ORG_DETAILS_FORM = [
	{
		label: 'Your Full Name*',
		name: 'fullName',
		isRequired: true,
		placeholder: 'Enter First and Last Name',
	},
	{
		label: 'Organization',
		name: 'organization',
		isRequired: false,
		placeholder: 'Enter Name of Organization',
	},
	{
		label: 'What are you looking for?',
		name: 'lookingFor',
		isRequired: true,
		placeholder: 'Select Option',
		type: 'select',
	},
	{ label: 'Email*', name: 'email', placeholder: 'Email', type: 'email' },
];

export const OPTIONS_ORG_MODAL = [
	"I'm looking for information on Leda services",
	"I'm looking to partner with Leda",
	"I've experienced sexual harm and am looking for support",
];
