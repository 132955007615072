import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import AlertDialog from '../../Components/AlertDialog';
import OrderKit from '../../Components/OrderKit';
import orderSTITest from '../../Services/orderSTITest';
import { useStore } from '../../store';
import { ORDER_STI_TEST_FORM_FIELD } from '../../utils/constants/orderKitForm';
import ROUTES from '../../utils/constants/routes';
import SERVICE_LIST from '../../utils/constants/serviceList';
import log from '../../utils/log';

function OrderSTITest() {
	const [alertContent, setAlertContent] = useState(null);
	const [openAlertDialog, setOpenAlertDialog] = useState({});
	const [store, dispatch] = useStore();
	const { user, userServiceList } = store;
	const serviceData = userServiceList[SERVICE_LIST.STI_TESTING];
	const history = useHistory();
	const handleSubmit = async (e, stiOrderDetails, setIsLoading) => {
		e.preventDefault();
		setIsLoading(false);
		log('userServiceList', userServiceList);
		if (stiOrderDetails?.contactName) {
			orderSTITest(
				stiOrderDetails,
				dispatch,
				setOpenAlertDialog,
				history,
				setIsLoading,
				user?.orgId,
				user?.cognitoSub,
				serviceData?.pickupLocation
			);
		} else {
			setAlertContent({
				message: {
					message: {
						title: 'An error occurred',
						line1: 'This can happen sometimes. ',
						line2: 'Please refresh your browser and try again in a little while.',
					},
					level: 'error',
					path: ROUTES.ORDER_TEST,
				},
			});
		}
	};

	return (
		<OrderKit
			formFields={ORDER_STI_TEST_FORM_FIELD}
			handleSubmit={handleSubmit}
			subTitle="Fill in the details below to order STI Test."
			title="Order STI Test">
			{alertContent && (
				<AlertDialog
					alertContent={alertContent}
					openAlertDialog={openAlertDialog}
					setOpenAlertDialog={setOpenAlertDialog}
				/>
			)}
		</OrderKit>
	);
}

export default OrderSTITest;
