import React from 'react';

import PickUpKit from '../../Components/PickUpKit';
import SERVICE_LIST from '../../utils/constants/serviceList';

function PickupSTITest() {
	return (
		<PickUpKit
			service={SERVICE_LIST.STI_TESTING}
			subtitle="Please follow the below steps to pick up a test."
			title="Pick Up a STI/STD Test"
			bagColor="GREEN"
		/>
	);
}

export default PickupSTITest;
