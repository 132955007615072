const SERVICE_LIST = {
	EEK: 'EEK',
	EARLY_EVIDENCE_KIT: 'Early Evidence Kit',
	PLAN_B: 'Plan B',
	STI_TESTING: 'STI/STD Testing',
	PEP: 'PEP',
	TOXICOLOGY_SCREENING: 'Toxicology Screening',
	PREP: 'PrEP',
	LIVE_SUPPORT_24_7: '24/7 Live Support',
	TECHNICAL_SUPPORT: 'Technical Support',
	SEXUAL_ASSAULT_EXAM_MAP: 'Sexual Assault Exam Map',
	CRISIS_COUNSELORS: '24/7 Crisis',
	SEXUAL_ASSAULT_HOTLINE: '24/7 Sexual Assault Hotline',
	CARE_TEAM: 'Care Team',
	BLOOM: 'Bloom',
	CALLISTO: 'Callisto',
};

export default SERVICE_LIST;
