import { Box, Link, List, ListItem, Typography } from '@material-ui/core';
import React from 'react';

import AvoidThingsBanner from '../../Components/AvoidThingsBanner';

import useStyles from './Styles/SignUpAlertDialog.Style';

function UnderAgeModal() {
	const classes = useStyles();
	return (
		<Box>
			<Typography>
				{
					"Leda's services are only intended for people over 18. If you are a minor (under age 18), "
				}
				<b>{'we recommend you seek a sexual assault exam ASAP. '}</b>
				If you are in danger or need immediate medical attention, please call 911. Here are some
				additional recommendations:
			</Typography>
			<List className={classes.list}>
				<ListItem className={classes.listItem}>
					Exams are time-sensitive, and we encourage you to seek one as soon as possible
					<br />
					<br />
					<Link
						className={classes.link}
						href="https://www.leda.co/exam-locator"
						rel="noopener noreferrer"
						target="_blank"
						underline="always">
						Click here to find the closest exam to you.
					</Link>
				</ListItem>
				<ListItem className={classes.listItem}>
					If you are a minor and you tell someone you were assaulted, they may be obligated by law
					to report your assault to local police.
					{
						' If you do not wish to report your assault to the police, it is important to understand the mandatory reporting laws in your state.'
					}
					<br />
					<br />
					<Link
						className={classes.link}
						href="https://www.childwelfare.gov/pubPDFs/mandaall.pdf"
						rel="noopener noreferrer"
						target="_blank"
						underline="always">
						Click here to learn more about mandatory reporting laws.
					</Link>
				</ListItem>
				<ListItem className={classes.listItem}>
					Sexual assault exams provide comprehensive support after assault, including an injury
					screening, DNA collection, and post-care.
					{
						' It is best to try to avoid certain things before your exam. If you cannot avoid the following, still proceed with your exam!'
					}
					<br />
					<br />
					<b>Try to Avoid:</b>
				</ListItem>
			</List>
			<Box className={classes.banner}>
				<AvoidThingsBanner />
			</Box>
		</Box>
	);
}

export default UnderAgeModal;
