/* eslint-disable no-nested-ternary */
import {
	Box,
	Button,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ArrowLeft from '../assets/ArrowLeft.svg';
import video from '../assets/BoxLock_Instructions_AdobeCreativeCloudExpress.gif';
import MyLabBox from '../assets/myLabBoxIcon.svg';
import fetchLockCode from '../Services/fetchLockCode';
import { showIntercom } from '../Services/intercom/method';
import { useStore } from '../store';
import { DEMO } from '../utils/constants/demoData';
import ROUTES from '../utils/constants/routes';
import SERVICE_LIST from '../utils/constants/serviceList';
import { getOnClick } from '../utils/disableServices';

import Footer from './Footer';
import Header from './Header';
import Loading from './Loading';
import { imageskeleton } from './PickupCardSkeleton';
import PickupSekeleton from './PickupSekeleton';
import QRCodeBox from './QRCodeBox';
import SendQRCode from './SendQRCode';
import useStyles from './Styles/PickUpKit.Style';

function PickUpKit({ title, service, subtitle }) {
	const [store, dispatch] = useStore();
	const { user, isMobile, userServiceList, serviceDisabled } = store;
	const history = useHistory();
	const classes = useStyles();
	const [qrCode, setQRCode] = useState('');
	const [qrCodeRefreshing, setQRCodeRefreshing] = useState(false);
	const [openSendDialog, setOpenSendDialog] = useState(false);

	const careTeamServiceData = userServiceList[SERVICE_LIST.CARE_TEAM];

	const serviceDisableInDemo = process.env.REACT_APP_ENV === DEMO;

	const serviceDataInstructions = userServiceList[service]?.pickupInstructions;

	const isQRCodeEnabledService = JSON.stringify(serviceDataInstructions)?.includes('text/barcode');

	const fetchQRcode = async () => {
		const res = await fetchLockCode(
			user,
			setQRCodeRefreshing,
			service,
			userServiceList[service].lockId,
			false
		);
		setQRCode(res);
	};
	const fetchRefreshedQRcode = async () => {
		const res = await fetchLockCode(
			user,
			setQRCodeRefreshing,
			service,
			userServiceList[service].lockId,
			true
		);
		setQRCode(res);
	};

	const handleCareTeamClick = async () => {
		await showIntercom();
	};

	useEffect(() => {
		fetchRefreshedQRcode();
	}, []);

	useEffect(() => {
		if (user && !qrCode && isQRCodeEnabledService) {
			fetchQRcode();
		}
	}, [user, qrCode]);

	return user ? (
		<Box className={classes.root}>
			{!isMobile && <Header showProfileMenu />}
			<Box className={classes.main}>
				<Box className={classes.topBar}>
					<Button
						className={classes.button}
						onClick={() => history.push(ROUTES.HOME)}
						startIcon={
							<img
								alt="A left arrow icon to signify that the page will open in a new tab."
								src={ArrowLeft}
							/>
						}>
						Back
					</Button>
				</Box>
				<Box className={classes.container}>
					<Typography className={classes.title}>{title}</Typography>
					<Typography className={classes.subTitle}>{subtitle}</Typography>
					<List className={classes.listBox}>
						{serviceDataInstructions && serviceDataInstructions.length ? (
							serviceDataInstructions?.map((data, index) =>
								data.type === 'text/barcode' ? (
									<ListItem key={data?.body} className={classes.firstElement}>
										<ListItemIcon className={classes.listItemIcon}>{index + 1}</ListItemIcon>
										<ListItemText className={classes.listText} disableTypography>
											{data.body}
											{qrCode && !qrCodeRefreshing && (
												<Typography
													className={classes.sendEmailtext}
													onClick={() => setOpenSendDialog(true)}>
													Send code to your email
												</Typography>
											)}
											<Typography
												className={classes.sendEmailtext}
												color="secondary"
												onClick={() => fetchRefreshedQRcode()}>
												Refresh Code
											</Typography>
										</ListItemText>
										<QRCodeBox
											qrCode={qrCode}
											qrCodeRefreshing={qrCodeRefreshing}
											setQRCode={setQRCode}
										/>
									</ListItem>
								) : data.body.includes('MyLAB') ? (
									<ListItem key={data?.body} className={classes.listItem}>
										<ListItemIcon className={classes.listItemIcon}>06</ListItemIcon>
										<ListItemText className={classes.listText} disableTypography>
											Activate your kit{' '}
											<a
												className={classes.sendEmailtext}
												href="https://www.mylabbox.com/register/"
												rel="noreferrer"
												target="_blank">
												here on the MyLAB Box Portal
											</a>
										</ListItemText>
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												color: 'black',
											}}>
											Powered By:
											<img
												alt="A QR code for the lock."
												className={
													isMobile ? classes.img : `${classes.marginLeft} ${classes.marginRight}`
												}
												src={MyLabBox}
											/>
											<a
												style={{
													color: 'black',
													fontWeight: 'bold',
												}}
												href="https://www.mylabbox.com/product/at-home-comprehensive-drug-test/"
												rel="noreferrer"
												target="_blank">
												Learn more
											</a>
										</div>
									</ListItem>
								) : (
									<ListItem key={data?.body} className={classes.listItem}>
										<ListItemIcon className={classes.listItemIcon}>{index + 1}</ListItemIcon>
										<ListItemText className={classes.listText} disableTypography>
											{data.body}
										</ListItemText>
										{data.type === 'text/image' &&
											(video ? (
												<img
													alt="A QR code for the lock."
													className={
														isMobile ? classes.img : `${classes.marginLeft} ${classes.marginRight}`
													}
													src={video}
												/>
											) : (
												imageskeleton(300, 160)
											))}
									</ListItem>
								)
							)
						) : (
							<PickupSekeleton />
						)}
					</List>

					<Typography style={{ display: 'flex', justifyContent: 'center' }}>
						If you are having trouble opening the lock, please contact the &nbsp;
						<div
							aria-hidden
							className={classes.linkText}
							onClick={getOnClick(
								handleCareTeamClick,
								serviceDisabled,
								dispatch,
								careTeamServiceData,
								serviceDisableInDemo
							)}>
							Care Team.
						</div>
					</Typography>
					<br />
					<Typography style={{ display: 'flex', justifyContent: 'center' }}>
						{'If you are able to, please find in-person care by reviewing the '} &nbsp;
						<div
							className={classes.linkText}
							aria-hidden
							onClick={() => history.push(ROUTES.RESOURCE_CENTER)}>
							Resources page
						</div>
						&nbsp; or contacting the Care Team.
					</Typography>
				</Box>
			</Box>
			<SendQRCode
				openSendDialog={openSendDialog}
				qrCode={qrCode}
				setOpenSendDialog={setOpenSendDialog}
			/>
			<Footer disclaimer />
		</Box>
	) : (
		<Box className={classes.loaderBox}>
			<Loading />
		</Box>
	);
}

PickUpKit.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	service: PropTypes.string,
};

export default PickUpKit;
