const PICKUP_KIT_ALIAS = {
	EEK: 'A01',
	'Plan B': 'A02',
	'Toxicology Screening': 'A03',
	PEP: 'A04',
	'STI/STD Testing': 'B01',
	PREP: 'C01',
};

export default PICKUP_KIT_ALIAS;
