import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import ModalCloseIcon from '../assets/ModalCloseIcon.svg';

import styles from './Styles/PrivacyPolicy.Style';

function PrivacyPolicy(props) {
	const classes = styles();

	const { openPrivacyPolicy, setOpenPrivacyPolicy } = props;

	const handleClose = () => {
		setOpenPrivacyPolicy(false);
	};

	return (
		<div>
			<Dialog
				classes={{ root: classes.div, paper: classes.dialog }}
				onClose={handleClose}
				open={openPrivacyPolicy}>
				<DialogTitle id="alert-dialog-title">
					<img
						aria-hidden
						alt="An icon to close Modal"
						className={classes.closeIcon}
						onClick={handleClose}
						src={ModalCloseIcon}
					/>
					<Typography variant="h5">Privacy Policy</Typography>
				</DialogTitle>
				<DialogContent classes={{ root: classes.dialogContent }}>
					<Typography align="left">Last Modified Date: September 21, 2022</Typography>
					<br />
					<Typography>
						Your privacy is a top priority to us at Leda Health. We recognize that it takes strength
						to share certain sensitive information in the aftermath of a sexual assault. We value
						your courage and recognize the importance of keeping your information secure.
					</Typography>
					<br />
					<Typography align="left">
						This Privacy Policy describes how Leda Health Corporation (“Leda,” “we,” or “us”)
						collects, uses, and discloses information about “you,” a user of our any of our Services
						(“User”). Our “Services” include but are not limited to our website, www.leda.co, and
						its related domains and sites (collectively, the “Website”), our mobile application (the
						“App”), our User Portal (the “Portal”), and any content, tools, features and
						functionality offered on or through our Website, App, or Portal, including but not
						limited to the Leda Health early evidence self-collection kit (the “Kit”), services
						provided by our care team, holistic healing services, and support group and organizing
						services (collectively, the “Services”).
					</Typography>
					<br />
					<Typography align="left">
						This privacy policy is incorporated by reference into our User Agreement, which may be
						found at{' '}
						<a
							className={classes.linkText}
							href="https://www.leda.co/agreements"
							rel="noreferrer"
							target="_blank">
							www.leda.co/agreements
						</a>
						.
					</Typography>
					<br />
					<Typography align="left">
						Please read this Privacy Policy carefully to understand our policies and practices
						regarding your information and how we will treat it. If you do not agree with our
						policies and practices, do not access or use the Services.
					</Typography>
					<br />
					<Typography align="left">
						BY ACCESSING OR USING THE SERVICES, YOU AGREE TO THIS PRIVACY POLICY.
					</Typography>
					<br />
					<Typography align="left" variant="h5">
						Children Under the Age of 18
					</Typography>
					<br />
					<Typography align="left">
						The Services are not intended for children under 18 years of age, and we do not
						knowingly collect Personal Information from children under 18. If you are under 18, you
						are not permitted to provide any information to or on the Services (including any
						information about yourself, such as your name, address, telephone number, email address
						or any screen name or username you may use).
					</Typography>
					<br />
					<Typography align="left">
						If we learn we have collected or received personal information from a child under 18, we
						may delete that information so long as doing so would not violate any applicable law,
						regulation, or order. We may also delete such Personal Information at the request of
						your legal parent or guardian. Additionally, if we discover any information pertaining
						to the abuse of a child under 18, we may report this to appropriate local authorities,
						and such report may include the disclosure of your Personal Information to third-parties
						and law enforcement.
					</Typography>
					<br />
					<Typography align="left">
						Learn more about your rights as a parent or guardian in the section titled Parental
						Rights and Controls.
					</Typography>
					<br />
					<Typography variant="h5">{'What Information Do We Collect From you? '}</Typography>
					<br />
					<Typography align="left" variant="h6">
						<b>Information That You May Provide</b>
					</Typography>
					<br />
					<Typography align="left">
						When you use our Services, you may be asked or required to provide certain information,
						some of which may be personally identifiable (“Personal Information”), some of which may
						be non-identifying, and some of which may be identifying only when viewed in conjunction
						with other information. Such information that you may provide to us includes but is not
						limited to the following:
					</Typography>
					<ul>
						<li>
							<Typography>
								{
									'Registration, account, or other identifying information, such as your name,account password, date of birth, mailing address, email address, Kit number,and telephone number; '
								}
							</Typography>
						</li>
						<li>
							<Typography>
								Certain, limited information regarding your physical or mental state, such as your
								relevant medical history, sex, possible injuries or current bodily condition;
							</Typography>
						</li>
						<li>
							<Typography>
								Information related to your recollection of a sexual assault (“Incident”), such as
								descriptions of the Incident, surrounding circumstances, the individuals involved,
								the time and location, physical or bodily symptoms experienced as a result of the
								Incident;
							</Typography>
						</li>
						<li>
							<Typography>
								{
									'Information conveyed or relevant to your communications with us, such as messages, communications, chats, data, images, and other information exchanged as a result of your communications with our care team members;information exchanged with our staff; and information exchanged by email; '
								}
							</Typography>
						</li>
						<li>
							<Typography>
								Information related to samples that you may collect as part of your use of our
								Services and the Kit, such as physical samples collected, garments
								collected,information derived from DNA and/or other analysis conducted by
								alaboratory, information uploaded onto a portal regarding results of DNAand/or other
								analysis; and
							</Typography>
						</li>
						<li>
							<Typography>
								Information communicated, exchanged, or collected as part of your use
								orparticipation in a healing circle, support group, and related services
								andorganizations.
							</Typography>
						</li>
					</ul>
					<Typography align="left" variant="h6">
						<b>Information That We May Automatically Collect</b>
					</Typography>
					<br />
					<Typography align="left">
						As you use the Services, we may use automatic data collection technologies to
						collectcertain information about your devices, browsing actions and patterns, including
						detailsof your use of the Services (such as traffic data, location data, logs and
						othercommunication data) and information about your devices and internet
						connection(including your IP address, operating system and browser type).
					</Typography>
					<Typography align="left">
						The technologies we use for this automatic data collection may include:
					</Typography>
					<ul>
						<li>
							<Typography>
								<b>Cookies and other similar technologies. </b>
								We may place cookies on your device to collect anonymous data related to your use of
								the Services. Ingeneral, a cookie is a small file placed on your device that can be
								used tohelp recognize your browser across different web pages, websites, andbrowsing
								sessions. We may use cookies to provide certain features of theServices. It may be
								possible to refuse to accept cookies by activating theappropriate setting on your
								device. However, if you select this setting youmay be unable to access certain
								features of the Services. We may also useother technologies to tailor the Services
								to you, such as web beacons (alsoreferred to as clear gifs, pixel tags and
								single-pixel gifs) that permit Leda, forexample, to track how visitors interact with
								the Services and for otherstatistics related to the Services (such as recording the
								popularity of certaincontent and verifying system and server integrity).
							</Typography>
						</li>
						<li>
							<Typography>
								<b>Log Information. </b>
								We may automatically collect and store certain informationin our server logs when
								you use the Services, including details about how youused the Services, IP address,
								and information specific to your device (suchas system activity, hardware settings,
								browser type, and browser language).
							</Typography>
						</li>
						<li>
							<Typography>
								<b>Device Identifiers. </b>
								We may collect information about your device, such asyour operating system, hardware
								version, device settings, file and softwarenames and types, mobile network
								information and device identifiers. Thishelps us measure how the Services are
								performing and improve the Servicesfor you on your particular device.
							</Typography>
						</li>
						<li>
							<Typography>
								<b>Location-Based Data. </b>
								We may collect and process information about yourgeographic location (for example,
								through GPS signals) when you use theServices. We will not track your device
								location without your permission. Wedo not share precise geolocation data with third
								parties, other than ourservice providers as necessary to provide the Services.
							</Typography>
						</li>
						<li>
							<Typography>
								<b>Analytics Data. </b>
								We may also collect analytics data, or use third-partyanalytics tools, to help us
								measure traffic and usage trends for the Services.
							</Typography>
						</li>
					</ul>
					<Typography align="left" variant="h6">
						<b>Information From Third Parties</b>
					</Typography>
					<br />
					<Typography align="left">
						As a part of your use of the Services, we may also collect or receive information about
						you from third parties who we have contracted with as a part of providing the
						Services.Such third parties may include but are not limited to providers for STI
						testing, Plan B contraceptives, delivery services, and DNA and/or forensic testing of
						your samples collected through the Kit and our Services.
					</Typography>
					<br />
					<Typography align="left">
						Although our Privacy Policy applies to information that we receive about you from third
						parties, please know that we cannot control and are not responsible for information that
						you provide to or is collected by third parties as a part of your use of the Services.
						In such instances, you acknowledge that Leda has no duty or responsibility to maintain
						and safeguard any information that you provide to, or is collected by, any third party.
						You must review the privacy policies of the applicable third party to learn more about
						how information will be handled by such parties.
					</Typography>
					<br />
					<Typography align="left" variant="h5">
						How We Use Your Information
					</Typography>
					<br />
					<Typography align="left">
						We collect your information to be able to provide you the Services. We also collect your
						information to continuously improve our Services, including estimating our user size and
						usage patterns. In some instances, we may also collect information to assist in your
						recollection of events or facts related to a sexual assault or to determine your
						eligibility to receive our Services.
					</Typography>
					<br />
					<Typography align="left">
						For example, to further these goals, Leda uses information that we collect about you or
						that you provide to us in order to:
					</Typography>
					<ul>
						<li>
							<Typography>{'Provide you with the Services; '}</Typography>
						</li>
						<li>
							<Typography>
								Communicate with you regarding the Services, your Account, your transactions with
								us, to send you details or updates about features of the Services or changes in our
								policies, and to provide you with information collected from your use of the Kit;
							</Typography>
						</li>
						<li>
							<Typography>
								To share or request from you information and/or news that may be relevant to the
								Services;
							</Typography>
						</li>
						<li>
							<Typography>Give you notices about your account, if applicable; and</Typography>
						</li>
						<li>
							<Typography>
								Carry out our obligations and enforce our rights arising from any contracts entered
								into between you and us, including, if applicable, for billing and collection.
							</Typography>
						</li>
					</ul>
					<Typography align="left">
						We may also anonymize your Personal Information (remove any information with which you
						may be personally identified) (“Anonymized Information”) to assess our Services and
						generate aggregated, de-aggregated, and statistical data that may enable us to better
						provide you the Services, for research and educational purposes, and to participate
						inthe sexual assault community as a whole.
					</Typography>
					<br />
					<Typography align="left">
						Additionally, Leda may use your IP address or other automatically collected information
						to help diagnose problems with our computer servicer and to administer the Website,App,
						Portal or other Services.
					</Typography>
					<br />
					<Typography align="left">
						We may also use information that we collect about you or that you provide to us in any
						other way we may describe when you provide the information or for any other purposes
						with your consent.
					</Typography>
					<br />
					<Typography align="left">
						{
							'If you choose to send samples collected through our Kit to our partner lab for testing, your used Kit and related samples may be stored by our partner lab in its facilities. Our partner lab is contractually obligated to not discard or destroy your Kit or its contents without our express consent, which we will obtain from you in the appropriate circumstances. You may request the return of your Kit and related samples by contacting us at '
						}
						<a
							className={classes.linkText}
							href="mailto:support@leda.co"
							rel="noreferrer"
							target="_blank">
							support@leda.co
						</a>
						.
					</Typography>
					<br />
					<Typography align="left" variant="h5">
						Florida Residents
					</Typography>
					<br />
					<Typography align="left">
						In response to the Protecting DNA Privacy Act enacted in Florida and effective October
						1, 2021, Leda has implemented additional procedures to provide Services to survivors who
						are in Florida or who have experienced sexual assault in Florida. If you are a survivor
						seeking our Services in Florida or have experienced sexual assault in Florida, we may
						further use or review information that you submit to us to determine your eligibility to
						access our Services, to communicate with law enforcement or a prosecutor’s department
						regarding your case and submitted information, or as otherwise necessary to ensure our
						compliance with the law.
					</Typography>
					<br />
					<Typography align="left" variant="h5">
						Disclosure of Your Information
					</Typography>
					<br />
					<Typography align="left">
						{
							'In the following instances, we may disclose your Personal Information collected through our Services: '
						}
					</Typography>
					<ul>
						<li>
							<Typography>
								To our contractors and other third parties we use to assist with providing
								theServices, such as STI testing, Plan B contraceptives, delivery services,healing
								circle and support groups, and laboratory services, and who are bound by contractual
								obligations to keep personal information confidential.
							</Typography>
						</li>
						<li>
							<Typography>To our subsidiaries and affiliates.</Typography>
						</li>
						<li>
							<Typography>
								To a buyer or other successor in the event of a merger, divestiture,restructuring,
								reorganization, dissolution or other sale or transfer of some orall of our assets,
								whether as a going concern or as part of bankruptcy,liquidation or similar
								proceeding, in which personal information held by us isamong the assets transferred.
							</Typography>
						</li>
						<li>
							<Typography>
								For any other purpose disclosed by us when you provide the information.
							</Typography>
						</li>
						<li>
							<Typography>With your consent.</Typography>
						</li>
						<li>
							<Typography>
								To comply with any court order, law or legal process, including to respond to any
								government or regulatory request.
							</Typography>
						</li>
						<li>
							<Typography>
								If we believe disclosure is necessary under legal obligations regarding mandatory
								reporting of crimes.
							</Typography>
						</li>
						<li>
							<Typography>
								To enforce or apply our Terms of Service and other agreements, including for billing
								and collection purposes, if applicable.
							</Typography>
						</li>
						<li>
							<Typography>
								If we believe disclosure is necessary or appropriate to protect the rights,property,
								or safety of Leda, our Users or others.This includes exchanging information with
								other companies and organizations for the purposes of fraud protection,
								investigating identity theft, and credit risk reduction.
							</Typography>
						</li>
					</ul>
					<Typography align="left" variant="h5">
						Third-Party Access to Your Information
					</Typography>
					<br />
					<Typography align="left">
						{
							'Leda does not sell, rent, license, or otherwise provide access to your Personal Information to third parties for their direct marketing use unless we receive your express consent to do so. '
						}
					</Typography>
					<br />
					<Typography align="left">
						In some instances, Leda may publicly or privately disclose Anonymized Information
						related to our Services and its use by Users. Such information may be disclosed or
						used,for example, to demonstrate how the Services are used, evaluate industry data, to
						create strategic partnerships, or to evaluate and study data relating to sexual assault.
					</Typography>
					<br />
					<Typography align="left" variant="h5">
						Third-Party Websites and Links
					</Typography>
					<br />
					<Typography align="left">
						{
							'The Services may contain links to third-party websites and online platforms (“Third-PartySites”) not owned or operated by Leda. Leda does not control such Third-Party Sites and are not responsible for their content, privacy practices, or use of your information.Information that you provide on public or semi-public venues, including information you share on social networking platforms may be viewable by other Users, users of Third-Party Sites, and/or the public without limitation as to its use by us or by a third party. Our inclusion of third-party links does not imply any endorsement or partnership with such Third-Party Sites or of their owners or operators except as disclosed through our Services. Leda expressly disclaims any and all liability relating to the use and/or disclosure of personal information by third parties. Any information submitted by youdirectly to these Third Party Sites is subject to that third party’s privacy and practices. '
						}
					</Typography>
					<br />
					<Typography align="left" variant="h5">
						Account Termination and Following your use of the Services
					</Typography>
					<br />
					<Typography align="left">
						{
							'Following termination or deactivation of an account, we may retain your information andcontent for backup, archival, audit or other business purposes, or otherwise inaccordance with applicable laws and this Privacy Policy. We may maintain AnonymizedInformation and information that is not Personal Information for analytics or other purposes. '
						}
					</Typography>
					<br />
					<Typography align="left" variant="h5">
						Your Choices About Our Use and Disclosure of Your Information
					</Typography>
					<br />
					<Typography align="left">
						We strive to provide you with choices regarding the personal information you provide to
						us. This section describes mechanisms we provide for you to control certain uses
						anddisclosures of your information.
					</Typography>
					<ul>
						<li>
							<Typography>
								<b>{'Tracking Technologies. '}</b>
								You can set your browser to refuse all or some browser cookies, or to alert you when
								cookies are being sent. If you disable or refuse cookies or block the use of other
								tracking technologies, some parts of the Services may then be inaccessible or not
								function properly.
							</Typography>
						</li>
						<li>
							<Typography>
								<b>Location Information. </b>
								You can choose whether or not to allow the Servicesto collect and use real-time
								information about your device’s location through the device’s privacy settings. If
								you block the use of location information,some parts of the Services may then be
								inaccessible or not function properly.
							</Typography>
						</li>
						<li>
							<Typography>
								<b>Promotional Offers. </b>You do not want us to use your email address or contact
								information to promote our own or third parties’ products or services as provided
								herein, you can opt-out by sending us an email stating your request to{' '}
								<a
									className={classes.linkText}
									href="mailto:support@leda.co"
									rel="noreferrer"
									target="_blank">
									support@leda.co
								</a>
								.
							</Typography>
						</li>
						<li>
							<Typography>
								<b>{'Targeted Advertising. '}</b>
								If you do not want us to use information that we collect or that you provide to us
								to deliver advertisements or updates about our Services, you can opt-out by sending
								us an email stating your request to{' '}
								<a
									className={classes.linkText}
									href="mailto:support@leda.co"
									rel="noreferrer"
									target="_blank">
									support@leda.co
								</a>
								.
							</Typography>
						</li>
						<li>
							<Typography>
								<b>Google Analytics. </b>
								We may also use Google Analytics to help us understand how our customers use the
								Website - you can read more about how Google uses your Personal Information{' '}
								<a
									className={classes.linkText}
									href="https://www.google.com/intl/en/policies/privacy/"
									rel="noreferrer"
									target="_blank">
									here
								</a>
								{': '}
								You can also opt-out ofGoogle Analytics:{' '}
								<a
									className={classes.linkText}
									href="https://tools.google.com/dlpage/gaoptout"
									rel="noreferrer"
									target="_blank">
									https://tools.google.com/dlpage/gaoptout
								</a>
								.
							</Typography>
						</li>
						<li>
							<Typography>
								Please note that we do not alter our Website’s data collection and usepractices when
								we see a “Do Not Track” signal from your browser.
							</Typography>
						</li>
						<li>
							<Typography>
								If you are a European resident, you may have the right to access personal
								information we hold about you and to ask that your personal information be
								corrected, updated, or deleted. If you would like to exercise this right or find out
								more information, please contact us at{' '}
								<a
									className={classes.linkText}
									href="mailto:support@leda.co"
									rel="noreferrer"
									target="_blank">
									support@leda.co
								</a>
								.
							</Typography>
						</li>
					</ul>
					<Typography align="left" variant="h5">
						Accessing and Correcting Your Information
					</Typography>
					<br />
					<Typography>
						You may access, correct or delete some of your Personal Information by logging into your
						account and visiting your account profile. When you do so, we’ll validate your request
						by verifying that you’re signed in to your Leda account. You (or your authorized agent)
						may also send us an email at{' '}
						<a
							className={classes.linkText}
							href="mailto:support@leda.co"
							rel="noreferrer"
							target="_blank">
							support@leda.co
						</a>{' '}
						to request access to, correct or delete any Personal Information that you have provided
						to us. We may not accommodate a request to change information if we believe the change
						would violate any law or legal requirement or cause the information to be incorrect.
						Leda will use the best available identifying information it has for you to ensure that
						the correct person is making the request, and may require you to answer several
						questions and provide Personal Information in order to meet such necessary verification
						before your request can be processed. In some cases, such as when you request the return
						of a Kit you sent to a partner lab, Leda may require a completed affidavit by you. Leda
						will not facilitate the destruction of the contents of Kits.
					</Typography>
					<br />
					<Typography>
						If you authorize another person to act as your agent to submit requests on your behalf,
						we will ask the agent to provide us the written and signed authorization that you
						provided to the agent, such as a power of attorney, and we will confirm with you that
						you did provide the authorization, and we will verify your identity.
					</Typography>
					<br />
					<Typography align="left" variant="h5">
						Your California Privacy Rights
					</Typography>
					<br />
					<Typography>
						California Civil Code Section 1798.83 permits users of the Services that are California
						residents to request certain information regarding our disclosure of personal
						information to third parties for their direct marketing purposes. To make such a
						request, please send an email to{' '}
						<a
							className={classes.linkText}
							href="mailto:support@leda.co"
							rel="noreferrer"
							target="_blank">
							support@leda.co
						</a>
						.
					</Typography>
					<br />
					<Typography align="left" variant="h5">
						<b>California Consumer Protection Act (“CCPA”)</b>
					</Typography>
					<br />
					<Typography>
						If you are a resident of the state of California, you may have certain data protection
						rights that can be exercised either by emailing us at{' '}
						<a
							className={classes.linkText}
							href="mailto:support@leda.co"
							rel="noreferrer"
							target="_blank">
							support@leda.co
						</a>{' '}
						or mailing us at:
						<br />
						<br />
					</Typography>
					<Typography>Leda Health Corporation</Typography>
					<Typography>370 Jay St.</Typography>
					<Typography>Brooklyn, NY 11201</Typography>
					<br />
					<Typography>Your rights under the CCPA may include the following:</Typography>
					<ul>
						<li>
							<Typography>
								The right to access or delete the information we have on you.Whenever made possible,
								you can access or request deletion of your personal data.
							</Typography>
						</li>
						<li>
							<Typography>
								The right to know what personal information is collected, used, shared or sold as to
								the categories and specific pieces of personal information, and the sources of that
								information, and types of information we collect, and the intended purposes of that
								information, and the parties with whom we share it.
							</Typography>
						</li>
						<li>
							<Typography>The right to opt-out of sale of personal information.</Typography>
						</li>
						<li>
							<Typography>
								The right to non-discrimination in terms of price or service when you exercise a
								privacy right under the CCPA. Exercising these rights may impact your right or
								ability to receive information about our products and the Services, and the benefits
								of the Services.
							</Typography>
						</li>
					</ul>
					<Typography align="left" variant="h5">
						Parental Rights and Controls
					</Typography>
					<br />
					<Typography>
						We do not knowingly collect Personal Information from children under 18 years of age. If
						you are a parent or guardian of a child from whom we have inadvertently collected
						Personal Information, you may request the deletion of your child’s Personal Information.
						In such cases, we will permanently delete your child’s Personal Information within a
						reasonable period of time so long as doing so would not violate any applicable law,
						regulation, or order.
					</Typography>
					<br />
					<Typography>
						If you have any questions about your child’s Personal Information, don’t hesitate to
						contact us. We’re here to help. We may require you to verify your identity as the
						parent/guardian of such child before acting on your request.
					</Typography>{' '}
					<br />
					<Typography>
						For any of the above inquiries or requests, you may contact us at support@leda.co.
					</Typography>
					<br />
					<Typography align="left" variant="h5">
						Data Security
					</Typography>
					<br />
					<Typography>
						We have implemented measures designed to secure your personal information from
						accidental loss and from unauthorized access, use, alteration and disclosure.All
						information you provide to us is stored on secure servers behind firewalls. Any
						information you provide to us through your use of the Services will be encrypted using
						industry standard technology. We cannot, however, ensure or warrant the security of any
						information you transmit to Leda or guarantee that your information on the Services may
						not be accessed, disclosed, altered, or destroyed by a breach of any of our
						physical,managerial, or technical safeguards.
					</Typography>
					<br />
					<Typography>
						The safety and security of your information also depends on you. Where we have givenyou
						(or where you have chosen) a password for access to the Services, you areresponsible for
						keeping this password confidential.We ask you not to share yourpassword with anyone.
					</Typography>
					<br />
					<Typography>
						Unfortunately, the transmission of information via the Internet is not completely
						secure.Although we do our best to protect your personal information, we cannot guarantee
						the security of your personal information transmitted through the Services. Any
						transmission of personal information is at your own risk. We are not responsible for
						circumvention ofany privacy settings or security measures we provide.
					</Typography>
					<br />
					<Typography align="left" variant="h5">
						Changes to Our Privacy Policy
					</Typography>
					<br />
					<Typography>
						Any changes we make to this Privacy Policy will be posted on this page. If we make
						material changes to how we treat Users’ personal information, we will notify you
						througha notice on the Website and/or by any other reasonable means. The date the
						Privacy Policy was last modified is identified at the top of the page.You are
						responsible for ensuring we have an up-to-date active and deliverable email address for
						you, and for regularly visiting our Website and this Privacy Policy to check for any
						changes.
					</Typography>
					<br />
					<Typography align="left" variant="h5">
						Contact Information
					</Typography>
					<br />
					<Typography>
						To ask questions or comment about our Privacy Policy and our privacy practices, contact
						us at{' '}
						<a
							className={classes.linkText}
							href="mailto:support@leda.co"
							rel="noreferrer"
							target="_blank">
							support@leda.co
						</a>
						{' or:'}
						<br />
						<br />
					</Typography>
					<Typography>Leda Health Corporation</Typography>
					<Typography>370 Jay St.</Typography>
					<Typography>Brooklyn, NY 11201</Typography>
				</DialogContent>
				<DialogActions classes={{ root: classes.dialogActions }} />
			</Dialog>
		</div>
	);
}

PrivacyPolicy.propTypes = {
	openPrivacyPolicy: PropTypes.bool.isRequired,
	setOpenPrivacyPolicy: PropTypes.func.isRequired,
};

export default PrivacyPolicy;
