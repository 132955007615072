import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(5, 25),
		textAlign: 'left',
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(3, 3),
		},
	},
	heading: {
		fontWeight: 400,
		fontSize: '40px',
		lineHeight: '40px',
		textAlign: 'center',
		color: '#000000',
		marginBottom: theme.spacing(2.5),
		[theme.breakpoints.down('sm')]: {
			fontSize: '30px',
			lineHeight: '30px',
		},
	},
	subHeading: {
		fontWeight: 400,
		fontSize: '30px',
		lineHeight: '30px',
		textAlign: 'center',
		color: '#000000',
		marginBottom: theme.spacing(2.5),
		[theme.breakpoints.down('sm')]: {
			fontSize: '20px',
			lineHeight: '23px',
		},
	},
	textHeading: {
		fontWeight: 700,
		fontSize: '20px',
		lineHeight: '20px',
		color: '#1E4C4E',
		margin: theme.spacing(1.5, 0),
	},
	text: {
		fontWeight: 400,
		fontSize: '16px',
		lineHeight: '24px',
		color: '#1E4C4E',
		margin: theme.spacing(1, 0),
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
	},
	submitButton: {
		width: '70%',
		background: '#BEE2E2',
		textTransform: 'none',
		borderRadius: 9,
		fontSize: 16,
		lineHeight: '20px',
		margin: theme.spacing(5, 0),
		padding: theme.spacing(3),
		color: theme.palette.primary.main,
		'&:hover': {
			background: '#BEE2E2',
		},
	},
	chatButton: {
		textDecoration: 'underline',
		textTransform: 'none',
	},
}));

export default useStyles;
