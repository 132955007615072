import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
	formControl: {
		marginBottom: theme.spacing(3),
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
}));

export default styles;
